import { CAR_SUBMIT_FORM, CLEAR_CAR_FORM } from "./type"

export const carSubmitForm = (data, callback) => {
    return async dispatch => {
        await dispatch({ type: CAR_SUBMIT_FORM, payload: data })
        callback && callback()
    }
}

export const clearCarForm = () => {
    return async dispatch => {
        await dispatch({ type: CLEAR_CAR_FORM })
    }
}