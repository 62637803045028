import React, { useCallback, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import TireLogo from "../../../assets/logo/tire_logo.png";
import CardDetail from '../../../components/CardDetail';
import { BlockModel, CardDetailModel, ContentModel } from '../../../components/CardDetail/CardDetailModel';
import ErrorMessage from '../../../components/ErrorMessage';
import Header from '../../../components/Header';
import withWarranty from '../../../components/HoC/withWarranty';
import { POSITION_OF_TIRE_ENUM } from '../../../components/TireCheckBox/TirePositionModel';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../../components/Wrapped';
import { config } from '../../../config';

const findTirePositionText = (label, enumValue) => {
    let text = ''
    if (enumValue === POSITION_OF_TIRE_ENUM.FRONT_LEFT.value) {
        text = label?.front_left
    }
    if (enumValue === POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value) {
        text = label?.front_right
    }
    if (enumValue === POSITION_OF_TIRE_ENUM.BACK_LEFT.value) {
        text = label?.back_left
    }
    if (enumValue === POSITION_OF_TIRE_ENUM.BACK_RIGHT.value) {
        text = label?.back_right
    }
    return text
}

const getCardDetail = (label, tire) => {
    const { tireChangeModel, tireChange: { tireChangeQuantity, tireChangePosition }, replacmentReasonsText, previousTireBrand, currentMileage } = tire
    const cardDetailModel = new CardDetailModel()
    cardDetailModel.header_title = label.tire_detail_header
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.tire_change_model, tireChangeModel.tireModelName)))
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.quantity, I18n.t('tire.confirm.quantity_value', { quantity: tireChangeQuantity }))))
    if (tireChangePosition.length > 0) {
        let position_text = ''
        let isFront = tireChangePosition.some(({ positionOfTire: pos_tire }) => pos_tire === POSITION_OF_TIRE_ENUM.FRONT_LEFT.value || pos_tire === POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value)
        let isRear = tireChangePosition.some(({ positionOfTire: pos_tire }) => pos_tire === POSITION_OF_TIRE_ENUM.BACK_LEFT.value || pos_tire === POSITION_OF_TIRE_ENUM.BACK_RIGHT.value)
        if (isFront || isRear) {
            position_text = isFront && isRear ? label.front_back_tire : isFront ? label.front_tire : isRear ? label.back_tire : null
        }
        if (position_text) {
            cardDetailModel.data.push(new BlockModel(
                new ContentModel(label.tire_position, position_text), 4, null, true))
        }
        tireChangePosition.sort((a, b) => config.common.compare(a.seq, b.seq))
        tireChangePosition.forEach((tire_pos, index) => {
            const { positionOfTire, tireSizeText, week, year } = tire_pos
            const fullYear = config.constant.CENTURY + year
            const title = findTirePositionText(label, positionOfTire)
            const manufacture = `${week}/${fullYear}`
            cardDetailModel.data.push(new BlockModel(
                new ContentModel(label.tire_size, tireSizeText), 4, title))
            cardDetailModel.data.push(new BlockModel(
                new ContentModel(label.week_year_manu, manufacture), 4, null, index === tireChangePosition.length - 1 ? true : false))
        })
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.reason, replacmentReasonsText), 5))
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.previous, previousTireBrand.tireBrandName), 5))
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.current_miles, I18n.t('tire.confirm.current_miles_value', { miles: currentMileage })), 5))
    }
    return cardDetailModel
}

const Confirm = ({ label, warranty, classes }) => {
    const history = useHistory()
    const baseClasses = baseStyles()

    const onConfirm = () => {
        history.replace(config.route.registerCustomer)
    }


    const handleBack = useCallback(() => {
        history.goBack()
    }, [history])


    useEffect(() => {
        sessionStorage.setItem('prevLocation', config.route.registerWarrantyConfirm)
    }, [])


    return (
        <Container className={classes.root}>
            <ErrorMessage root={config.route.register} />
            <Body>
                <Header title={label.confirm.header} remark={I18n.t("for_dealer")} logo={TireLogo} />
                <CardDetail
                    cardDetail={getCardDetail(label.confirm, warranty)}
                    containerStyle={{ marginTop: 30 }}
                />
                <Footer className={baseClasses.root} style={{ width: '90%' }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={5} >
                            <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={handleBack}>
                                {label.button.edit}
                            </ButtonWrapped>
                        </Col>
                        <Col xs={7}>
                            <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                {label.button.next}
                            </ButtonWrapped>
                        </Col>
                    </Row>
                </Footer>
            </Body>
        </Container>
    )
}

export default withWarranty(Confirm)