import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import { LAYOUT_SPACING } from '../../App.config';
import CardDetail from '../../components/CardDetail';
import { BlockModel, CardDetailModel, ContentModel } from '../../components/CardDetail/CardDetailModel';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../components/Wrapped';
import { config } from '../../config';

const mock_data = {
    detail: `
        ขอบคุณสำหรับการลงทะเบียนเข้าร่วมประกันภัยยางกับดันลอป ท่านจะได้รับโปรแกรมรับประกันดันลอป
        อุ่นใจประกันยาง 100 วัน ทันทีที่ท่านลงทะเบียน
        เสร็จสิ้น
        <br/><br/>
        ขอแสดงความนับถือ
        <br/>
        บริษัท ดันลอปไทร์ (ไทยแลนด์) จำกัด
    `,
}
const getCardDetail = (label, data) => {
    const cardDetailModel = new CardDetailModel()
    cardDetailModel.header_title = label.header
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(null, data.detail)))
    return cardDetailModel
}

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        height: 'auto',
        minHeight: '100vh',
        '& .body': {
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '100%'
        },
        '& .body-content': {
            margin: theme.spacing(LAYOUT_SPACING),
            padding: theme.spacing(LAYOUT_SPACING),
        },
        '& .header-content': {
            background: theme.palette.banner
        },
        '& .footer': {
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },

    }
}))

const Thanks = () => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const label = { ...I18n.t('thanks'), button: I18n.t('button') }

    const onConfirm = () => {
        history.replace({
            pathname: config.route.memberWarranty,
            state: {
                register: true
            }
        })
    }

    useEffect(() => {
        sessionStorage.setItem('prevLocation', config.route.thanks)
    }, [])

    return (
        <Container className={[classes.root, baseClasses.root]}>
            <Body>
                <CardDetail
                    isHTML
                    cardDetail={getCardDetail(label, mock_data)}
                    containerStyle={{
                        height: '60vh',
                        overflowY: 'scroll',
                        marginTop: 30
                    }}
                />
                <Footer style={{ width: '90%' }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={12}>
                            <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                {label.button.view_warranty_detail}
                            </ButtonWrapped>
                        </Col>
                    </Row>
                </Footer>
            </Body>
        </Container>
    )
}

export default Thanks