import { combineReducers } from "redux"
import { TireBrandReducer } from "./tireBrand"
import { TireModelReducer } from "./tireModel"
import { TireSizeReducer } from "./tireSize"

const TireReducer = combineReducers({
    tireBrand: TireBrandReducer,
    tireModel: TireModelReducer,
    tireSize: TireSizeReducer,
})

export { TireReducer }
