import { UPLOAD_IMAGE_BEGIN, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE } from "./type";

const initialState = {
    url: '',
    loading: false
}
export const UploadImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_IMAGE_BEGIN:
            return {
                ...state,
                loading: true
            }
        case UPLOAD_IMAGE_SUCCESS:
            return action.payload
        case UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
};
