import { GET_SUB_DISTRICT_BEGIN, GET_SUB_DISTRICT_SUCCESS, CLEAR_SUB_DISTRICT } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const SubDistrictReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUB_DISTRICT_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_SUB_DISTRICT_SUCCESS:
            return action.payload
        case CLEAR_SUB_DISTRICT:
            return {
                ...state,
                loading: false,
                items: []
            }
        default:
            return state
    }
};
