import { ProvinceReducer } from "./provinces";
import { combineReducers } from "redux"
import { DistrictReducer } from "./districts";
import { SubDistrictReducer } from "./subDistricts";

const AddressReducer = combineReducers({
    province: ProvinceReducer,
    district: DistrictReducer,
    subDistrict: SubDistrictReducer,
})

export {
    AddressReducer
}