
class PersonalFormModel {
    titleId = ''
    gender
    firstname = ''
    lastname = ''
    fullname
    identificationNumber = ''
    dateOfBirth = null
    phoneNumber = ''
    email = ''
    address = ''
    provinceId = ''
    provinceText
    districtId = ''
    districtText
    subDistrictId = ''
    subDistrictText
    verifyCitizenId = false
    pdpaFlag
}

export default PersonalFormModel