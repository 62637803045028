import { CircularProgress, makeStyles } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FastField, Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { carSubmitForm } from '../../store/reducers/car/actions';
import { getCarBrand } from '../../store/reducers/common/car/carBrands/actions';
import { getCarModel } from '../../store/reducers/common/car/carModel/actions';
import { getMileage } from '../../store/reducers/common/other/mileage/actions';
import Select from '../Select';
import ValidateError from '../ValidateError';
import { AutoCompleteWrapped, baseStyles, ButtonWrapped, Col, Footer, FormWrapped, Row, TextFieldWrapped } from '../Wrapped';
import { useHistory } from 'react-router';
import { config } from '../../config';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 30,
        minHeight: 'calc(100vh - 130px)',
        '& .MuiToggleButton-root': {
            border: 'none',
            fontSize: theme.typography.h4,
            fontWeight: theme.typography.fontWeightBold,
            width: '100%',
        },
        '& .MuiToggleButton-root:first-of-type': {
            marginRight: 2,
        },
        '& .MuiToggleButton-root:last-of-type': {
            marginLeft: 1,
        },
        '& .MuiInputBase-input': {
            '& i': {
                color: 'white'
            },
        },
        '& .footer': {
            marginTop: 'auto'
        }
    }
}))

const validate = (values) => {
    const errors = {};
    for (let [key, value] of Object.entries(values)) {
        if (!value) {
            errors[key] = {
                ...errors[key],
                required: true
            }
        }

        if (key === 'carLicense') {
            const pattern = /^[0-9ก-ฮ][ก-ฮ]?[ก-ฮ]? [0-9]{1,4}$/g
            if (!pattern.test(value)) {
                errors[key] = {
                    ...errors[key],
                    pattern: true
                }
            }
        }
    }
    return errors;
}

const CarForm = ({ label, formData, isExisting }) => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const dispatcher = useDispatch()
    const common = useSelector(state => state.common)

    const onCarBrandChange = (id) => {
        dispatcher(getCarModel(id))
    }

    useEffect(() => {
        dispatcher(getCarBrand(''))
        dispatcher(getMileage())
    }, [dispatcher])

    return (
        <Formik
            initialValues={formData}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    console.log("values", values)
                    dispatcher(carSubmitForm(values, () => {
                        history.push(config.route.registerCustomerConfirm)
                    }))

                    setSubmitting(false);
                }, 400);
            }}
        >
            {({
                handleSubmit,
                isSubmitting
            }) => (
                    <FormWrapped className={[baseClasses.root, classes.root]} onSubmit={handleSubmit}>
                        <Field name="carBrand">
                            {({ field, form, meta }) => (
                                <Row>
                                    <AutoCompleteWrapped
                                        {...field}
                                        onChange={(_, data) => {
                                            if (data?.id) {
                                                onCarBrandChange(data?.id)
                                            }
                                            form.setFieldValue('carModelId', undefined)
                                            form.setFieldValue('carModel', '')
                                            form.setFieldValue('carBrandId', data?.id)
                                            form.setFieldValue('carBrand', data)
                                        }}
                                        disableClearable
                                        onBlur={() => form.setTouched({ "carBrand": true, ...form.touched })}
                                        options={common.car.carBrand.items}
                                        getOptionLabel={(option) => option?.carBrandName || ""}
                                        filterOptions={(option, state) => config.common.filterOptions(option, state, "carBrandName")}
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.carBrandName, inputValue);
                                            const parts = parse(option.carBrandName, matches);
                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        renderInput={params => (
                                            <TextFieldWrapped
                                                {...params}
                                                label={I18n.t("personal.car.car_brand", { car: label.car.title })}
                                                error={meta.error?.required && meta.touched}
                                                value={field.value}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {common.car.carBrand.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <ValidateError error={meta.error?.required && meta.touched}
                                        message={I18n.t("validate_message.please_select", { label: I18n.t("personal.car.car_brand", { car: label.car.title }) })} />
                                </Row>
                            )}
                        </Field>
                        <Field name="carModel">
                            {({ field, form, meta }) => (
                                <Row>
                                    <AutoCompleteWrapped
                                        {...field}
                                        onChange={(_, data) => {
                                            form.setFieldValue('carModelId', data?.id)
                                            form.setFieldValue('carModel', data)
                                        }}
                                        disableClearable
                                        onBlur={() => form.setTouched({ "carModel": true, ...form.touched })}
                                        options={common.car.carModel.items}
                                        getOptionLabel={(option) => option?.carModelName || ""}
                                        filterOptions={(option, state) => config.common.filterOptions(option, state, "carModelName")}
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.carModelName, inputValue);
                                            const parts = parse(option.carModelName, matches);
                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        renderInput={params => (
                                            <TextFieldWrapped
                                                {...params}
                                                label={I18n.t("personal.car.car_model", { car: label.car.title })}
                                                error={meta.error?.required && meta.touched}
                                                value={field.value}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {common.car.carModel.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <ValidateError error={meta.error?.required && meta.touched}
                                        message={I18n.t("validate_message.please_select", { label: I18n.t("personal.car.car_model", { car: label.car.title }) })} />
                                </Row>
                            )}
                        </Field>
                        <Field name="carMilleageId">
                            {({ field, form, meta }) => (
                                <Row>
                                    <Select
                                        {...field}
                                        label={label.car.range_of_miles}
                                        error={meta.error?.required && meta.touched}
                                        data={common.other.mileage.items}
                                        dataText="annualMileageDescription"
                                        onChange={({ target: { value }, nativeEvent: { target: { textContent } } }) => {
                                            form.setFieldValue('annualMileageText', textContent)
                                            form.setFieldValue('carMilleageId', value)
                                        }} />
                                    <ValidateError error={meta.error?.required && meta.touched}
                                        message={I18n.t("validate_message.please_select", { label: label.car.range_of_miles })} />
                                </Row>
                            )}
                        </Field>
                        <FastField name="carLicense">
                            {({ field, form, meta }) => (
                                <Row>
                                    <TextFieldWrapped
                                        {...field}
                                        label={label.car.car_license}
                                        error={meta.error && meta.touched}
                                        onChange={({ target: { value } }) => {
                                            const pattern = /^[ก-ฮ0-9 ]*$/g
                                            if (pattern.test(value)) {
                                                form.setFieldValue('carLicense', value)
                                            }
                                        }}
                                        onBlur={({ target: { value } }) => {
                                            let result = value.replace(/ /g, '').split('')
                                            let lastCharactersIndex = -1
                                            result.forEach((r, index) => {
                                                if (isNaN(Number(r))) {
                                                    lastCharactersIndex = index
                                                }
                                            })
                                            result = result.join('')
                                            result = result.substring(0, lastCharactersIndex + 1)
                                                + " "
                                                + result.substring(lastCharactersIndex + 1, result.length)
                                            form.setFieldTouched('carLicense', true)
                                            form.setFieldValue('carLicense', result.trim())
                                        }} />
                                    <ValidateError error={meta.error?.required && meta.touched}
                                        message={I18n.t("validate_message.please_input", { label: label.car.car_license })} />
                                    <ValidateError
                                        error={!meta.error?.required && meta.error?.pattern && meta.touched}
                                        message={I18n.t("validate_message.please_input", { label: label.car.car_license_pattern })} />
                                </Row>
                            )}
                        </FastField>
                        <Footer>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                {
                                    isExisting ?
                                        <Col xs={12}>
                                            <ButtonWrapped disabled={isSubmitting} variant="contained" size="large" type="submit" color="primary">
                                                {label.button.next}
                                            </ButtonWrapped>
                                        </Col> :
                                        <React.Fragment>
                                            <Col xs={5} >
                                                <ButtonWrapped
                                                    onClick={() => {
                                                        history.push(config.route.registerCustomer)
                                                    }}
                                                    variant="contained" size="medium" color="secondary">
                                                    {label.button.previous}
                                                </ButtonWrapped>
                                            </Col>
                                            <Col xs={7}>
                                                <ButtonWrapped disabled={isSubmitting} variant="contained" size="large" type="submit" color="primary">
                                                    {label.button.next}
                                                </ButtonWrapped>
                                            </Col>
                                        </React.Fragment>
                                }
                            </Row>
                        </Footer>
                    </FormWrapped>
                )}
        </Formik >
    )
}

export default CarForm