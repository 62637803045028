export const MESSAGE_ENUM = {
    PASSWARD_WRONG: 'PASSWARD_WRONG'
}
export const MESSAGES = {
    PASSWARD_WRONG: "รหัสผ่านไม่ถูกต้อง"
}

const getMesssage = (errorCode) => {
    return MESSAGES[errorCode]
}

export default {
    getMesssage
}