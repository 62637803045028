import qs from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyLineAccount } from "../../store/reducers/authorization/actions";
import Spinner from "../Spinner";
import { useHistory } from "react-router";
import { config } from "../../config";

const withResolver = Component => props => {
    const history = useHistory()
    const verifyPassword = history.location.state?.verify
    const dispatcher = useDispatch();
    const authorization = useSelector(state => state.authorization)
    // const [query, setQuery] = useState(qs.parse(props.location.search))
    // const [query] = useState(qs.parse('?token=d6280c0ef48e9b8159baef91ff105c7d')) //mock
    // const [query] = useState(qs.parse('?token=4e951c0875c4327555c8a69c83f865fb')) //mock
    const [query] = useState(qs.parse(props.location.search)) //mock
    const qs_token = Object.keys(query).find(b => b.includes("token"))
    const acid = Object.keys(query).find(b => b.includes("acid"))
    const UA = navigator.userAgent || navigator.vender;
    if(!config.common.isMobile(UA)){
        history.replace(config.route.error)
    }

    if (history.location.pathname === config.route.member && authorization.newUser) {
        history.replace(config.route.register)
    }

    const VerifyFailure = useCallback(() => {
        history.replace(config.route.error)
    }, [history])

    useEffect(() => {
        if (!authorization.isAuth && !verifyPassword) {
            dispatcher(verifyLineAccount(query[qs_token], query[acid], null, VerifyFailure))
        }
    }, [query, authorization, qs_token, dispatcher, VerifyFailure, verifyPassword, acid])

    if (!authorization.isAuth && !verifyPassword) {
        return <Spinner />
    }

    return (
        <Component {...props} verifyPassword={verifyPassword} />
    )
}

export default withResolver