import { Button, FormControl, Grid, InputLabel, makeStyles, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup, Autocomplete } from "@material-ui/lab";
import { Form } from "formik";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LAYOUT_SPACING } from "../../App.config";
import { COLOR_TRANSPARENT, MAIN_COLOR, MAIN_COLOR_INPUT, TEXT_HINT, TEXT_SECONDARY } from "../../App.theme";

export const TextFieldWrapped = styled(TextField).attrs([{ variant: "outlined" }])`

    .MuiInputBase-root {
        ${props => props.value ? `background: ${COLOR_TRANSPARENT};` : null}
        ${props => props.error && !props.disabled ? `background: ${COLOR_TRANSPARENT};` : null}
    }

    label {
        ${props => props.value ? `color: ${MAIN_COLOR};` : null}
        ${props => props.error ? `
            color: ${TEXT_SECONDARY} !important;
            ` : null}
        ${props => props.disabled ? `
            opacity: 1 !important;
            ` : null}
    }

    input {
        ${props => props.value ? `background: ${COLOR_TRANSPARENT};` : null}
        ${props => props.error && !props.disabled ? `background: ${COLOR_TRANSPARENT};` : null}
    }
    fieldset {
        ${props => props.value && !props.error ? `
            border-color: ${MAIN_COLOR} !important;
            ` : null}
    }
`
export const SelectWrapped = styled(Select)`
    ${props => props.value ? `background: ${COLOR_TRANSPARENT};` : null}
    ${props => props.error && !props.disabled ? `background: ${COLOR_TRANSPARENT};` : null}

    div {
        ${props => props.value ? `background: ${COLOR_TRANSPARENT};` : null}
        ${props => props.error ? `background: ${COLOR_TRANSPARENT};` : null}
    }
    fieldset {
        ${props => props.value ? `border-color: ${MAIN_COLOR};` : null}
    }
`
export const InputLabelWrapped = styled(InputLabel)`
    ${props => props.value ? `color: ${MAIN_COLOR};` : null}
    ${props => props.error ? `
            color: ${TEXT_SECONDARY} !important;
            ` : null}
`


export const FormControlWrapped = styled(FormControl).attrs([{ variant: "outlined" }])`
    width: 100%;
`

export const ButtonWrapped = styled(Button)`
    width: 100%;
    border-radius: 25px;
    touch-action: manipulation;
`

export const RadioGroupWrapped = styled(RadioGroup).attrs([{ className: "flex-container flex-row flex-justify-space-evenly" }])`
    width: 100%;
    padding: 5px 10px 5px 10px;
`
export const RadioWrapped = styled(Radio).attrs([{ color: "primary" }])`

`

export const LabelWrapped = styled.label`
    color: ${props => props.active ? `${TEXT_SECONDARY}` : `${TEXT_HINT}`};
`

export const Container = styled.div.attrs([{ className: "container flex-container flex-column" }])`
`

export const Body = styled.div.attrs([{ className: 'body' }])`
    align-self: center;
`

export const Footer = styled.div.attrs([{ className: "footer" }])`
    width: 100%;
`

export const FormWrapped = styled(Form).attrs([{ className: "form-wrapped flex-container flex-column flex-justify-center flex-align-items-center" }])`
    width: 90%;
`

export const AutoCompleteWrapped = styled(Autocomplete).attrs([{ clearOnEscape: true, noOptionsText: "ไม่พบข้อมูล" }])`
    width: 100%;
    
    .MuiAutocomplete-popupIndicator {
        display: none;
    }
`


export const Row = styled(Grid).attrs([{
    container: true,
}])`
`

export const Col = styled(Grid).attrs([{
    item: true,
}])``


export const LinkWrapped = styled(Link)`
    text-decoration: none;
`

export const ToggleButtonGroupWrapped = styled(ToggleButtonGroup)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const ToggleButtonWrapped = styled(ToggleButton)`
`
export const UploadFile = styled.label`
    width: 100%;
`



export const baseStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: '0px !important',
            width: '100%',
        },
        '& .MuiToggleButtonGroup-root': {
        },
        '& .MuiFormControl-root': {
            margin: '0px !important',
            width: '100%',
        },
        '& .MuiGrid-container': {
            margin: theme.spacing(LAYOUT_SPACING),
            width: '100%',
        },
        '& .MuiInputBase-input': {
            border: "none",
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '& .MuiGrid-item': {
            marginTop: theme.spacing(LAYOUT_SPACING),
            marginBottom: theme.spacing(LAYOUT_SPACING),
        },
        '& .MuiGrid-item:first-of-type': {
            paddingRight: theme.spacing(LAYOUT_SPACING),
        },
        '& .MuiGrid-item:last-of-type': {
            paddingLeft: theme.spacing(LAYOUT_SPACING),
        },
        '& .MuiToggleButton-root': {
            background: MAIN_COLOR_INPUT,
            color: theme.palette.text.secondary,
            border: 'none'
        },
        '& .MuiToggleButton-root.Mui-selected': {
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        },
        '& .footer': {
            marginTop: 'auto',
            marginBottom: 25,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                maxWidth: '400px',
                '& span': {
                    fontSize: theme.typography.h4.fontSize + ' !important',
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
        '& .toggle-error': {
            background: 'transparent',
            border: `1.4px solid ${theme.palette.error.main} !important`
        },
        '& .MuiPickersBasePicker-container': {
            '& h6': {
                fontSize: '1.2em',
                fontWeight: theme.typography.fontWeightBold
            }
        }
    },
    container: {
        background: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        height: '100%',
    },
    body: {
        display: "flex",
        flexDirection: "column",
        margin: 15,
    },
    inputFile: {
        display: 'none',
    },
}));
