export default {
    ERRORS: {
        UNAUTHORIZED: "UNAUTHORIZED",
        USER_NOT_FOUND: "USER_400_001",
    },
    STATUS_NEW_USER: "New User",
    STATUS_OLD_USER: "Old User",
    CENTURY: "20",
    WEEK_PER_YEAR: 53,
    YEAR_LENGHT: 5,
    PERSONAL: "P",
    COMPANY: "C",
    MAX_CITIZEN_ID: 13,
    MOD_CITIZEN_VALUE: 11,
    AGREE: 1,
    DISAGREE: 0,
    DATE_INPUT_FORMAT: 'MMM DD YYYY',
    WARRANTY_DATE_FORMAT: 'DD-MM-YYYY',
    BIRTH_DATE_FORMAT: 'DD/MM/YYYY',
    API_DATE_FORMAT: 'YYYY-MM-DD',
    MAX_FILE_SIZE: 26214400,
    FILE_MIME_TYPE: 'image'
}