import { ON_LOADING, ON_LOADED } from "./type";

export const LoaderReducer = (state = false, action) => {
    switch (action.type) {
        case ON_LOADING:
            return action.payload
        case ON_LOADED:
            return action.payload
        default:
            return state
    }
};
