import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { LAYOUT_SPACING } from '../../App.config'
import Spinner from '../Spinner'
const useStyle = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        height: 'auto',
        minHeight: '100vh',
        '& .body': {
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '100%'
        },
        '& .body-content': {
            margin: theme.spacing(LAYOUT_SPACING),
            padding: theme.spacing(LAYOUT_SPACING),
        },
        '& .header-content': {
            background: theme.palette.banner
        },
        '& .footer': {
            marginTop: 'auto',
            marginBottom: 15,
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
    }
}))

const withWarranty = Component => props => {
    const warranty = useSelector(state => state.warranty.formData)
    const label = { ...I18n.t("tire"), button: I18n.t("button") }
    const classes = useStyle()
    // const hasCreated = useSelector(state => state.warranty.created)
    const hasCreated = sessionStorage.getItem('created')
    const history = useHistory()

    if (hasCreated) {
        history.goBack()
        return (
            <Spinner />
        )
    }


    const attributes = { warranty, label, classes }
    return (
        <Component {...props} {...attributes} />
    )
}

export default withWarranty