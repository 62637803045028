import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { FastField, Field, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import Calendar from "../../assets/logo/calendar.png";
import { config } from '../../config';
import { clearDistrict, getDistricts } from '../../store/reducers/common/address/districts/actions';
import { getProvinces } from '../../store/reducers/common/address/provinces/actions';
import { clearSubDistrict, getSubDistrict } from '../../store/reducers/common/address/subDistricts/actions';
import { getTitle } from '../../store/reducers/common/other/title/actions';
import { personalSubmitForm, updateProfiles, verifyCitizenId } from '../../store/reducers/personal/actions';
import DialogMessage from '../DialogMessage';
import Select from '../Select';
import SkeletonLoader from '../SkeletonLoader';
import ValidateError from '../ValidateError';
import { baseStyles, ButtonWrapped, Col, Footer, FormWrapped, Row, TextFieldWrapped, ToggleButtonGroupWrapped, ToggleButtonWrapped } from '../Wrapped';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20,
        '& .MuiToggleButton-root': {
            border: 'none',
            fontSize: theme.typography.h4,
            fontWeight: theme.typography.fontWeightBold,
            width: '100%',
        },
        '& .MuiToggleButton-root:first-of-type': {
            marginRight: 2,
        },
        '& .MuiToggleButton-root:last-of-type': {
            marginLeft: 1,
        },
        '& .MuiInputBase-input': {
            '& i': {
                color: 'white'
            },
        },
        '& .disabled-container': {
            width: '100%',
            marginTop: 15,
            marginBottom: 15,
            '& p': {
                color: theme.palette.text.hint,
                fontWeight: theme.typography.fontWeightMedium,
                opacity: 1
            }
        },
    }
}))

const validate = (values) => {
    const errors = {};
    const except = ['email', 'verifyCitizenId', 'loading','identificationNumber', 
      'address', 'provinceId', 'districtId', 'subDistrictId', 'districtText', 'subDistrictText', 'provinceText']
    for (let [key, value] of Object.entries(values)) {
        if (!value && !except.includes(key)) {
            errors[key] = {
                ...errors[key],
                required: true
            }
        }
        if (key === 'identificationNumber') {
            let citizenId = value.replace(/ /g, "").split("-").join('').split('')
            const { MAX_CITIZEN_ID, MOD_CITIZEN_VALUE } = config.constant
            if (citizenId.length === MAX_CITIZEN_ID) {
                let start = MAX_CITIZEN_ID
                const sumCitizenId = citizenId.reduce((p, c) => {
                    if (start !== 1) {
                        const calc = Number(c) * start
                        start--
                        return p + calc
                    }
                    return p
                }, 0)
                const modCitizen = sumCitizenId % MOD_CITIZEN_VALUE
                const diffForLastCitizen = (MOD_CITIZEN_VALUE - modCitizen) % 10
                const isCorrect = Number(citizenId[citizenId.length - 1]) === diffForLastCitizen
                if (!isCorrect) {
                    errors[key] = {
                        ...errors[key],
                        pattern: true
                    }
                }
            } else if(citizenId.length>0){
                errors[key] = {
                    ...errors[key],
                    pattern: true
                }
            }
        }
    }
    if (values.email) {
        let emailFormat = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!emailFormat.test(values.email)) {
            errors['email'] = {
                ...errors['email'],
                pattern: true
            }
        }
    }
    if (values.phoneNumber) {
        const numberLength = values.phoneNumber.split('-').join('').length
        if (numberLength < 10) {
            errors['phoneNumber'] = {
                ...errors['phoneNumber'],
                pattern: true
            }
        }
    }
    return errors;
}

const PersonalForm = ({ label, formData, isEdit }) => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const dispatcher = useDispatch()
    const common = useSelector(state => state.common)
    const [citizenOpen, setCitizenOpen] = useState(false)
    const [firstTime, setFirstTime] = useState(true)
    const { replaceCitizenId, replacePhoneNumber } = config.common

    useEffect(() => {
        let mounted = false
        if (firstTime && !mounted) {

            dispatcher(getProvinces())
            dispatcher(getTitle())

            if (formData?.provinceId) {
                dispatcher(getDistricts(formData.provinceId))
            }
            if (formData?.districtId) {
                dispatcher(getSubDistrict(formData.districtId))
            }
            setFirstTime(false)
        }
        return () => {
            mounted = true
        }
    }, [dispatcher, formData, firstTime, setFirstTime])

    useEffect(() => {
        let mounted = false
        if (isEdit && !mounted) {
            formData.identificationNumber = replaceCitizenId(formData.identificationNumber)
            formData.phoneNumber = replacePhoneNumber(formData.phoneNumber)
        }
        return () => {
            mounted = true
        }
    }, [isEdit, formData, replaceCitizenId, replacePhoneNumber])

    const done = useCallback(async () => {
        if (isEdit) {
            history.push(config.route.memberWarranty)
        } else {
            history.push(config.route.registerCar)
        }
    }, [history, isEdit])

    const updateProfilesDispacher = useCallback(async (values) => {
        const params = {
            ...values,
            phoneNumber: config.common.replaceForSave(values.phoneNumber)
        }
        dispatcher(updateProfiles(params, () => {
            history.goBack()
        }))
    }, [dispatcher, history])



    const { province, district, subDistrict } = common.address
    if (firstTime) {
        return <SkeletonLoader fieldCount={10} />
    }


    const onProvinceChange = (id) => {
        if (id) {
            dispatcher(getDistricts(id))
        } else {
            dispatcher(clearDistrict())
        }
        dispatcher(clearSubDistrict())
    }
    const onDistrictChange = (id) => {
        if (id) {
            dispatcher(getSubDistrict(id))
        } else {
            dispatcher(clearSubDistrict())
        }
    }

    return (
        <React.Fragment>
            <DialogMessage
                open={citizenOpen}
                title={I18n.t("personal.verify_citizen_title")}
                content={I18n.t("personal.verify_citizen")}
                onConfirm={() => {
                    done()
                }}
                onClose={() => setCitizenOpen(false)}
                cancelLabel={label.button.cancel}
                confirmLabel={label.button.to_proceed}
            />
            <Formik
                initialValues={formData}
                validate={validate}
                onSubmit={(values) => {
                    setTimeout(() => {
                        if (isEdit) {
                            updateProfilesDispacher(values)
                        } else {
                            dispatcher(verifyCitizenId(values.identificationNumber?config.common.replaceForSave(values.identificationNumber):config.common.convertTelAndBirthDateToNID(config.common.replaceForSave(values.phoneNumber),values.dateOfBirth), (status) => {
                                dispatcher(personalSubmitForm(values, () => {
                                    if (!status) {
                                        setCitizenOpen(true)
                                    } else {
                                        done()
                                    }
                                }))
                            }))
                        }
                    }, 400)
                }}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                }) => (
                        <FormWrapped className={[baseClasses.root, classes.root]} onSubmit={handleSubmit}>
                            {
                                !isEdit &&
                                (common.other.title.loading ?
                                    <SkeletonLoader fieldCount={1} />
                                    :
                                    <FastField name="titleId">
                                        {({ field, form, meta }) => (
                                            <Row>
                                                <ToggleButtonGroupWrapped style={{ width: '100%' }}
                                                    {...field}
                                                    exclusive
                                                    onChange={(e, value) => form.setFieldValue('titleId', value || field.value)}
                                                >
                                                    {
                                                        common.other.title.items?.map(({ id, title }) => (
                                                            <ToggleButtonWrapped
                                                                name={`${title}`}
                                                                key={`title_${id}`}
                                                                value={id}
                                                                className={meta.error?.required && meta.touched && 'toggle-error'}
                                                                onChange={form.handleChange}>
                                                                <Typography>
                                                                    {title}
                                                                </Typography>
                                                            </ToggleButtonWrapped>
                                                        ))
                                                    }
                                                </ToggleButtonGroupWrapped>
                                                <ValidateError error={meta.error?.required && meta.touched}
                                                    message={I18n.t("validate_message.please_select", { label: label.title })} />
                                            </Row>
                                        )}
                                    </FastField>)
                            }
                            <FastField name="firstname">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            label={label.first_name}
                                            error={meta.error?.required && meta.touched}
                                            onBlur={async (e) => {
                                                form.handleBlur(e)
                                                await form.setFieldValue('firstname', e.target.value.trim())
                                            }}
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                        <ValidateError error={meta.error?.required && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.first_name })} />
                                    </Row>
                                )}
                            </FastField>
                            <FastField name="lastname">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            label={label.last_name}
                                            error={meta.error?.required && meta.touched}
                                            onBlur={async (e) => {
                                                form.handleBlur(e)
                                                await form.setFieldValue('lastname', e.target.value.trim())
                                            }}
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                        <ValidateError error={meta.error?.required && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.last_name })} />
                                    </Row>
                                )}
                            </FastField>
                            {
                                !isEdit &&
                                <React.Fragment>
                                    <FastField name="identificationNumber">
                                        {({ field, form, meta }) => (
                                            <Row>
                                                <TextFieldWrapped
                                                    {...field}
                                                    label={I18n.t("personal.citizen_id", { notRequired: label.notRequired })}
                                                    type="tel"
                                                    error={meta.error?.pattern && meta.touched}
                                                    onChange={({ target: { value } }) => {
                                                        form.setFieldValue('identificationNumber', replaceCitizenId(value))
                                                    }}
                                                />
                                                <ValidateError error={ meta.error?.pattern && meta.touched}
                                                    message={I18n.t("validate_message.please_input", { label: label.citizen_id_pattern })} />
                                            </Row>
                                        )}
                                    </FastField>
                                    <FastField name="dateOfBirth">
                                        {({ field, form, meta }) => (
                                            <Row>
                                                <DatePicker
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(value) => {
                                                        const d = moment(value)
                                                        form.setFieldValue('dateOfBirth', d)
                                                    }}
                                                    openTo="year"
                                                    views={["year", "month", "date"]}
                                                    disableFuture
                                                    autoOk
                                                    format="DD/MM/YYYY"
                                                    TextFieldComponent={(props) => {
                                                        let value = field.value
                                                        if (value) {
                                                            value = config.common.toBuddhistYear(value, "DD/MM/YYYY")
                                                        }
                                                        return (
                                                            <TextFieldWrapped
                                                                {...props}
                                                                value={value || ''}
                                                                label={label.date_of_birth}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end"><img src={Calendar} style={{ width: 20, height: 16 }} alt="calendar" /></InputAdornment>,
                                                                }}
                                                            />)
                                                    }}
                                                    helperText={null}
                                                    error={meta.error?.required && meta.touched}
                                                />
                                                <ValidateError error={meta.error?.required && meta.touched}
                                                    message={I18n.t("validate_message.please_select", { label: label.date_of_birth })} />
                                                    <ValidateError
                                            error={true}
                                            message={
                                                 label.warning_date 
                                            }
                                        />
                                            </Row>
                                        )}
                                    </FastField>
                                </React.Fragment>
                            }
                            <FastField name="phoneNumber">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            label={label.phone_number}
                                            type="tel"
                                            onChange={({ target: { value } }) => {
                                                form.setFieldValue('phoneNumber', replacePhoneNumber(value))
                                            }}
                                            error={meta.error && meta.touched} />
                                        <ValidateError error={meta.error?.required && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.phone_number })} />
                                        <ValidateError error={!meta.error?.required && meta.error?.pattern && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.phone_pattern })} />
                                    </Row>
                                )}
                            </FastField>
                            <FastField name="email">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            value={field?.value || ''}
                                            label={I18n.t("personal.email", { notRequired: label.notRequired })}
                                            error={meta.error?.pattern && meta.touched}
                                            onBlur={async (e) => {
                                                form.handleBlur(e)
                                                await form.setFieldValue('email', e.target.value.trim())
                                            }}
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                        <ValidateError error={meta.error?.pattern && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.email_pattern })} />
                                    </Row>
                                )}
                            </FastField>
                            <FastField name="address">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            value={field?.value || ''}
                                            // label={label.address}
                                            label={I18n.t("personal.address", { notRequired: label.notRequired })}
                                            // error={meta.touched}
                                            onBlur={async (e) => {
                                                form.handleBlur(e)
                                                await form.setFieldValue('address', e.target.value.trim())
                                            }}
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                        {/* <ValidateError error={meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.address })} /> */}
                                    </Row>
                                )}
                            </FastField>

                            <Field name="provinceId">
                                {({ field, form, meta }) => (
                                    <Row>
                                        {
                                            province.loading ?
                                                <SkeletonLoader fieldCount={1} />
                                                :
                                                <Select
                                                    {...field}
                                                    // label={label.province}
                                                    value={field?.value || ''}
                                                    defaultValue={''}
                                                    label={I18n.t("personal.province", { notRequired: label.notRequired })}
                                                    data={common.address.province.items}
                                                    dataText="provinceName"
                                                    // error={meta.touched}
                                                    onChange={async ({ target: { value } }) => {
                                                        if (value !== field.value) {
                                                            const item = common.address.province.items.find(item => item.id === Number(value))
                                                            onProvinceChange(value)
                                                            await form.setValues({
                                                                ...form.values,
                                                                provinceId: value,
                                                                districtId: '',
                                                                subDistrictId: '',
                                                                provinceText: item?.provinceName,
                                                                districtText: undefined,
                                                                subDistrictText: undefined
                                                            })
                                                        }
                                                    }}
                                                    disabled={common.address.province.loading}
                                                />
                                        }
                                        {/* <ValidateError error={meta.touched}
                                            message={I18n.t("validate_message.please_select", { label: label.province })} /> */}
                                    </Row>
                                )}
                            </Field>
                            <Field name="districtId">
                                {({ field, form, meta }) => (
                                    <Row>
                                        {
                                            district.loading ?
                                                <SkeletonLoader fieldCount={1} />
                                                :
                                                <Select
                                                    {...field}
                                                    value={common.address.district.items ? field.value : ''}
                                                    // label={label.district}
                                                    label={I18n.t("personal.district", { notRequired: label.notRequired })}
                                                    data={common.address.district.items}
                                                    dataText="districtName"
                                                    // error={meta.touched}
                                                    onChange={async ({ target: { value } }) => {
                                                        if (value !== field.value) {
                                                            const item = common.address.district.items.find(item => item.id === Number(value))
                                                            onDistrictChange(value)
                                                            await form.setValues({
                                                                ...form.values,
                                                                districtId: value,
                                                                subDistrictId: '',
                                                                districtText: item?.districtName,
                                                                subDistrictText: undefined
                                                            })
                                                        }
                                                    }}
                                                    disabled={common.address.district.loading} />
                                        }
                                        {/* <ValidateError error={meta.touched}
                                            message={I18n.t("validate_message.please_select", { label: label.district })} /> */}
                                    </Row>
                                )}
                            </Field>
                            <Field name="subDistrictId">
                                {({ field, form, meta }) => (
                                    <Row>
                                        {
                                            subDistrict.loading ?
                                                <SkeletonLoader fieldCount={1} />
                                                :
                                                <Select
                                                    {...field}
                                                    value={common.address.subDistrict.items ? field.value : ''}
                                                    // label={label.sub_district}
                                                    label={I18n.t("personal.sub_district", { notRequired: label.notRequired })}
                                                    data={common.address.subDistrict.items}
                                                    dataText="subDistrictName"
                                                    // error={meta.error?.required && meta.touched}
                                                    disabled={common.address.subDistrict.loading}
                                                    onChange={async ({ target: { value } }) => {
                                                        const item = common.address.subDistrict.items.find(item => item.id === Number(value))
                                                        await form.setValues({
                                                            ...form.values,
                                                            subDistrictId: value,
                                                            subDistrictText: item?.subDistrictName
                                                        })
                                                    }}
                                                />
                                        }
                                        {/* <ValidateError error={meta.error?.required && meta.touched}
                                            message={I18n.t("validate_message.please_select", { label: label.sub_district })} /> */}
                                    </Row>
                                )}
                            </Field>
                            {
                                isEdit &&
                                <React.Fragment>
                                    <div className="disabled-container">
                                        <Typography>{label.edit_hint_disabled}</Typography>
                                    </div>
                                    {/* <FastField name="identificationNumber">
                                        {({ field, form, meta }) => (
                                            <Row>
                                                <TextFieldWrapped
                                                    {...field}
                                                    label={I18n.t("personal.citizen_id", { notRequired: '' })}
                                                    type="tel"
                                                    error={(meta.error?.required || meta.error?.pattern) && meta.touched}
                                                    onChange={({ target: { value } }) => {
                                                        form.setFieldValue('identificationNumber', replaceCitizenId(value))
                                                    }}
                                                    disabled
                                                />
                                            </Row>
                                        )}
                                    </FastField> */}
                                    <FastField name="dateOfBirth">
                                        {({ field, form, meta }) => (
                                            <Row>
                                                <DatePicker
                                                    {...field}
                                                    value={field?.value || ''}
                                                    onChange={(value) => {
                                                        const d = moment(value)
                                                        form.setFieldValue('dateOfBirth', d)
                                                    }}
                                                    disableFuture
                                                    autoOk
                                                    format="DD/MM/YYYY"
                                                    TextFieldComponent={(props) => {
                                                        let value = field.value
                                                        if (value) {
                                                            const date = moment(value)
                                                            value = config.common.toBuddhistYear(date, "DD/MM/YYYY")
                                                        }
                                                        return (
                                                            <TextFieldWrapped
                                                                {...props}
                                                                value={value}
                                                                label={label.date_of_birth}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end"><img src={Calendar} style={{ width: 20, height: 16 }} alt="calendar" /></InputAdornment>,
                                                                }}
                                                            />)
                                                    }}
                                                    helperText={null}
                                                    error={meta.error?.required && meta.touched}
                                                    disabled
                                                />
                                            </Row>
                                        )}
                                    </FastField>
                                </React.Fragment>
                            }
                            <Footer>
                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                    {
                                        isEdit ?
                                            <React.Fragment>
                                                <Col xs={5} >
                                                    <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={() => history.goBack()}>
                                                        {label.button.cancel}
                                                    </ButtonWrapped>
                                                </Col>
                                                <Col xs={7}>
                                                    <ButtonWrapped variant="contained" size="large" type="submit" color="primary">
                                                        {isEdit ? label.button.confirm : label.button.next}
                                                    </ButtonWrapped>
                                                </Col>
                                            </React.Fragment>
                                            :
                                            <Col xs={12} style={{ paddingLeft: 'unset', paddingRight: 'unset' }}>
                                                <ButtonWrapped variant="contained" size="large" type="submit" color="primary">
                                                    {isEdit ? label.button.confirm : label.button.next}
                                                </ButtonWrapped>
                                            </Col>
                                    }
                                </Row>
                            </Footer>
                        </FormWrapped>
                    )}
            </Formik >
        </React.Fragment>
    )
}

export default PersonalForm