import { VERIFY_ROUTE } from "./type";

const initialState = {
    auth: false,
    register: false,
    verifyDealer: false,
    warranty: false,
    warrantyConfirm: false,
    customer: false,
    customerCar: false,
    customerConfirm: false,
    pdpa: false,
}

export const RouteReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_ROUTE:
            return {
                ...state,
                [action.payload]: true
            }
        default:
            return state
    }
};
