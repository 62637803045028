import { makeStyles } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapped = styled.button`
    touch-action: manipulation;
`


const AnimatedContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: none;
    transition: all 150ms;
    border-radius: 50%;
    
    ${ButtonWrapped}:active & {
        outline: none;
        background-color: rgba(0, 0, 0, 0.2);
    }
`

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        transition: 'all 250ms',
        background: 'transparent',
        '&:focus': {
            outline: 'none',
        },
    }
}))

const NumButton = ({ children, onClick, className }) => {
    const classes = useStyles()
    return (
        <React.Fragment>
            <ButtonWrapped onClick={onClick} className={[classes.root, className]}>
                <AnimatedContainer>
                    {children}
                </AnimatedContainer>
            </ButtonWrapped>
        </React.Fragment>
    )
}

export default NumButton