import { InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FastField, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import renderHTML from 'react-render-html';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import SubBackground from '../../assets/background/sub_background.png';
import SubBackgroundDesktop from '../../assets/background/sub_background_desktop.png';
import Calendar from '../../assets/logo/calendar.png';
import ErrorMessage from '../../components/ErrorMessage';
import ValidateError from '../../components/ValidateError';
import {
    baseStyles,
    Body,
    ButtonWrapped,
    Col,
    Container,
    Footer,
    Row,
    TextFieldWrapped,
} from '../../components/Wrapped';
import { config } from '../../config';
import { verifyForgotPassword } from '../../store/reducers/personal/actions';

const headerStyle = {
    fontWeight: 300,
    lineHeight: 1.25,
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .header': {
            ...headerStyle,
            color: theme.palette.text.primary,
        },
        '& .sub-header': {
            ...headerStyle,
            color: theme.palette.text.primary,
        },
        '& .mark': {
            fontWeight: 300,
            color: theme.palette.text.hint,
        },
        '& .body': {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '90%',
            height: '100%',
            marginTop: '20%',
            '& .form': {
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
            },
            '@media(min-width: 500px)': {
                marginTop: '5%',
            },
            '@media(min-width: 600px)': {
                marginTop: '5%',
            },
            '@media(min-width: 768px)': {
                marginTop: '7%',
            },
            '& .MuiGrid-container': {
                margin: 'unset',
                marginTop: 8,
                marginBottom: 8,
            },
        },
        '& .footer': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            marginTop: 0,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiGrid-container': {
                margin: '0 !important',
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
            '& .MuiGrid-item:first-of-type': {
                paddingRight: 'unset',
            },
            '& .MuiGrid-item:last-of-type': {
                paddingLeft: 'unset',
            },
            '@media(min-width: 768px)': {
                bottom: '25%',
            },
        },
    },
}));

const ContainerWrapped = styled(Container)`
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media (min-width: 768px) {
        background: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`;
const BodyWrapped = styled(Body)``;

const validate = (values) => {
    const errors = {};
    const except = [];
    for (let [key, value] of Object.entries(values)) {
        if (!value && !except.includes(key)) {
            errors[key] = {
                ...errors[key],
                required: true,
            };
        }
        if (key === 'idCard') {
            let citizenId = value
                .replace(/ /g, '')
                .split('-')
                .join('')
                .split('');
            const { MAX_CITIZEN_ID, MOD_CITIZEN_VALUE } = config.constant;
            if (citizenId.length >= 10) {
                if (citizenId.length === MAX_CITIZEN_ID) {
                    let start = MAX_CITIZEN_ID;
                    const sumCitizenId = citizenId.reduce((p, c) => {
                        if (start !== 1) {
                            const calc = Number(c) * start;
                            start--;
                            return p + calc;
                        }
                        return p;
                    }, 0);
                    const modCitizen = sumCitizenId % MOD_CITIZEN_VALUE;
                    const diffForLastCitizen =
                        (MOD_CITIZEN_VALUE - modCitizen) % 10;
                    const isCorrect =
                        Number(citizenId[citizenId.length - 1]) ===
                        diffForLastCitizen;
                    if (!isCorrect) {
                        errors[key] = {
                            ...errors[key],
                            pattern: true,
                        };
                    }
                }
            } else {
                errors[key] = {
                    ...errors[key],
                    pattern: true,
                };
            }
        }
    }
    return errors;
};

const VerifyForgot = () => {
    const history = useHistory();
    const baseClasses = baseStyles();
    const dispatcher = useDispatch();
    const classes = useStyles();
    const label = { ...I18n.t('reset_password'), ...I18n.t('button') };
    const { replaceForSave, replacePhoneNumber, convertTelAndBirthDateToNID } =
        config.common;

    const onCancel = () => {
        history.goBack();
    };

    return (
        <ContainerWrapped
            className={[baseClasses.root, baseClasses.container, classes.root]}
        >
            <ErrorMessage />
            <BodyWrapped>
                <Typography variant="h3" className="header">
                    {label.header}
                </Typography>
                <Typography className="mark">
                    {renderHTML(label.hint)}
                </Typography>
                <Typography className="mark">
                    {renderHTML(label.hint2)}
                </Typography>
                <Formik
                    initialValues={{
                        idCard: '',
                        birthDate: null,
                    }}
                    validate={validate}
                    onSubmit={({ idCard, birthDate }) => {
                        setTimeout(() => {
                            const params = {
                                idCard:
                                    idCard.length > 12
                                        ? replaceForSave(idCard)
                                        : convertTelAndBirthDateToNID(
                                              replaceForSave(idCard),
                                              birthDate
                                          ),
                                birthDate,
                            };
                            dispatcher(
                                verifyForgotPassword(params, () => {
                                    history.replace({
                                        pathname:
                                            config.route.memberResetPassword,
                                        state: {
                                            header: true,
                                            reset_mode: true,
                                        },
                                    });
                                })
                            );
                        }, 400);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                            <FastField name="idCard">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <TextFieldWrapped
                                            {...field}
                                            label={label.citizen_id}
                                            type="tel"
                                            error={
                                                (meta.error?.required ||
                                                    meta.error?.pattern) &&
                                                meta.touched
                                            }
                                            onChange={({
                                                target: { value },
                                            }) => {
                                                form.setFieldValue(
                                                    'idCard',
                                                    replacePhoneNumber(value)
                                                );
                                            }}
                                        />
                                        <ValidateError
                                            error={
                                                (meta.error?.required ||
                                                    meta.error?.pattern) &&
                                                meta.touched
                                            }
                                            message={I18n.t(
                                                'validate_message.please_input',
                                                { label: label.citizen_id }
                                            )}
                                        />
                                    </Row>
                                )}
                            </FastField>
                            <FastField name="birthDate">
                                {({ field, form, meta }) => (
                                    <Row>
                                        <DatePicker
                                            {...field}
                                            value={field?.value}
                                            onChange={(value) => {
                                                const d = moment(value);
                                                form.setFieldValue(
                                                    'birthDate',
                                                    d
                                                );
                                            }}
                                            openTo="year"
                                            views={['year', 'month', 'date']}
                                            disableFuture
                                            autoOk
                                            format="DD/MM/YYYY"
                                            TextFieldComponent={(props) => {
                                                let value = field.value;
                                                if (value) {
                                                    const date = moment(value);
                                                    value =
                                                        config.common.toBuddhistYear(
                                                            date,
                                                            'DD/MM/YYYY'
                                                        );
                                                }
                                                return (
                                                    <TextFieldWrapped
                                                        {...props}
                                                        value={value}
                                                        label={
                                                            label.date_of_birth
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img
                                                                        src={
                                                                            Calendar
                                                                        }
                                                                        style={{
                                                                            width: 20,
                                                                            height: 16,
                                                                        }}
                                                                        alt="calendar"
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                );
                                            }}
                                            helperText={null}
                                            error={
                                                meta.error?.required &&
                                                meta.touched
                                            }
                                        />
                                        <ValidateError
                                            error={
                                                meta.error?.required &&
                                                meta.touched
                                            }
                                            message={I18n.t(
                                                'validate_message.please_select',
                                                { label: label.date_of_birth }
                                            )}
                                        />
                                    </Row>
                                )}
                            </FastField>
                            <Footer>
                                <Row
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Col xs={5}>
                                        <ButtonWrapped
                                            variant="contained"
                                            size="large"
                                            color="secondary"
                                            onClick={onCancel}
                                        >
                                            {label.cancel}
                                        </ButtonWrapped>
                                    </Col>
                                    <Col xs={7}>
                                        <ButtonWrapped
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                            color="primary"
                                        >
                                            {label.next}
                                        </ButtonWrapped>
                                    </Col>
                                </Row>
                            </Footer>
                        </Form>
                    )}
                </Formik>
            </BodyWrapped>
        </ContainerWrapped>
    );
};

export default VerifyForgot;
