import { config } from "../../../config";
import http from "../../../http";
import { SIGN_IN, SIGN_OUT, VERIFY_LINE_ACCOUNT } from "./type";
import { hasErrors } from "../error/actions";

export const signin = (password, callback) => {
    return async dispatch => {
        try {
            const result = await http.post(config.api.CUSTOMER.MEMBER.LOGIN, { password })
            if (result?.token) {
                localStorage.setItem('token', result.token)
            }
            dispatch({ type: SIGN_IN, payload: { isAuth: true } })
            if (callback) {
                callback()
            }
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const verifySignin = (password, callback) => {
    return async dispatch => {
        try {
            const result = await http.post(config.api.CUSTOMER.MEMBER.VERIFY_PASSWORD, { password })
            if (result) {
                dispatch({ type: SIGN_IN, payload: { isAuth: true } })
                if (callback) {
                    callback(result?.status)
                }
            }
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const signout = () => {
    return dispatch => {
        localStorage.clear()
        dispatch({ type: SIGN_OUT, payload: false })
    }
}


export const verifyLineAccount = (OTT, acid, success, failure) => {
    return async dispatch => {
        try {
            const { token, status, firstTime } = await http.get(config.api.CUSTOMER.THIRD_PARTY.GET_LINE_ID, { headers: { token: OTT, acid } })
            localStorage.setItem("token", token)
            await dispatch({ type: VERIFY_LINE_ACCOUNT, payload: { isAuth: true, newUser: config.constant.STATUS_NEW_USER === status, firstTime } })
            success && success()
        } catch (error) {
            failure && failure()
            await dispatch(hasErrors(error))
        }
    }
}

export const clearToken = () => {
    return () => {

    }
}