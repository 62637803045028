import React from 'react';
import { withStyles } from '@material-ui/core';

const Container = withStyles(theme => ({
    root: {
        borderColor: theme.palette.separator.main,
        borderBottom: 'none',
        marginTop: 25,
        marginBottom: 25,
    }
}))(({ classes }) => {
    return (
        <hr className={classes.root} />
    )
})

const Separator = () => {
    return (
        <Container />
    )
}

export default Separator