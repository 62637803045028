import { isMobile as mobile } from 'react-device-detect';

import moment from "moment";
import { config } from ".";
import { createFilterOptions } from '@material-ui/lab';


const isMobile = (UA) => {
    // return UA.indexOf("Line/") > -1 && mobile
    return mobile
}

const compare = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}
const convertDateStringFormat = (dateStr) => {
    const date_list = dateStr.split(' ')
    return `${date_list[1]} ${date_list[2]} ${date_list[3]}`
}
const findDateDuration = (expireDateStr) => {
    const now = moment()
    const expireDate = moment(convertDateStringFormat(expireDateStr), config.constant.DATE_INPUT_FORMAT)
    const duration = moment.duration(expireDate.diff(now))
    return Math.ceil(duration.asDays() + 1)
}

const convertDateToBE = (date, format) => {
    const dateBE = moment(date, format)
    dateBE.set({ year: dateBE.year() + 543 })
    return dateBE
}

const convertDateToAD = (date) => {
    const dateAD = moment(date)
    dateAD.set({ year: dateAD.year() - 543 })
    return dateAD
}

const replaceCitizenId = (value) => {
    let result = value.replace(/\D/g, '');
    result = result.substring(0, 1)
        + (result.length > 1 ? '-' : '')
        + result.substring(1, 5)
        + (result.length > 5 ? '-' : '')
        + result.substring(5, 10)
        + (result.length > 10 ? '-' : '')
        + result.substring(10, 12)
        + (result.length > 12 ? '-' : '')
        + result.substring(12, 13)
    return result
}


const replacePhoneNumber = (value) => {
    let result = value.replace(/\D/g, '');
    result = result.substring(0, 3)
        + (result.length > 3 ? '-' : '')
        + result.substring(3, 6)
        + (result.length > 6 ? '-' : '')
        + result.substring(6, 10)
    return result
}

const replaceForSave = (value) => {
    return value.replace(/-/g, '');
}

const intersect = (targetArray) => {
    return (current) => {
        return targetArray.filter((target) => {
            return target.id === current.id && target.id === current.id
        }).length === 0;
    }
}

const filterOptions = (data, state, key) => {
    const options = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option[key],
    })(data, state)
    const anyOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option[key],
    })(data, state)
    const diff = anyOptions.filter(intersect(options))
    const result = options.concat(diff)
    return result
}

const toBuddhistYear = (moment, format) => {
    const christianYear = moment.format('YYYY')
    const buddhishYear = (parseInt(christianYear) + 543).toString()
    return moment
        .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
        .replace(christianYear, buddhishYear)
}

const fontSizeHeader = () => {
    return window.innerWidth < 360 ? '1.2em' : '1.325em'
}

const convertTelAndBirthDateToNID = (tel,dateOfBirth) =>{
    return `${tel}${moment(dateOfBirth).format('DDMMYY')}`
}

export default {
    compare,
    isMobile,
    convertDateStringFormat,
    findDateDuration,
    convertDateToBE,
    convertDateToAD,
    replaceCitizenId,
    replacePhoneNumber,
    replaceForSave,
    filterOptions,
    toBuddhistYear,
    fontSizeHeader,
    convertTelAndBirthDateToNID
}
