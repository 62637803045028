import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import Logo from "../../assets/logo/settings_logo.png";
import PeopleIcon from "../../assets/logo/people_logo.png";
import PasswordIcon from "../../assets/logo/password_logo.png";
import Header from '../../components/Header';
import withPersonal from '../../components/HoC/withPersonal';
import { Body, Container, ButtonWrapped } from '../../components/Wrapped';
import { MAIN_COLOR_INPUT } from '../../App.theme';
import SubBackground from "../../assets/background/sub_background.png"
import SubBackgroundDesktop from "../../assets/background/sub_background_desktop.png"
import styled from 'styled-components';
import { config } from '../../config';
import { useLastLocation } from "react-router-last-location";
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
    root: {
        '& .content-container': {
            maxWidth: '360px',
            width: '90%',
            '& .button': {
                background: MAIN_COLOR_INPUT,
                color: theme.palette.text.secondary,
                padding: '10px 0px 10px 0px',
                marginTop: 25,
                '&:first-of-type': {
                    marginTop: 50
                },
                '& span, h3': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: theme.typography.fontWeightMedium
                },
                '& img': {
                    width: 20,
                    marginRight: 25,
                }
            }
        },
        '& .logo-container': {
            '& img': {
                width: '30px',
                height: '30px'
            }
        }
    }
}))


const ContainerWrapped = styled(Container)`
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media(min-width: 768px){
        background: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat; 
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`

const Settings = ({ label, classes }) => {
    const history = useHistory()
    const settingsClasses = useStyles()
    const lastLocation = useLastLocation()

    const onEditPersonalInfo = () => {
        history.push({
            pathname: config.route.memberPersonal,
            state: {
                edit: true
            }
        })
    }
    const onResetPassword = () => {
        history.push({
            pathname: config.route.memberVerify,
            state: {
                verify: true
            }
        })
    }

    if (lastLocation.pathname === config.route.memberResetPassword && sessionStorage.getItem('verifyResetMode')) {
        sessionStorage.clear('verifyResetMode')
        history.replace({ pathname: config.route.memberResetPasswordSuccess, state: { settings: true } })
    }

    return (
        <ContainerWrapped className={[classes.root, settingsClasses.root]}>
            <Body>
                <Header title={label.settings.header} logo={Logo} />
                <div className="content-container">
                    <ButtonWrapped className="button" variant="contained" size="medium" onClick={onEditPersonalInfo}>
                        <Typography variant="h3">
                            <img src={PeopleIcon} alt="people_icon" />{label.settings.edit_personal}
                        </Typography>
                    </ButtonWrapped>
                    <ButtonWrapped className="button" variant="contained" size="medium" onClick={onResetPassword}>
                        <Typography variant="h3">
                            <img src={PasswordIcon} alt="password_icon" />{label.settings.change_password}
                        </Typography>
                    </ButtonWrapped>
                </div>
            </Body>
        </ContainerWrapped>
    )
}

export default withPersonal(Settings)