import { config } from "../../../../../config";
import http from "../../../../../http";
import { GET_TIRE_SIZE_BEGIN, GET_TIRE_SIZE_SUCCESS } from "./type";
import { hasErrors } from "../../../error/actions";
import qs from "querystring";

export const getTireSize = (tireModelId) => {
    return async dispatch => {
        try {
            dispatch(getBegin())
            const result = await http.get(config.api.COMMON.TIRE.GET_TIRE_SIZES, {
                params: { tireModelId },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'brackets' })
                },
            })

            if (result) {
                result.sort((a, b) => config.common.compare(a.tireSize, b.tireSize))
                result.sort((a, b) => config.common.compare(a.tireSize.split(/[^X0-9.//]+/)[1].trim(), b.tireSize.split(/[^X0-9.//]+/)[1].trim()))
            }

            dispatch(getSuccess(result))
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

const getBegin = () => {
    return dispatch => {
        dispatch({ type: GET_TIRE_SIZE_BEGIN })
    }
}
const getSuccess = (items) => {
    return dispatch => {
        dispatch({ type: GET_TIRE_SIZE_SUCCESS, payload: { items, loading: false } })
    }
}