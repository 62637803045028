import { config } from "../../../../../config";
import http from "../../../../../http";
import { GET_CAR_BRAND_BEGIN, GET_CAR_BRAND_SUCCESS } from "./type";
import { hasErrors } from "../../../error/actions";
import qs from 'querystring'

export const getCarBrand = (keyword) => {
    return async dispatch => {
        try {
            dispatch(getBegin())
            const result = await http.get(config.api.COMMON.CAR.GET_CAR_BRANDS, {
                params: { keyword },
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: 'brackets' })
                },
            })
            dispatch(getSuccess(result))
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

const getBegin = () => {
    return dispatch => {
        dispatch({ type: GET_CAR_BRAND_BEGIN })
    }
}
const getSuccess = (items) => {
    return dispatch => {
        dispatch({ type: GET_CAR_BRAND_SUCCESS, payload: { items, loading: false } })
    }
}