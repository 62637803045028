import { Button, CircularProgress, FormControlLabel, Grid, makeStyles, Typography, withStyles } from '@material-ui/core';
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { FastField, Field, Formik } from 'formik';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import { ERROR_COLOR, MAIN_COLOR_INPUT, MAIN_COLOR } from '../../App.theme';
import CameraLogo from "../../assets/logo/camera_logo.png";
import { config } from '../../config';
import { uploadFile } from '../../store/reducers/common/file/uploadImage/actions';
import { getReason } from '../../store/reducers/common/other/reason/actions';
import { getTireBrand } from '../../store/reducers/common/tire/tireBrand/actions';
import { getTireModel } from '../../store/reducers/common/tire/tireModel/actions';
import { getTireSize } from '../../store/reducers/common/tire/tireSize/actions';
import { warrantySubmit, clearWarrantyForm } from '../../store/reducers/warranty/actions';
import Radio from '../Radio';
import CustomSelect from '../Select';
import SkeletonLoader from '../SkeletonLoader';
import TireCheckbox from '../TireCheckBox/TireCheckbox';
import TireDetailGroup from '../TireCheckBox/TireDetailGroup';
import ValidateError from '../ValidateError';
import { AutoCompleteWrapped, baseStyles, ButtonWrapped, Col, Footer, FormWrapped, LabelWrapped, RadioGroupWrapped, Row, TextFieldWrapped, ToggleButtonGroupWrapped, ToggleButtonWrapped, UploadFile } from '../Wrapped';

const UploadButton = withStyles(theme => ({
    root: {
        width: '100%',
        height: '160px',
        background: (props) => props.error || props.success ? "transparent" : MAIN_COLOR_INPUT,
        border: (props) => props.error ? `1px solid ${ERROR_COLOR}` : props.success ? `1px solid ${MAIN_COLOR}` : 'none',
        boxShadow: 'none !important',
        '& .content-container': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& img': {
                width: 35,
                height: 35
            },
            '& .image-upload': {
                width: 'auto',
                height: '140px',
            },
            '& span': {
                marginTop: 10,
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.secondary
            },
        },
    },
}))(Button)

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiToggleButton-root': {
            borderRadius: '50% !important',
            width: '60px',
            height: '60px',
            border: 'none',
            fontWeight: theme.typography.fontWeightBold,
        },
        '& .tireChangeQuantity-container': {
            width: 'inherit',
            '& .title': {
                marginBottom: 20
            },
            '& h3': {
                fontWeight: theme.typography.fontWeightBold,
            },
        },
    }
}))

const validate = (values) => {
    const errors = {};
    for (let [key, value] of Object.entries(values)) {
        if (typeof value === 'object') {
            if (key === "onBehalf") {
                if (value.purchaseBy === config.constant.COMPANY && !value.companyName) {
                    errors[key] = {
                        companyName: {
                            required: true
                        }
                    }
                }
            }

            if (key === "tireChange") {
                if (!value.tireChangeQuantity) {
                    errors[key] = {
                        ...errors[key],
                        tireChangeQuantity: {
                            required: true
                        }
                    }
                }
                if (!value.tireChangePosition.length || value.tireChangePosition.length < value.tireChangeQuantity) {
                    errors[key] = {
                        ...errors[key],
                        tireChangePosition: {
                            required: true
                        }
                    }
                }
                if (value.tireChangePosition.length) {
                    value.tireChangePosition.forEach(p => {
                        if (!p.tireSizeId || !p.week || !p.year) {
                            errors[key] = {
                                ...errors[key],
                                detail: {
                                    ...errors[key]?.detail,
                                    [p.positionOfTire]: {
                                        tireSizeId: { required: true },
                                        week: { required: true },
                                        year: { required: true },
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }
        if (!value) {
            errors[key] = {
                required: true
            }
        }
    }

    if (values.imageErrors) {
        if (values.imageErrors?.fileType) {
            errors["fileType"] = true
        }
        if (values.imageErrors?.fileSize) {
            errors["fileSize"] = true
        }
    }

    return errors;
}

const TireForm = ({ label, formData }) => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const dispatcher = useDispatch()
    const common = useSelector(state => state.common)

    useEffect(() => {
        dispatcher(getTireModel())
        dispatcher(getTireBrand())
        dispatcher(getReason())
    }, [dispatcher])

    const onCancel = useCallback((e) => {
        history.replace(config.route.register)
        dispatcher(clearWarrantyForm())
    }, [history, dispatcher])


    const onTireModelChange = (id) => {
        dispatcher(getTireSize(id))
    }


    if (common.tire.tireModel.loading || common.tire.tireBrand.loading || common.other.reason.loading) {
        return <SkeletonLoader fieldCount={10} />
    }

    return (
        <Formik
            initialValues={formData}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    dispatcher(warrantySubmit(values, () => {
                        setSubmitting(false)
                        history.push(config.route.registerWarrantyConfirm)
                    }))
                }, 400);
            }}
        >
            {({
                handleSubmit,
                isSubmitting,
            }) => (
                    <FormWrapped className={[baseClasses.root, classes.root]} onSubmit={handleSubmit}>
                        <FastField name="onBehalf" >
                            {({ field, form: { values, setFieldValue, setFieldTouched, handleBlur }, meta }) => (
                                <React.Fragment>
                                    <Grid container>
                                        <RadioGroupWrapped
                                            aria-label="onBehalf" name="onBehalf"
                                            value={values.onBehalf.purchaseBy}
                                            onChange={async ({ target: { value } }) => {
                                                await setFieldValue("onBehalf.purchaseBy", value)
                                                setFieldValue("purchaseBy", value)
                                                if (value === config.constant.PERSONAL) {
                                                    await setFieldValue("onBehalf.companyName", '')
                                                    setFieldValue("companyName", undefined)
                                                }
                                            }}>
                                            <FormControlLabel
                                                style={{ margin: 'auto' }}
                                                value={config.constant.PERSONAL} control={<Radio />}
                                                label={
                                                    <LabelWrapped active={values.onBehalf.purchaseBy === config.constant.PERSONAL}>
                                                        {I18n.t("tire.on_behalf", { behalf: label.personal })}
                                                    </LabelWrapped>} />
                                            <FormControlLabel
                                                style={{ margin: 'auto' }}
                                                value={config.constant.COMPANY} control={<Radio />}
                                                label={
                                                    < LabelWrapped active={values.onBehalf.purchaseBy === config.constant.COMPANY}>
                                                        {I18n.t("tire.on_behalf", { behalf: label.company })}
                                                    </LabelWrapped>
                                                }
                                            />
                                        </RadioGroupWrapped>
                                    </Grid>
                                    {
                                        values.onBehalf.purchaseBy === config.constant.COMPANY &&
                                        <Grid container>
                                            <TextFieldWrapped
                                                {...field}
                                                value={values.onBehalf.companyName}
                                                label={label.company_name}
                                                error={meta.error?.companyName && meta.touched}
                                                onChange={async ({ target: { value } }) => {
                                                    await setFieldValue("onBehalf.companyName", value)
                                                    setFieldValue("companyName", value)
                                                }}
                                                onBlur={async (e) => {
                                                    handleBlur(e)
                                                    setFieldTouched('onBehalf.companyName', true)
                                                    setFieldValue('companyName', e.target.value.trim())
                                                    await setFieldValue('onBehalf.companyName', e.target.value.trim())
                                                }}
                                                inputProps={{
                                                    maxLength: 100
                                                }}
                                            />
                                            <ValidateError
                                                error={meta.error?.companyName && meta.touched}
                                                message={I18n.t("validate_message.please_input", { label: label.company_name })} />
                                        </Grid>
                                    }
                                </React.Fragment>
                            )}
                        </FastField>
                        <Field name="tireChangeModel">
                            {({ field, form, meta }) => (
                                <Grid container>
                                    <AutoCompleteWrapped
                                        {...field}
                                        onChange={(_, data) => {
                                            if (data?.id) {
                                                onTireModelChange(data?.id)
                                            }
                                            form.setFieldValue('tireChangeModelId', data?.id)
                                            form.setFieldValue('tireChangeModel', data)
                                            if (form?.values?.tireChange?.tireChangePosition.length) {
                                                const positions = form.values.tireChange.tireChangePosition
                                                positions.forEach(position => {
                                                    position.tireSizeId = ''
                                                })
                                                form.setFieldValue('tireChange.tireChangePosition', positions)
                                            }
                                        }}
                                        disableClearable
                                        onBlur={async (e) => {
                                            form.handleBlur(e)
                                            form.setFieldTouched('tireChangeModel', true)
                                        }}
                                        options={common.tire.tireModel.items}
                                        getOptionLabel={(option) => option?.tireModelName || ""}
                                        filterOptions={(option, state) => config.common.filterOptions(option, state, "tireModelName")}
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.tireModelName, inputValue);
                                            const parts = parse(option.tireModelName, matches);
                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        renderInput={params => (
                                            <TextFieldWrapped
                                                {...params}
                                                variant="outlined"
                                                label={label.tire_change_model}
                                                value={field.value}
                                                error={form.errors?.tireChangeModel && form.touched?.tireChangeModel} />
                                        )}
                                    />
                                    <ValidateError
                                        error={form.errors?.tireChangeModel && form.touched?.tireChangeModel}
                                        message={I18n.t("validate_message.please_select", { label: label.tire_change_model })} />
                                </Grid>
                            )}
                        </Field>
                        <Field name="tireChange">
                            {({ field, form, meta }) => (
                                <React.Fragment>
                                    <Grid container>
                                        <div className="tireChangeQuantity-container">
                                            <Typography className="title" color="textPrimary">
                                                {label.tire_change_quantity}
                                            </Typography>
                                            <ToggleButtonGroupWrapped
                                                value={form.values.tireChange.tireChangeQuantity}
                                                exclusive
                                                onChange={(e, value) => {
                                                    form.setFieldValue("tireChangeQuantity", value || form.values.tireChange.tireChangeQuantity)
                                                    form.setFieldValue("tireChange.tireChangeQuantity", value || form.values.tireChange.tireChangeQuantity)
                                                    form.setFieldValue("tireChange.firstTimeFlag", true)
                                                }}
                                            >
                                                {new Array(4)
                                                    .fill()
                                                    .map((a, i) =>
                                                        <ToggleButtonWrapped
                                                            key={`${i + 1}_quantity`}
                                                            value={i + 1}
                                                            className={meta.error?.tireChangeQuantity && meta.touched && 'toggle-error'}>
                                                            <Typography variant="h3">
                                                                {i + 1}
                                                            </Typography>
                                                        </ToggleButtonWrapped>)}
                                            </ToggleButtonGroupWrapped>
                                            <ValidateError
                                                error={meta.error?.tireChangeQuantity && meta.touched}
                                                message={I18n.t("validate_message.please_select", { label: label.tire_change_quantity_validate })} />
                                        </div>
                                    </Grid>
                                    <Grid container>
                                        <TireCheckbox
                                            title={label.tire_change_position.main}
                                            data={form.values.tireChange.tireChangePosition}
                                            quantity={form.values.tireChange.tireChangeQuantity}
                                            onChange={async (value) => {
                                                form.setFieldValue("tireChangePosition", value)
                                                await form.setFieldValue("tireChange.tireChangePosition", value)
                                            }}
                                            error={meta.error?.tireChangePosition && meta.touched}
                                        />
                                        <ValidateError
                                            error={meta.error?.tireChangePosition && meta.touched}
                                            message={I18n.t("validate_message.please_select", { label: label.tire_change_position.main })} />
                                    </Grid>
                                    <TireDetailGroup
                                        data={form.values.tireChange.tireChangePosition}
                                        tire_size={common.tire.tireSize.items}
                                        tire_label={label.tire_change_position}
                                        errors={{ ...meta.error?.detail, touched: form.touched?.tireChange }}
                                        onTouched={(enumValue) => {
                                            form.setFieldTouched(`tireChange.${enumValue}`, true)
                                        }}
                                        onChange={(value, target, enumValue) => {
                                            const results = form.values.tireChange.tireChangePosition.map(tire => {
                                                if (form.values.tireChange.firstTimeFlag || tire.positionOfTire === enumValue) {
                                                    tire[target] = value
                                                    if (target === 'tireSizeId') {
                                                        const item = common.tire.tireSize.items.find(item => item.id === Number(value))
                                                        tire['tireSizeText'] = item?.tireSize
                                                        const isFront = enumValue.includes('F')
                                                        form.values.tireChange.tireChangePosition.forEach(t => {
                                                            if (isFront) {
                                                                if (t.positionOfTire.includes('F')) {
                                                                    t.tireSizeId = value
                                                                    t.tireSizeText = item?.tireSize
                                                                }
                                                            } else {
                                                                if (t.positionOfTire.includes('B')) {
                                                                    t.tireSizeId = value
                                                                    t.tireSizeText = item?.tireSize
                                                                }
                                                            }
                                                        })
                                                    }
                                                    if (target === 'week') {
                                                        tire['manufacturingWeek'] = Number(value)
                                                    }
                                                    if (target === 'year') {
                                                        tire['manufacturingYear'] = Number(`${config.constant.CENTURY}${value}`)
                                                    }
                                                }
                                                return tire
                                            })
                                            form.setFieldValue("tireChangePosition", results)
                                            form.setFieldValue("tireChange.firstTimeFlag", false)
                                            form.setFieldValue("tireChange.tireChangePosition", results)
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </Field>
                        <FastField name="replacmentReasonsId" >
                            {({ field, form: { values, errors, touched, setFieldValue }, meta }) => (
                                <Grid container>
                                    <CustomSelect
                                        {...field}
                                        label={label.tire_change_reason}
                                        value={values.replacmentReasonsId}
                                        onChange={({ target: { value } }) => {
                                            const item = common.other.reason.items.find(item => item.id === Number(value))
                                            setFieldValue('replacmentReasonsText', item.reasonTh)
                                            setFieldValue('replacmentReasonsId', value)
                                        }}
                                        data={common.other.reason.items}
                                        dataText="reasonTh"
                                        error={errors?.replacmentReasonsId && touched?.replacmentReasonsId}
                                    />
                                    <ValidateError
                                        error={errors?.replacmentReasonsId && touched?.replacmentReasonsId}
                                        message={I18n.t("validate_message.please_select", { label: label.tire_change_reason })} />
                                </Grid>
                            )}
                        </FastField>
                        <FastField name="previousTireBrand" >
                            {({ field, form, meta }) => (
                                <Grid container>
                                    <AutoCompleteWrapped
                                        {...field}
                                        onChange={(_, data) => {
                                            form.setFieldValue('previousTireBrandId', data?.id)
                                            form.setFieldValue('previousTireBrand', data)
                                        }}
                                        disableClearable
                                        onBlur={async (e) => {
                                            form.handleBlur(e)
                                            form.setFieldTouched('previousTireBrand', true)
                                        }}
                                        options={common.tire.tireBrand.items}
                                        getOptionLabel={(option) => option?.tireBrandName || ""}
                                        filterOptions={(option, state) => config.common.filterOptions(option, state, "tireBrandName")}
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.tireBrandName, inputValue);
                                            const parts = parse(option.tireBrandName, matches);
                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        renderInput={params => (
                                            <TextFieldWrapped
                                                {...params}
                                                variant="outlined"
                                                label={label.previous_tire_brand}
                                                value={field.value}
                                                error={form.errors?.previousTireBrand && form.touched?.previousTireBrand} />
                                        )}
                                    />

                                    <ValidateError
                                        error={form.errors?.previousTireBrand && form.touched?.previousTireBrand}
                                        message={I18n.t("validate_message.please_select", { label: label.previous_tire_brand })} />
                                </Grid>
                            )}
                        </FastField>
                        <FastField name="currentMileage" >
                            {({ field, form: { values, errors, touched, setFieldValue }, meta }) => (
                                <Grid container>
                                    <TextFieldWrapped
                                        {...field}
                                        variant="outlined"
                                        label={label.current_miles}
                                        value={values.currentMileage}
                                        error={errors?.currentMileage && touched?.currentMileage}
                                        onChange={({ target: { value } }) => {
                                            const clearText = value.replace(',', '')
                                            let number = clearText && Number(clearText)
                                            if (isNaN(number) || clearText.length > 6) return
                                            let result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                            setFieldValue('currentMileage', result)
                                        }}
                                        type="tel" />
                                    <ValidateError
                                        error={errors?.currentMileage && touched?.currentMileage}
                                        message={I18n.t("validate_message.please_input", { label: label.current_miles })} />
                                </Grid>
                            )}
                        </FastField>
                        <Field name="image">
                            {({ field, form, meta }) => (
                                <Grid container>
                                    <input
                                        accept="image/*"
                                        className={baseClasses.inputFile}
                                        id="image"
                                        onClick={() => {
                                            document.body.onfocus = () => {
                                                form.setFieldTouched('image', true)
                                            }
                                        }}
                                        onChange={async ({ currentTarget: { files } }) => {
                                            const file = files[0]
                                            form.setFieldTouched('image', true)
                                            if (file) {
                                                const { size, type } = file
                                                const fileSizeCorrect = size <= config.constant.MAX_FILE_SIZE
                                                const fileTypeCorrect = type.includes(config.constant.FILE_MIME_TYPE)
                                                if (fileSizeCorrect && fileTypeCorrect) {
                                                    dispatcher(uploadFile(file, async (result) => {
                                                        await form.setFieldValue('receiptImage', result?.url)
                                                        await form.setFieldValue('image', file)
                                                    }))
                                                } else {
                                                    await form.setFieldValue('image', '')
                                                    await form.setFieldValue('receiptImage', '')
                                                }
                                                await form.setFieldValue('imageErrors', {
                                                    fileType: !fileTypeCorrect,
                                                    fileSize: !fileSizeCorrect
                                                })
                                            } else {
                                                if (!field.value) {
                                                    form.setFieldTouched('image', true)
                                                }
                                            }
                                        }}
                                        type="file"
                                    />
                                    <UploadFile htmlFor="image">
                                        <UploadButton className="upload-button" variant="contained" color="secondary"
                                            component="span"
                                            error={meta.error && meta.touched ? 'true' : undefined}
                                            success={field.value ? 'true' : undefined}>
                                            <div className="content-container">
                                                {
                                                    common.file.upload.loading ? <CircularProgress /> :
                                                        field.value ?
                                                            <img className="image-upload" src={URL.createObjectURL(field.value)} alt="upload" /> :
                                                            <React.Fragment>
                                                                <img src={CameraLogo} alt="camera" />
                                                                <span>{label.upload_proof_of_purchase}</span>
                                                            </React.Fragment>
                                                }
                                            </div>
                                        </UploadButton>
                                    </UploadFile>
                                    <div>
                                        <ValidateError
                                            error={meta.error && meta.touched && !form.errors?.fileSize && !form.errors?.fileType}
                                            message={I18n.t("validate_message.please", { label: label.upload_proof_of_purchase })} />
                                        <ValidateError
                                            error={form.errors?.fileSize && meta.touched}
                                            message={I18n.t("validate_message.file_size")} />
                                        <ValidateError
                                            error={form.errors?.fileType && meta.touched}
                                            message={I18n.t("validate_message.file_type")} />
                                    </div>
                                </Grid>
                            )}
                        </Field>
                        <Footer>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Col xs={5} >
                                    <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={onCancel}>
                                        {label.button.cancel}
                                    </ButtonWrapped>
                                </Col>
                                <Col xs={7}>
                                    <ButtonWrapped disabled={isSubmitting} variant="contained" size="large" type="submit" color="primary">
                                        {label.button.next}
                                    </ButtonWrapped>
                                </Col>
                            </Row>
                        </Footer>
                    </FormWrapped>
                )}
        </Formik >
    )
}

export default TireForm