import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Row } from '../Wrapped';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 15,
        '& .MuiGrid-container': {
            width: '100%',
            padding: '5px 20px 5px 20px',
            '& .skel-load': {
                width: '100%',
            }
        }
    }
}))

const SkeletonLoader = ({ fieldCount = 1 }) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {
                new Array(fieldCount).fill().map((a, _i) => (
                    <Row key={_i}>
                        <Skeleton className="skel-load" animation="wave" />
                    </Row>
                ))
            }
        </div>
    )
}
export default SkeletonLoader