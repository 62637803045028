import DialogMessageModel from "../../../components/DialogMessage/DialogMessageModel";
import { CLEAR_DIALOG, SET_DIALOG } from "./type";

const initialState = new DialogMessageModel()
export const DialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIALOG:
            return action.payload
        case CLEAR_DIALOG:
            return initialState
        default:
            return state
    }
};
