import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { MAIN_COLOR_INPUT, MAIN_COLOR } from '../App.theme';
import { makeStyles } from '@material-ui/core';

const PinCodeItemContainer = styled.div.attrs([{ className: 'flex-container flex-row' }])`
    width: 100%;
    max-width: 500px;
`


const PinBlockValueStyle = {
    position: 'absolute',
    width: 10,
    height: 10,
    background: MAIN_COLOR,
    borderRadius: '50%',
    transition: 'opacity 200ms',
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        background: 'transparent',
        border: 'none',
        outline: 'none',
        margin: 0,
        padding: 0,
        marginTop: 30,
        marginBottom: 30,
        '&:focus': {
            outline: 'none',
        },
        '& .pin-block': {
            position: 'relative',
            height: `50px`,
            width: `40px`,
            border: 'none',
            borderTopRightRadius: theme.shape.borderRadius,
            borderTopLeftRadius: theme.shape.borderRadius,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: MAIN_COLOR_INPUT,
            marginLeft: 6,

            '&:first-of-type': {
                marginLeft: 0
            },
            '&:last-of-type': {
            },
        }
    }
}))


const PinCode = ({ pinCode, length, onClick }) => {
    const classes = useStyles()
    const getElementPinCode = () => {
        const elements = []
        for (let index = 0; index < length; index++) {
            elements.push(React.createElement('div', { key: `pincode_${index}`, className: "pin-block" },
                React.createElement('div', {
                    style: { ...PinBlockValueStyle, opacity: pinCode[index] || pinCode[index] === 0 ? 1 : 0 }
                }, null)
            ))
        }
        return elements
    }

    return (
        <button className={classes.root} onClick={onClick}>
            <PinCodeItemContainer>
                {getElementPinCode().map(e => e)}
            </PinCodeItemContainer>
        </button>
    )
}

PinCode.defaultProps = {
    length: 6,
    pinCode: []
}

PinCode.propTypes = {
    length: PropTypes.number,
    pinCode: PropTypes.array.isRequired
}

export default PinCode