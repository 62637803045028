import { GET_CAR_MODEL_BEGIN, GET_CAR_MODEL_SUCCESS } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const CarModelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAR_MODEL_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_CAR_MODEL_SUCCESS:
            return action.payload
        default:
            return state
    }
};
