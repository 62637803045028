import { GET_TITLE_BEGIN, GET_TITLE_SUCCESS } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const TitleReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TITLE_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_TITLE_SUCCESS:
            return action.payload
        default:
            return state
    }
};
