const root = ''

const route = {
    register: '/register',
    registerVerifyDealer: '/register/verify/dealer',
    registerWarranty: '/register/warranty',
    registerWarrantyConfirm: '/register/warranty/confirm',
    registerCustomer: '/register/personal/info',
    registerCar: '/register/personal/info/car',
    registerCustomerConfirm: '/register/personal/info/confirm',
    member: '/member',
    memberWarranty: '/member/warranty',
    memberSettings: '/member/settings',
    memberPersonal: '/member/personal/edit',
    memberVerify: '/member/verify',
    memberVerifyForgot: '/member/verify/forgot',
    memberResetPassword: '/member/reset/password',
    memberResetPasswordSuccess: '/member/reset/password/success',
    qa: '/register/qa',
    pdpa: '/pdpa',
    thanks: '/thanks',
    error: '/error',
}

const getRoute = () => {
    for (const key in route) {
        route[key] = root + route[key]
    }
    return route
}

export default {
    ...getRoute()
}