import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Col, Row } from '../Wrapped';
import { I18n } from 'react-redux-i18n';
import { config } from '../../config';
import { useState } from 'react';
import qs from 'querystring'
import http from '../../http';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 15,
        width: '100%',
        background: 'black',
        color: 'white',
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        '&:first-of-type': {
            marginTop: 0
        },
        '& .image-container': {
            background: 'white',
            width: '38%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            color: theme.palette.secondary.main,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            '& img': {
                height: 120,
                alignSelf: 'center',
            },
            '& .content': {
                padding: '15px 10px 15px 10px',
                '& .title': {
                    fontSize: '0.65em',
                    fontWeight: 600,
                },
                '& .sub-title': {
                    lineHeight: 1,
                    fontSize: '0.65em',
                    fontWeight: theme.typography.fontWeightRegular,
                },
            }
        },
        '& .content-container': {
            width: '62%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: 10,
            paddingRight: 10,
            '& .content-container-wrapper': {
                '& .main-content-container': {
                    '& .title': {

                    },
                    '& .detail': {
                        color: theme.palette.primary.main,
                        fontWeight: theme.typography.fontWeightBold
                    },
                    '& .error': {
                        color: theme.palette.error.main,
                        fontWeight: theme.typography.fontWeightRegular
                    },
                },
                '& .sub-content-container': {
                    display: 'flex',
                    '& .content': {
                        display: 'flex',
                        flexDirection: 'column',
                        '& .title': {

                        },
                        '& .detail': {
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightRegular
                        },
                    },
                },
            },
        },
    }
}))


const getTireImage = async (tireModelId, callback) => {
    const result = await http.get(config.api.COMMON.UPLOAD.GET_TIRE_IMG, {
        params: { tireModelId },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'brackets' })
        },
    })
    callback && callback(result)
}
const isExpired = (expireDate) => {
    const now = moment()
    const expire_date = moment(config.common.convertDateStringFormat(expireDate), config.constant.DATE_INPUT_FORMAT)
    return now.unix() > expire_date.unix()
}

const WarrantyCard = ({ label, data }) => {
    const classes = useStyles()
    const { warrantyCode, warrantyExpiredDate, carLicense, carModelName, dealerName, tireModelName, tireSize, tireModelId } = data
    const alreadyExpired = isExpired(warrantyExpiredDate);
    const status = alreadyExpired ? label.status_inactive : I18n.t('warranty.status_active', { date: config.common.findDateDuration(warrantyExpiredDate) })
    const [loading, setLoading] = useState(false)
    const [tireImage, setTireImage] = useState(null)

    useEffect(() => {
        let unmounted = false;
        const getAsyncFunc = async () => {
            setLoading(true)
            await getTireImage(tireModelId, (result) => {
                if (!unmounted) {
                    setTireImage(result.img)
                    setLoading(false)
                }
            })
        }
        getAsyncFunc()
        return () => { unmounted = true };
    }, [tireModelId])
    return (
        <div className={classes.root} style={{ opacity: alreadyExpired ? 0.5 : 1 }}>
            <div className="image-container">
                {loading ?
                    <CircularProgress /> :
                    <img src={tireImage} alt="tire" />
                }
                <div className="content">
                    <Typography className="title" variant="subtitle1">
                        {tireModelName}
                    </Typography>
                    <Typography className="sub-title" variant="subtitle1">
                        {tireSize}
                    </Typography>
                </div>
            </div>
            <div className="content-container">
                <div className="content-container-wrapper">
                    <div className="main-content-container">
                        <Row>
                            <Col style={{ maxWidth: '50%', flexBasis: '50%' }}>
                                <Typography>
                                    {label.warranty_id}
                                </Typography>
                            </Col>
                            <Col style={{ maxWidth: '8%', flexBasis: '8%' }}>
                                <Typography>
                                    {":"}
                                </Typography>
                            </Col>
                            <Col style={{ maxWidth: '42%', flexBasis: '42%' }}>
                                <Typography variant="subtitle1" className="detail">
                                    {warrantyCode}
                                </Typography>
                            </Col>
                        </Row>
                    </div>
                    <div className="main-content-container">
                        <Row>
                            <Col style={{ maxWidth: '50%', flexBasis: '50%' }}>
                                <Typography>
                                    {label.expire_date}
                                </Typography>
                            </Col>
                            <Col style={{ maxWidth: '8%', flexBasis: '8%' }}>
                                <Typography>
                                    {":"}
                                </Typography>
                            </Col>
                            <Col style={{ maxWidth: '42%', flexBasis: '42%' }}>
                                <Typography variant="subtitle1" className="detail">
                                    {moment(config.common.convertDateStringFormat(warrantyExpiredDate), config.constant.DATE_INPUT_FORMAT).format(config.constant.WARRANTY_DATE_FORMAT)}
                                </Typography>
                            </Col>
                        </Row>
                    </div>
                    <div className="sub-content-container" style={{ marginTop: 10 }}>
                        <div className="content">
                            <Typography className="title">
                                {label.car_license}
                            </Typography>
                            <Typography className="detail" variant="subtitle1">
                                {carLicense}
                            </Typography>
                        </div>
                        <div className="content" style={{ marginLeft: 20 }}>
                            <Typography className="title">
                                {label.car_model}
                            </Typography>
                            <Typography className="detail" variant="subtitle1">
                                {carModelName}
                            </Typography>
                        </div>
                    </div>
                    <div className="sub-content-container" style={{ marginTop: 10 }}>
                        <div className="content">
                            <Typography className="title">
                                {label.dealer_name}
                            </Typography>
                            <Typography className="detail" variant="subtitle1">
                                {dealerName}
                            </Typography>
                        </div>
                    </div>
                    <div className="main-content-container" style={{ marginTop: 10 }}>
                        <Row>
                            <Col xs={3}>
                                <Typography>
                                    {label.status}
                                </Typography>
                            </Col>
                            <Col xs={1}>
                                <Typography>
                                    {":"}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography variant="subtitle1" className={alreadyExpired ? 'error' : 'detail'}>
                                    {status}
                                </Typography>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WarrantyCard