import { GET_PROVINCES_BEGIN, GET_PROVINCES_SUCCESS, CLEAR_PROVINCE } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const ProvinceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROVINCES_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_PROVINCES_SUCCESS:
            return action.payload
        case CLEAR_PROVINCE:
            return {
                ...state,
                loading: false,
                items: []
            }
        default:
            return state
    }
};
