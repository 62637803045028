import { Typography, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const Container = withStyles(theme => ({
    root: {
        marginTop: 5,
        color: theme.palette.error.main,
        display: 'flex',
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        '& .icon-container': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 3,
            '& .icon': {
                width: '0.8em',
                height: '0.8em',
                zIndex: 1
            },
            '& .icon-color-wrapped': {
                width: '0.6em',
                height: '0.6em',
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: 0
            }
        },
    }
}))(({ classes, message }) => {
    return (
        <Typography className={classes.root}>
            <span className="icon-container">
                <ErrorIcon className="icon" />
                <span className="icon-color-wrapped" />
            </span>
            {message}
        </Typography>
    )
})

const ValidateError = ({ error, message }) => {
    if (!error) return null

    return (
        <Container message={message} />
    )
}

export default ValidateError