class WarrantyFormModel {
    onBehalf = {
        purchaseBy: "P",
        companyName: "",
    }
    purchaseBy = "P"
    companyName

    tireChangeModel = null
    tireChangeModelId

    previousTireBrand = null
    previousTireBrandId

    currentMileage = ""

    tireChange = {
        tireChangeQuantity: 0,
        tireChangePosition: [],
        LF: false,
        RF: false,
        LB: false,
        RB: false,
        firstTimeFlag: true,
    }
    tireChangeQuantity
    tireChangePosition

    replacmentReasonsId = ""
    replacmentReasonsText

    image = ""
    receiptImage = ""
}

export default WarrantyFormModel