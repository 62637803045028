import { ThemeProvider as MainThemeProvider } from "@material-ui/core/styles";
import React from 'react';
import { Provider } from 'react-redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from "react-redux-i18n";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoute from './App.route';
import theme from './App.theme';
import store from './store';
import i18n from "./store/i18n";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { LastLocationProvider } from 'react-router-last-location'

syncTranslationWithStore(store);
store.dispatch(loadTranslations(i18n));
store.dispatch(setLocale('th'));
class LocalizedUtils extends MomentUtils {
  locale = "th"

  getCalendarHeaderText(date) {
    const d = moment(date)
    d.set({ year: d.year() + 543 })
    return this.format(d, "MMMM YYYY", { locale: this.locale });
  }
  getYearText(date) {
    const d = moment(date)
    return Number(d.year() + 543).toString()
  }
}
const App = () => {
  return (
    <MainThemeProvider theme={theme}>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={LocalizedUtils}>
          <BrowserRouter>
            <LastLocationProvider>
              <AppRoute />
            </LastLocationProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </Provider>
    </MainThemeProvider >
  )
}

export default App;
