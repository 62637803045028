import { createMuiTheme } from "@material-ui/core";

export const MAIN_COLOR_INPUT = "rgba(0, 0, 0, 0) linear-gradient(0deg, #1f1f1f 0%, #353535 100%)";
export const COLOR_TRANSPARENT = "transparent";
export const BORDER_RADIUS = 6;
export const MAIN_COLOR = '#FEE101';
export const ERROR_COLOR = '#ec1c24';
export const TEXT_PRIMARY = 'rgba(255, 255, 255, 1)';
export const TEXT_SECONDARY = 'rgba(255, 255, 255, 0.7)';
export const TEXT_DISABLED = 'rgba(255, 255, 255, 0.5)';
export const TEXT_HINT = 'rgba(255, 255, 255, 0.5)';
export const TEXT_ICON = 'rgba(255, 255, 255, 0.5)';


const fontFamily = ['Prompt', 'sans-serif',].join(",")

let theme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            root: {
                borderRadius: BORDER_RADIUS,
                background: MAIN_COLOR_INPUT,
                '& ::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                    appearance: 'none'
                },
                '& :focus': {
                    background: 'transparent !important'
                },
            },
            input: {
                borderRadius: BORDER_RADIUS,
                '& :focus': {
                    background: 'transparent !important'
                },
                // '& + fieldset':{
                //     background: MAIN_COLOR_INPUT,
                // }
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "rgba(255, 255, 255, 0)",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        MuiMenu: {
            list: {
                background: MAIN_COLOR_INPUT
            }
        },
        MuiPaper: {
            root: {
                background: MAIN_COLOR_INPUT
            }
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: MAIN_COLOR,
            contrastText: "rgba(35, 35, 35, 1)",
        },
        secondary: {
            main: "#000000",
            contrastText: "#999999",
        },
        cancel: {
            main: "#343434",
            contrastText: "rgba(255, 255, 255, 0.7)",
        },
        info: {
            main: "rgba(255, 255, 255, 1)"
        },
        error: {
            main: ERROR_COLOR,
            contrastText: "#FFF"
        },
        background: {
            default: "#191919",
            paper: "#191919",
            input: "#343434",
        },
        text: {
            primary: TEXT_PRIMARY,
            secondary: TEXT_SECONDARY,
            disabled: TEXT_DISABLED,
            hint: TEXT_HINT,
            icon: TEXT_ICON,
        },
        header: {
            main: '#FEE101',
            remark: '#FFFFFF'
        },
        card: {
            main: '#121212',
        },
        separator: {
            main: "rgba(255, 255, 255, 0.2)"
        },
        keyboard: {
            main: '#232323',
            sub: '#363636',
            contrastText: "#d1d1d1",
        }
    },
    typography: {
        fontFamily,
        fontSize: 13,
        h1: '1.25em',
        h2: '1.125em',
        h3: '1em',
        h4: '0.85em',
        '@media (min-width:600px)': {
            fontSize: 16,
            h1: '1.5em',
            h2: '1.375em',
            h3: '1.25em',
            h4: '1.125em',
        },
        fontWeightLight: 100,
        fontWeightMedium: 200,
        fontWeightRegular: 300,
        fontWeightBold: 400
    },
    shape: {
        borderRadius: BORDER_RADIUS
    }
})
theme.typography.h1 = {
    fontFamily,
    fontSize: '2.5em',
    '@media (min-width:600px)': {
        fontSize: '2.625em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.75em',
    },
};
theme.typography.h2 = {
    fontFamily,
    fontSize: '2em',
    '@media (min-width:600px)': {
        fontSize: '2.25em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '2.5em',
    },
};
theme.typography.h3 = {
    fontFamily,
    fontSize: '1.375em',
    '@media (min-width:600px)': {
        fontSize: '1.5em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.625em',
    },
};
theme.typography.h4 = {
    fontFamily,
    fontSize: '1.125em',
    '@media (min-width:600px)': {
        fontSize: '1.25em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.375em',
    },
};

theme.typography.h5 = {
    fontFamily,
    fontSize: '1em',
    '@media (min-width:600px)': {
        fontSize: '1.125em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.25em',
    },
};

theme.typography.h6 = {
    fontFamily,
    fontSize: '0.75em',
    '@media (min-width:600px)': {
        fontSize: '0.875em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1em',
    },
};

theme.typography.subtitle1 = {
    fontFamily,
    fontSize: '0.8em',
    '@media (min-width:600px)': {
        fontSize: '0.925em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1.05',
    },
};

theme.typography.subtitle2 = {
    fontFamily,
    fontSize: '0.75em',
    '@media (min-width:600px)': {
        fontSize: '0.875em',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '1em',
    },
};

export default theme