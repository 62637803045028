import axios from "axios";
import { config } from "../config";
import ErrorModel from "../components/ErrorMessage/ErrorModel";
import { I18n } from "react-redux-i18n";
const http = axios.create({
    baseURL: config.API_URL
})
http.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, error => {
    return Promise.reject(error)
})

http.interceptors.response.use((response) => {
    if (response.data) {
        return response.data
    }
    return response
}, error => {
    if (error?.response?.data) {
        return Promise.reject(error.response.data)
    }
    if (error?.message === "Network Error") {
        const err = new ErrorModel()
        err.message = I18n.t('errors.network_error')
        return Promise.reject(err)
    }
    return Promise.reject(error)
})

export default http