import { makeStyles, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { FormControlWrapped, InputLabelWrapped, SelectWrapped } from "../Wrapped";

const useStyles = makeStyles(theme => ({
    root: {
        whiteSpace: 'nowrap',
        '& .MuiSelect-icon': {
            color: theme.palette.text.hint,
            marginTop: 2.5,
        },
        '& .MuiSelect-select': {
            paddingRight: 'unset',
        },
    }
}))

const Select = ({ id, data, dataText, dataValue, label, value = "", defaultValue, onChange, disabled, error, ...props }) => {
    const classes = useStyles()
    const tireSizeInputRef = React.useRef();
    const [tireSizeLabelWidth, setTireSizeLabelWidth] = React.useState(0);
    useEffect(() => setTireSizeLabelWidth(tireSizeInputRef.current.offsetWidth), [])
    return (
        <FormControlWrapped className={classes.root}>
            <InputLabelWrapped
                error={error}
                ref={tireSizeInputRef}
                value={value}
                htmlFor={id}>{label}</InputLabelWrapped>
            <SelectWrapped
                id={id}
                {...props}
                error={error}
                displayEmpty
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                labelWidth={tireSizeLabelWidth}
                IconComponent={ExpandMoreIcon}
                disabled={disabled}
                native={isMobile}
            >
                {
                    isMobile ?
                        <React.Fragment>
                            <option />
                            {data?.map((d, i) => {
                                return <option key={`key_${i}`} value={dataValue ? d[dataValue] : d.id}>{d[dataText]}</option>
                            })}
                        </React.Fragment> :
                        data?.map((d, i) => {
                            return <MenuItem key={`key_${i}`} value={dataValue ? d[dataValue] : d.id}>{d[dataText]}</MenuItem>
                        })

                }
            </SelectWrapped>
        </FormControlWrapped >
    )
}

Select.propTypes = {
    data: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
}

export default Select