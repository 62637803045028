import { GET_DISTRICT_BEGIN, GET_DISTRICT_SUCCESS, CLEAR_DISTRICT } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const DistrictReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DISTRICT_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_DISTRICT_SUCCESS:
            return action.payload
        case CLEAR_DISTRICT:
            return {
                ...state,
                loading: false,
                items: []
            }
        default:
            return state
    }
};
