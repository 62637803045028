class CarFormModel {
    carBrandId
    carBrand = null
    carModelId
    carModel = null
    carMilleageId = ''
    annualMileageText
    carLicense = ''
}

export default CarFormModel