import common from './common.function';
import { api, api_dev, api_prod } from "./config.api";
import constant from "./config.constant";
import messages from "./config.message";
import route from "./config.route";

export const isDevelopment = process.env.REACT_APP_STAGE === "development"

const development = {
    ...api_dev
}

const production = {
    ...api_prod
}

const config_constant = isDevelopment ? development : production

export const config = { api, ...config_constant, constant, common, messages, route }