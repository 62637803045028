import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useHistory, withRouter } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import ProtectedRoute from './components/ProtectedRoute';
import { config } from './config';
import InvalidPlatform from './pages/InvalidPlatform';
import MemberAccess from './pages/MemberAccess';
import MemberRegister from './pages/MemberRegister';
import VerifyDealer from './pages/MemberRegister/VerifyDealer';
import PDPA from './pages/PDPA';
import Personal from './pages/Personal';
import Car from './pages/Personal/Car';
import PersonalConfirm from './pages/Personal/Confirm';
import QA from './pages/QA';
import RegisterWarranty from './pages/RegisterWarranty';
import WarrantyConfirm from './pages/RegisterWarranty/Confirm';
import ResetPassword from './pages/ResetPassword';
import ResetPasswordSuccess from './pages/ResetPassword/ResetPasswordSuccess';
import Settings from './pages/Settings';
import Thanks from './pages/Thanks';
import VerifyForgot from './pages/VerifyForgot';
import Warranty from './pages/Warranty';
import { clearCarForm } from './store/reducers/car/actions';
import { clearPersonalForm } from './store/reducers/personal/actions';
import { clearWarrantyForm } from './store/reducers/warranty/actions';
import { isIOS } from 'react-device-detect';


const AppRoute = () => {
    const history = useHistory()
    const UA = navigator.userAgent || navigator.vender || window.opera;
    const lastLocation = useLastLocation();
    const dispatcher = useDispatch()

    const handleBack = useCallback((e) => {
        const { action, location } = history
        const { pathname: currentPath } = location
        const { pathname: prevPath } = lastLocation || { pathname: null }
        switch (action) {
            case 'POP':
                if (currentPath.includes('register')) {
                    const fromRegistConfirmToRegist = prevPath === config.route.registerWarrantyConfirm && currentPath === config.route.registerWarranty
                    const fromRegistToRegistConfirm = prevPath === config.route.registerWarranty && currentPath === config.route.registerWarrantyConfirm
                    if (fromRegistConfirmToRegist) {
                        history.replace(config.route.registerWarranty)
                        break;
                    }
                    if (fromRegistToRegistConfirm) {
                        history.replace(config.route.registerWarrantyConfirm)
                        break;
                    }

                    const fromRegistCarToRegistCustomer = prevPath === config.route.registerCar && currentPath === config.route.registerCustomer
                    const fromRegistCustomerToRegistCar = prevPath === config.route.registerCustomer && currentPath === config.route.registerCar
                    if (fromRegistCarToRegistCustomer) {
                        history.replace(config.route.registerCustomer)
                        break;
                    }
                    if (fromRegistCustomerToRegistCar) {
                        history.replace(config.route.registerCar)
                        break;
                    }

                    const fromRegistCustomerConfirmToRegistCar = prevPath === config.route.registerCustomerConfirm && currentPath === config.route.registerCar
                    const fromRegistCarToRegistCustomerConfirm = prevPath === config.route.registerCar && currentPath === config.route.registerCustomerConfirm
                    if (fromRegistCustomerConfirmToRegistCar) {
                        history.replace(config.route.registerCar)
                        break;
                    }
                    if (fromRegistCarToRegistCustomerConfirm) {
                        history.replace(config.route.registerCustomerConfirm)
                        break;
                    }

                    const fromVerifyDealerToFirstPage = prevPath === config.route.registerVerifyDealer && currentPath === config.route.register

                    if (fromVerifyDealerToFirstPage) {
                        history.replace(config.route.register)
                        break;
                    }

                    const fromVerifyDealerToRegisterWarranty = prevPath === config.route.registerVerifyDealer && currentPath === config.route.registerWarranty
                    if (fromVerifyDealerToRegisterWarranty) {
                        history.go(-1)
                        break;
                    }
                    if (currentPath !== config.route.register && currentPath !== config.route.qa && currentPath !== config.route.registerVerifyDealer) {
                        dispatcher(clearWarrantyForm())
                        dispatcher(clearPersonalForm())
                        dispatcher(clearCarForm())
                        const direction = isIOS ? -(history.length) : -(history.length - 2)
                        history.go(direction)
                        history.replace(config.route.register)
                        break;
                    }
                }
                break;
            default:
                break;
        }
    }, [history, lastLocation, dispatcher])

    useEffect(() => {
        window.onpopstate = handleBack
    }, [handleBack])

    return (
        <Switch>
            <Route exact path={config.route.register} component={MemberRegister} />
            <Route path={config.route.qa} component={QA} />
            <ProtectedRoute path={config.route.registerVerifyDealer} component={VerifyDealer} />
            <ProtectedRoute exact path={config.route.registerWarranty} component={RegisterWarranty} />
            <ProtectedRoute path={config.route.registerWarrantyConfirm} component={WarrantyConfirm} />
            <ProtectedRoute exact path={config.route.registerCustomer} component={Personal} />
            <ProtectedRoute path={config.route.registerCar} component={Car} />
            <ProtectedRoute path={config.route.registerCustomerConfirm} component={PersonalConfirm} />
            <ProtectedRoute path={config.route.pdpa} component={PDPA} />
            <ProtectedRoute path={config.route.thanks} component={Thanks} />
            <Route exact path={config.route.member} component={MemberAccess} />
            <ProtectedRoute path={config.route.memberWarranty} component={Warranty} />
            <ProtectedRoute path={config.route.memberSettings} component={Settings} />
            <ProtectedRoute path={config.route.memberPersonal} component={Personal} />
            <ProtectedRoute exact path={config.route.memberVerify} component={MemberAccess} />
            <ProtectedRoute path={config.route.memberVerifyForgot} component={VerifyForgot} />
            <ProtectedRoute exact path={config.route.memberResetPassword} component={ResetPassword} />
            <ProtectedRoute path={config.route.memberResetPasswordSuccess} component={ResetPasswordSuccess} />
            <Route path={config.route.error} component={InvalidPlatform} />
            <Redirect path="*" to={config.route.error} />
            {
                !config.common.isMobile(UA) && <Redirect to={config.route.error} />
            }
        </Switch>
    )
}

export default withRouter(AppRoute)