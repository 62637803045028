import { combineReducers } from "redux"
import { MileageReducer } from "./mileage"
import { ReasonReducer } from "./reason"
import { TitleReducer } from "./title"

const OtherReducer = combineReducers({
    mileage: MileageReducer,
    reason: ReasonReducer,
    title: TitleReducer,
})

export {
    OtherReducer
}