import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import styled from 'styled-components';
import SubBackground from "../../assets/background/sub_background.png";
import PasswordLogo from '../../assets/logo/password_logo.png';
import Header from '../../components/Header';
import Numpad from '../../components/Numpad';
import PinCode from '../../components/PinCode';
import ValidateError from '../../components/ValidateError';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../components/Wrapped';
import { useDispatch } from 'react-redux';
import { changePassword } from '../../store/reducers/personal/actions';
import ErrorMessage from '../../components/ErrorMessage';
import { useHistory } from 'react-router';
import { config } from '../../config';
import { useLastLocation } from "react-router-last-location";
const pinLength = 6;
const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        minHeight: '100vh',
        '& .body': {
            // minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '100%',
            '& .content-container': {
                padding: 15,
                marginTop: '40px',
                '& .new-password-container': {
                    transition: 'all 150ms'
                },
                '& .header': {
                    fontWeight: 300,
                    color: theme.palette.text.primary,
                    lineHeight: 1
                },
                '& .hint': {
                    marginTop: 10,
                    fontWeight: 300,
                    color: theme.palette.text.secondary,
                    lineHeight: 1.2,
                },
            },
        },
        '& .footer': {
            marginTop: 'auto',
            marginBottom: 15,
            alignSelf: 'center',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h3.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
        '& .remark-container': {
            background: theme.palette.background.default,
            '& .remark-content': {
                color: theme.palette.text.primary,
            }
        },
        '& .logo-container': {
            '& img': {
                width: '30px',
                height: '35px'
            }
        }
    },
}))

const ContainerWrapped = styled(Container)`
    align-items: unset !important;
    justify-content: unset !important;
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
`
const ResetPassword = () => {
    const history = useHistory()
    const lastLocation = useLastLocation()
    const label = { ...I18n.t("change_password"), button: I18n.t("button") }
    const baseClasses = baseStyles()
    const classes = useStyles()
    const dispatcher = useDispatch()
    const [pinCode, setPinCode] = useState([])
    const [confirmPinCode, setConfirmPinCode] = useState([])
    const [isFocused, setIsFocused] = useState({
        pincode: false,
        confirm: false
    })
    const [keyboardAvoid, setKeyboardAvoid] = useState(false)
    const [touched, setTouched] = useState(false)
    const [error, setError] = useState({ required: true, incorrect: true })
    const useHeader = history.location.state?.header
    const resetMode = history.location.state?.reset_mode
    const verify = history.location.state?.verify
    const firstTime = history.location.state?.firstTime

    useEffect(() => {
        setError(e => ({ ...e, required: pinCode.length !== pinLength }))
    }, [pinCode, setError])

    useEffect(() => {
        if (pinCode.length === pinLength) {
            setKeyboardAvoid(false)
        }
    }, [pinCode, setKeyboardAvoid])

    useEffect(() => {
        if (pinCode.length === pinLength && confirmPinCode.length === pinLength) {
            setIsFocused({
                pincode: false,
                confirm: false
            })
            setKeyboardAvoid(false)
            if (pinCode.join("") !== confirmPinCode.join("")) {
                setTouched(true)
                setError(e => ({ ...e, incorrect: true }))
                setConfirmPinCode([])
            } else {
                setTouched(true)
                setError(e => ({ ...e, incorrect: false }))
            }
        }
    }, [pinCode, confirmPinCode, setIsFocused, setError])

    const onCancel = () => {
        history.goBack()
    }

    const onConfirm = () => {
        setTouched(true)
        if (error.required || error.incorrect) {
            return
        }
        const params = {
            password: pinCode.join(""),
            confirmPassword: confirmPinCode.join("")
        }
        dispatcher(changePassword(params, () => {
            if (firstTime) {
                history.replace(config.route.memberWarranty)
            } else {
                if (resetMode) {
                    sessionStorage.setItem('resetMode', true)
                }
                if (verify) {
                    sessionStorage.setItem('verifyResetMode', true)
                }
                history.goBack()
            }
        }, () => {
            setPinCode([])
            setConfirmPinCode([])
            setTouched(false)
        }))
    }

    if (verify && lastLocation.pathname !== config.route.memberVerify) {
        history.goBack()
    }

    if (resetMode && lastLocation.pathname !== config.route.memberVerifyForgot) {
        history.goBack()
    }


    return (
        <ContainerWrapped className={[baseClasses.root, classes.root]}>
            <ErrorMessage root="/member" />
            {useHeader &&
                <Header title={resetMode ? label.reset_password.header : label.header} logo={PasswordLogo} />
            }
            <Body>
                <div className='content-container'>
                    <div className="new-password-container" style={{ marginTop: isFocused.confirm ? useHeader ? '-150px' : '-100px' : null }}>
                        <Typography variant="h3" className="header">
                            {label.new_password}
                        </Typography>
                        <Typography variant="subtitle1" className="hint">
                            {label.new_password_hint}
                        </Typography>
                        <div>
                            <PinCode
                                pinCode={pinCode}
                                length={pinLength}
                                onClick={() => {
                                    setIsFocused({
                                        pincode: true,
                                        confirm: false
                                    })
                                    setKeyboardAvoid(!keyboardAvoid)
                                }}
                            />
                        </div>
                    </div>
                    <div className="confirm-password-container">
                        <Typography variant="h3" className="header">
                            {label.confirm_password}
                        </Typography>
                        <Typography variant="subtitle1" className="hint">
                            {label.confirm_hint}
                        </Typography>
                        <div>
                            <PinCode
                                pinCode={confirmPinCode}
                                length={pinLength}
                                onClick={() => {
                                    setIsFocused({
                                        pincode: false,
                                        confirm: true
                                    })
                                    setKeyboardAvoid(!keyboardAvoid)
                                }}
                            />
                        </div>
                    </div>
                    <ValidateError
                        error={error.required && touched}
                        message={I18n.t("validate_message.please_input", { label: label.required })}
                    />
                    <ValidateError
                        error={!error.required && error.incorrect && touched}
                        message={I18n.t("validate_message.please_input", { label: label.incorrect })}
                    />
                    <Numpad
                        active={keyboardAvoid}
                        setActive={(value) => {
                            setKeyboardAvoid(value)
                            setIsFocused({
                                pincode: false,
                                confirm: false
                            })
                        }}
                        isAbsolute
                        pinCode={isFocused.pincode ? pinCode : confirmPinCode}
                        setPinCode={isFocused.pincode ?
                            (value) => {
                                setPinCode(value)
                                if (confirmPinCode.length > 0) {
                                    setConfirmPinCode([])
                                }
                            } : (value) => {
                                setConfirmPinCode(value)
                            }}
                        length={pinLength}
                    />
                </div>
            </Body>
            <Footer style={{ width: '90%' }}>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        verify || resetMode ?
                            <React.Fragment>
                                <Col xs={5} >
                                    <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={onCancel}>
                                        {label.button.cancel}
                                    </ButtonWrapped>
                                </Col>
                                <Col xs={7}>
                                    <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                        {label.button.submit}
                                    </ButtonWrapped>
                                </Col>
                            </React.Fragment>
                            :
                            <Col xs={12} style={{ paddingLeft: 'unset', paddingRight: 'unset' }}>
                                <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                    {label.button.submit}
                                </ButtonWrapped>
                            </Col>
                    }
                </Row>
            </Footer>
        </ContainerWrapped>
    )
}
export default ResetPassword