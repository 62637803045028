import { AddressReducer } from "./address";
import { combineReducers } from "redux";
import { TireReducer } from "./tire";
import { CarReducer } from "./car";
import { OtherReducer } from "./other";
import { FileReducer } from "./file";

const CommonReducer = combineReducers({
    address: AddressReducer,
    tire: TireReducer,
    car: CarReducer,
    other: OtherReducer,
    file: FileReducer,
})

export {
    CommonReducer
}