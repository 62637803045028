import { i18nReducer } from "react-redux-i18n";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/authorization";
import { CarReducer } from "./reducers/car";
import { CommonReducer } from "./reducers/common";
import { DealerReducer } from "./reducers/dealer";
import { DialogReducer } from "./reducers/dialog";
import { ErrorReducer } from "./reducers/error";
import { LoaderReducer } from "./reducers/loader";
import { PersonalReducer } from "./reducers/personal";
import { WarrantyReducer } from "./reducers/warranty";
import { RouteReducer } from "./reducers/route";

const CombineReducers = combineReducers({
    authorization: AuthReducer,
    common: CommonReducer,
    loader: LoaderReducer,
    warranty: WarrantyReducer,
    dealer: DealerReducer,
    personal: PersonalReducer,
    car: CarReducer,
    error: ErrorReducer,
    i18n: i18nReducer,
    dialog: DialogReducer,
    route: RouteReducer,
})

const store = createStore(CombineReducers, {}, applyMiddleware(thunk))
export default store