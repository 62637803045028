import { config } from "../../../../../config";
import http from "../../../../../http";
import { hasErrors } from "../../../error/actions";
import { UPLOAD_IMAGE_BEGIN, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE } from "./type";


export const uploadFile = (file, callback) => {
    return async dispatch => {
        try {
            dispatch(begin())
            const formData = new FormData()
            formData.append('image', file)
            const result = await http.post(config.api.COMMON.UPLOAD.UPLOAD_IMAGES, formData)
            if (callback) {
                callback(result)
            }
            dispatch(success(result))
        } catch (error) {
            dispatch(failure(error))
        }
    }
}

const begin = () => {
    return dispatch => {
        dispatch({ type: UPLOAD_IMAGE_BEGIN })
    }
}
const success = (result) => {
    return dispatch => {
        dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: { url: result, loading: false } })
    }
}

const failure = (error) => {
    return dispatch => {
        dispatch(hasErrors(error))
        dispatch({ type: UPLOAD_IMAGE_FAILURE })
    }
}