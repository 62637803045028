import qs from 'querystring';
import { config } from "../../../../../config";
import http from "../../../../../http";
import { hasErrors } from "../../../error/actions";
import { GET_CAR_MODEL_BEGIN, GET_CAR_MODEL_SUCCESS } from "./type";

export const getCarModel = (carBrandId, keyword) => {
    return async dispatch => {
        try {
            dispatch(getBegin())
            const result = await http.get(config.api.COMMON.CAR.GET_CAR_MODELS, {
                params: { carBrandId, keyword },
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                },
            })
            dispatch(getSuccess(result))
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

const getBegin = () => {
    return dispatch => {
        dispatch({ type: GET_CAR_MODEL_BEGIN })
    }
}
const getSuccess = (items) => {
    return dispatch => {
        dispatch({ type: GET_CAR_MODEL_SUCCESS, payload: { items, loading: false } })
    }
}