import React from 'react';
import { I18n } from 'react-redux-i18n';
import CarForm from '../../../components/CarForm';
import CircleProgress from '../../../components/CircleProgress';
import ErrorMessage from '../../../components/ErrorMessage';
import Header from '../../../components/Header';
import withPersonal from '../../../components/HoC/withPersonal';
import { Body, Container } from '../../../components/Wrapped';
import { config } from '../../../config';
import commonFunction from '../../../config/common.function';

const Car = ({ car, label, classes, isExisting, steps }) => {

    return (
        <Container className={classes.root}>
            <ErrorMessage root={config.route.register} />
            <Body>
                <Header title={isExisting ? label.car.header_1 : label.car.header_2} remark={I18n.t("for_customer")}
                    element={<CircleProgress step={isExisting ? 1 : 2} total={steps} />} titleStyle={{ fontSize: commonFunction.fontSizeHeader() }} />
                <CarForm label={label} formData={car} isExisting={isExisting} />
            </Body>
        </Container>
    )
}

export default withPersonal(Car)