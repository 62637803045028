import { VERIFY_DEALER_BEGIN, VERIFY_DEALER_SUCCESS, SIGN_IN_DEALER_SUCCESS } from "./type";

const initialState = {
    loading: false,
    data: '',
    isAuth: false,
}
export const DealerReducer = (state = initialState, action) => {
    switch (action.type) {
        case VERIFY_DEALER_BEGIN:
            return {
                ...state,
                loading: true
            }
        case VERIFY_DEALER_SUCCESS:
            return {
                data: action.payload,
                loading: false
            }
        case SIGN_IN_DEALER_SUCCESS:
            return {
                ...state,
                isAuth: true
            }
        default:
            return state
    }
};
