import React from 'react';
import { FaCheck } from "react-icons/fa";
import styled from 'styled-components';
import PropTypes from 'prop-types'
import { MAIN_COLOR_INPUT, MAIN_COLOR, ERROR_COLOR } from '../../App.theme';

const CheckBoxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;
`

const IconCheck = styled(FaCheck)`
    width: 40%;
    height: 40%;
    color: black;
`

const InputCheckBox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const StyledCheckBox = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    width: 50px;
    height: 50px;
    background: ${props => props.checked ? MAIN_COLOR : MAIN_COLOR_INPUT};
    opacity: ${props => (props.disabled ? 0.25 : props.checked ? 1 : 0.5)};
    border-radius: 50%;
    ${props => props.checkBoxStyle}
    ${props => props.error && !props.checked && `
        background: transparent !important;
        opacity: 1 !important;
        border: 1.4px solid ${ERROR_COLOR} !important;
    `}

    ${IconCheck}{
        opacity: ${props => (props.disabled ? 0 : props.checked ? 1 : 0)};
    }
`
const CheckBox = ({ checked, checkBoxStyle, disabled, error, ...props }) => {
    return (
        <CheckBoxContainer>
            <InputCheckBox id={props.id} checked={checked} disabled={disabled && !checked} {...props} />
            <StyledCheckBox htmlFor={props.id} checked={checked} checkBoxStyle={checkBoxStyle} disabled={disabled && !checked} error={error} {...props}>
                <IconCheck />
            </StyledCheckBox>
        </CheckBoxContainer>
    )
}

CheckBox.propTypes = {
    checked: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
}

export default CheckBox