import { WARRANTY_FORM_SUBMIT, WARRANTY_BEGIN, WARRANTY_SUCCESS, CLEAR_WARRANTY_FORM } from "./type"
import { hasErrors } from "../error/actions"
import http from "../../../http"
import { config } from "../../../config"

export const warrantySubmit = (data, callback) => {
    return async dispatch => {
        await dispatch({ type: WARRANTY_FORM_SUBMIT, payload: data })
        await callback && callback()
    }
}

export const createWarranty = (data, callback) => {
    return async dispatch => {
        try {
            await dispatch(begin())
            const result = await http.post(config.api.CUSTOMER.REGISTER_WARRANTY.REGISTER, data)
            if (result?.token) {
                localStorage.setItem('token', result.token)
            }
            sessionStorage.setItem('created', true)
            await dispatch(success(null, true))
            callback && callback()
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const getWarranty = (callback) => {
    return async dispatch => {
        try {
            await dispatch(begin())
            const result = await http.get(config.api.CUSTOMER.MEMBER.GET_WARRANTIES)
            await dispatch(success(result))
            callback && callback(result)
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const clearWarrantyForm = () => {
    return async dispatch => {
        await dispatch({ type: CLEAR_WARRANTY_FORM })
    }
}

const begin = () => {
    return async dispatch => {
        await dispatch({ type: WARRANTY_BEGIN })
    }
}
const success = (result, created) => {
    return async dispatch => {
        await dispatch({ type: WARRANTY_SUCCESS, payload: { result, created } })
    }
}