import { Link, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import SubBackground from "../../assets/background/sub_background.png";
import PasswordLogo from '../../assets/logo/password_logo.png';
import ErrorMessage from '../../components/ErrorMessage';
import Header from '../../components/Header';
import withResolver from '../../components/HoC/withResolver';
import Numpad from '../../components/Numpad';
import PinCode from '../../components/PinCode';
import ValidateError from '../../components/ValidateError';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../components/Wrapped';
import { config } from '../../config';
import { MESSAGE_ENUM } from '../../config/config.message';
import { signin, verifySignin } from '../../store/reducers/authorization/actions';
import { hasErrors } from '../../store/reducers/error/actions';
const pinLength = 6;
const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        '& .body': {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '100%',
            '& .content-container': {
                padding: 15,
                marginTop: '40px',
                '& .header': {
                    fontWeight: 300,
                    color: theme.palette.text.primary,
                    lineHeight: 1
                },
                '& .hint': {
                    marginTop: 10,
                    fontWeight: 300,
                    color: theme.palette.text.hint,
                    lineHeight: 1.2,
                },
                '& .forgot-container': {
                    width: 100,
                    '& .forgot-wrapper': {
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        '& .forgot': {
                            cursor: 'pointer',
                            marginTop: 'unset',
                            borderBottom: `1px solid ${theme.palette.text.hint}`
                        },
                        '& span': {
                            fontSize: '0.8em',
                            color: theme.palette.text.hint,
                            marginBottom: 2,
                            marginLeft: 5,
                        },
                    },
                },
            },
        },
        '& .footer': {
            marginTop: 'auto',
            marginBottom: 15,
            alignSelf: 'center',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
        '& .remark-container': {
            background: theme.palette.background.default,
            '& .remark-content': {
                color: theme.palette.text.primary,
            }
        },
        '& .logo-container': {
            '& img': {
                width: '30px',
                height: '35px'
            }
        }
    },
}))

const ContainerWrapped = styled(Container)`
    align-items: unset !important;
    justify-content: unset !important;
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
`
const MemberAccess = ({ verifyPassword }) => {
    const history = useHistory()
    const auth = useSelector(state => state.authorization)
    const error = useSelector(state => state.error)
    const label = { ...(auth.firstTime ? I18n.t("member_access.first_time") : I18n.t("member_access")), button: I18n.t("button") }
    const baseClasses = baseStyles()
    const classes = useStyles()
    const dispatch = useDispatch()
    const [pinCode, setPinCode] = useState([])
    const [keyboardAvoid, setKeyboardAvoid] = useState(false)
    const [touched, setTouched] = useState(false)
    const [pinError, setPinError] = useState(true)
    const resetMode = sessionStorage.getItem('resetMode')



    const onCancel = () => {
        if (verifyPassword) {
            history.goBack()
        }
    }

    const onConfirm = () => {
        setTouched(true)
        if (pinCode.length < pinLength) {
            setPinError(true)
        } else {
            setPinError(false)
            const password = pinCode.join("")
            if (verifyPassword) {
                dispatch(verifySignin(password, (status) => {
                    if (status) {
                        history.replace({
                            pathname: config.route.memberResetPassword,
                            state: {
                                header: true,
                                verify: true
                            }
                        })
                    } else {
                        dispatch(hasErrors({ message: config.messages.getMesssage(MESSAGE_ENUM.PASSWARD_WRONG) }))
                    }
                }))
            } else {
                dispatch(signin(password, () => {
                    if (auth.firstTime) {
                        history.replace(config.route.memberResetPassword, {
                            firstTime: true
                        })
                    } else {
                        history.replace(config.route.memberWarranty)
                    }
                }))
            }
        }
    }

    useEffect(() => {
        if (pinCode.length === pinLength) {
            setKeyboardAvoid(false)
            setPinError(false)
        }
    }, [pinCode, setKeyboardAvoid])

    useEffect(() => {
        if (error?.status) {
            setPinCode([])
            setPinError(false)
        }
    }, [error])

    if (resetMode) {
        history.replace(config.route.memberResetPasswordSuccess)
    }

    return (
        <ContainerWrapped className={[baseClasses.root, classes.root]}>
            <ErrorMessage />
            {verifyPassword &&
                <Header
                    title={I18n.t('change_password.header')}
                    logo={PasswordLogo} />
            }
            <Body>
                <div className='content-container'>
                    <div >
                        <Typography variant="h3" className="header">
                            {verifyPassword ? I18n.t('change_password.password') : label.header}
                        </Typography>
                        <Typography variant="subtitle1" className="hint">
                            {verifyPassword ? I18n.t('change_password.password_hint') : label.hint}
                        </Typography>
                        <div>
                            <PinCode
                                pinCode={pinCode}
                                length={pinLength}
                                onClick={() => {
                                    setKeyboardAvoid(!keyboardAvoid)
                                }}
                            />
                            <div style={{ marginBottom: pinError && touched ? 30 : 0 }}>
                                <ValidateError
                                    error={pinError && touched}
                                    message={I18n.t("validate_message.please_input", { label: label.password_validate })}
                                />
                            </div>
                        </div>
                        {
                            (!verifyPassword && !auth.firstTime) &&
                            <div className="forgot-container">
                                <Link className="forgot-wrapper" onClick={() => history.push(config.route.memberVerifyForgot)}>
                                    <Typography variant="subtitle1" className="hint forgot">
                                        {label.forgot_password}
                                    </Typography>
                                    <span>{">>"}</span>
                                </Link>
                            </div>
                        }
                    </div>
                    <Numpad
                        active={keyboardAvoid}
                        setActive={(value) => {
                            setKeyboardAvoid(value)
                        }}
                        isAbsolute
                        pinCode={pinCode}
                        setPinCode={setPinCode}
                        length={pinLength}
                    />
                </div>
            </Body>
            <Footer style={{ width: '90%' }}>
                <Row style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        verifyPassword ?
                            <React.Fragment>
                                <Col xs={5} >
                                    <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={onCancel}>
                                        {label.button.cancel}
                                    </ButtonWrapped>
                                </Col>
                                <Col xs={7}>
                                    <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                        {label.button.submit}
                                    </ButtonWrapped>
                                </Col>
                            </React.Fragment>
                            :
                            <Col xs={12} style={{ paddingLeft: 'unset', paddingRight: 'unset' }}>
                                <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                    {label.button.submit}
                                </ButtonWrapped>
                            </Col>
                    }
                </Row>
            </Footer>
        </ContainerWrapped>
    )
}
export default withResolver(MemberAccess)