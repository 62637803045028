import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import { ReactSVG } from 'react-svg';
import styled from "styled-components";
import TireCarVector from "../../assets/tire_car_vector.svg";
import CheckBox from "./CheckBox";
import TirePositionModel, { POSITION_OF_TIRE_ENUM } from "./TirePositionModel";


const CHECK_BOX_STYLE = {
    width: '60px',
    height: '60px'
}

const CarContainer = styled.div`
    max-width: 120px;
    max-height: 223px;
    width: 100%;
    height: 100%;
`

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',

        '& .title': {
            fontSize: theme.typography.h2
        },
        '& .car-container': {
            marginTop: 30,
            marginBottom: 30,
        },
    },
}));

const positionTireList = [
    { positionOfTire: POSITION_OF_TIRE_ENUM.FRONT_LEFT.value, seq: POSITION_OF_TIRE_ENUM.FRONT_LEFT.seq },
    { positionOfTire: POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value, seq: POSITION_OF_TIRE_ENUM.FRONT_RIGHT.seq },
    { positionOfTire: POSITION_OF_TIRE_ENUM.BACK_LEFT.value, seq: POSITION_OF_TIRE_ENUM.BACK_LEFT.seq },
    { positionOfTire: POSITION_OF_TIRE_ENUM.BACK_RIGHT.value, seq: POSITION_OF_TIRE_ENUM.BACK_RIGHT.seq },
]

const TireCheckbox = ({ data, title, onChange, quantity, error }) => {
    const [values, setValues] = useState(data)
    const [disabled, setDisabled] = useState(true)
    const classes = useStyles()

    const getCountOfSelectedData = (val) => {
        const count = Object.keys(val).reduce((p, c) => val[c] ? ++p : p, 0)
        return count
    }

    const checkDisabled = useCallback((val) => {
        const count = getCountOfSelectedData(val)
        setDisabled(count >= quantity)
        if (count > quantity) {
            setValues([])
            onChange([])
        }
    }, [quantity, onChange])

    const onPositionValueChange = useCallback((event, seq) => {
        if (quantity === 4) return;
        const { target: { id } } = event
        const found = values.find(value => value.positionOfTire === id)
        let newValues = Object.assign([], values)
        if (!found) {
            newValues.push(new TirePositionModel(id, seq))
        } else {
            newValues = newValues.filter(value => value.positionOfTire !== id)
        }
        setValues(newValues)
        onChange(newValues)
        checkDisabled(newValues)
    }, [values, onChange, checkDisabled, quantity])

    useEffect(() => {
        if (quantity === 4 && values.length !== 4) {
            let newValues = []
            if (values.length) {
                positionTireList.forEach(({ positionOfTire, seq }) => {
                    const found = values.find(value => value.positionOfTire === positionOfTire)
                    if (!found) {
                        newValues.push(new TirePositionModel(positionOfTire, seq))
                    } else {
                        newValues.push(found)
                    }
                })
            } else {
                newValues = [
                    new TirePositionModel(POSITION_OF_TIRE_ENUM.FRONT_LEFT.value, POSITION_OF_TIRE_ENUM.FRONT_LEFT.seq),
                    new TirePositionModel(POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value, POSITION_OF_TIRE_ENUM.FRONT_RIGHT.seq),
                    new TirePositionModel(POSITION_OF_TIRE_ENUM.BACK_LEFT.value, POSITION_OF_TIRE_ENUM.BACK_LEFT.seq),
                    new TirePositionModel(POSITION_OF_TIRE_ENUM.BACK_RIGHT.value, POSITION_OF_TIRE_ENUM.BACK_RIGHT.seq),
                ]
            }
            setValues(newValues)
            onChange(newValues)
        }
    }, [quantity, onChange, setValues, values])

    useEffect(checkDisabled.bind(this, values), [checkDisabled, values])

    return (
        <div className={classes.root}>
            <Typography className="title" color="textPrimary">
                {title}
            </Typography>
            <div className="car-container flex-container flex-justify-center pos-relative" style={{ width: '100%' }}>
                <div className="flex-container flex-column flex-justify-space-between" style={{ marginRight: 20 }}>
                    <CheckBox id={POSITION_OF_TIRE_ENUM.FRONT_LEFT.value}
                        checked={values && values.find(val => val.positionOfTire === POSITION_OF_TIRE_ENUM.FRONT_LEFT.value) ? true : false}
                        onChange={(e) => onPositionValueChange(e, POSITION_OF_TIRE_ENUM.FRONT_LEFT.seq)}
                        checkBoxStyle={{ ...CHECK_BOX_STYLE, marginTop: '10px' }}
                        disabled={disabled}
                        error={error} />
                    <CheckBox id={POSITION_OF_TIRE_ENUM.BACK_LEFT.value}
                        checked={values && values.find(val => val.positionOfTire === POSITION_OF_TIRE_ENUM.BACK_LEFT.value) ? true : false}
                        onChange={(e) => onPositionValueChange(e, POSITION_OF_TIRE_ENUM.BACK_LEFT.seq)}
                        checkBoxStyle={{ ...CHECK_BOX_STYLE, marginBottom: '10px' }}
                        disabled={disabled}
                        error={error} />
                </div>
                <CarContainer>
                    <ReactSVG src={TireCarVector}
                        beforeInjection={svg => {
                            svg.setAttribute('style', 'width: 100%; max-width: inherit; height: 100%; max-height: inherit')
                        }} />
                </CarContainer>
                <div className="flex-container flex-column flex-justify-space-between" style={{ marginLeft: 20 }}>
                    <CheckBox id={POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value}
                        checked={values && values.find(val => val.positionOfTire === POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value) ? true : false}
                        onChange={(e) => onPositionValueChange(e, POSITION_OF_TIRE_ENUM.FRONT_RIGHT.seq)}
                        checkBoxStyle={{ ...CHECK_BOX_STYLE, marginTop: '10px' }}
                        disabled={disabled}
                        error={error} />
                    <CheckBox id={POSITION_OF_TIRE_ENUM.BACK_RIGHT.value}
                        checked={values && values.find(val => val.positionOfTire === POSITION_OF_TIRE_ENUM.BACK_RIGHT.value) ? true : false}
                        onChange={(e) => onPositionValueChange(e, POSITION_OF_TIRE_ENUM.BACK_RIGHT.seq)}
                        checkBoxStyle={{ ...CHECK_BOX_STYLE, marginBottom: '10px' }}
                        disabled={disabled}
                        error={error} />
                </div>
            </div>
        </div>
    )
}

TireCheckbox.propTypes = {
    data: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    quantity: (props, propName) => {
        if (isNaN(Number(props[propName]))) {
            return new Error("Quantity require input type number only.")
        } else if (props[propName] < 0 || props[propName] > 4) {
            return new Error("Quantity can input number from 0 to 4.")
        }
    },
}

export default TireCheckbox