import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import { LAYOUT_SPACING } from "../../App.config";
import Separator from "../Separator";
import { Col, Row } from "../Wrapped";
import renderHTML from "react-render-html";

const SPAN_SIZE = 12

const Container = withStyles((theme) => (
    {
        root: {
            marginTop: theme.spacing(LAYOUT_SPACING),
            marginBottom: theme.spacing(LAYOUT_SPACING),
            display: 'flex',
            alignItems: 'center',
            width: '90%',
            '& .MuiGrid-container': {
                margin: 'unset'
            },
            '& .MuiGrid-item:first-of-type': {
                paddingLeft: 'unset'
            },
            '& .MuiGrid-item:last-of-type': {
                paddingRight: 'unset'
            },
            '& .container': {
                width: "100%",
                border: 'double 1px transparent',
                borderRadius: theme.shape.borderRadius,
                backgroundImage: `linear-gradient(${theme.palette.card.main}, ${theme.palette.card.main}), linear-gradient(#bdbdbd, #333333)`,
                backgroundOrigin: 'border-box',
                backgroundClip: 'content-box, border-box',
                display: 'flex',
                flexDirection: 'column',
                '& .container-wrapper': {
                    padding: 15,

                    '& .header_title': {
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightBold
                    },
                    '& .content-container': {
                        '& .title': {
                            marginTop: 25,
                            color: theme.palette.text.primary,
                            fontWeight: theme.typography.fontWeightBold
                        },
                        '& .content-container-wrapper': {
                            marginTop: 5,
                            '& .content-wrapper': {
                                display: 'flex',
                                '& .label-content': {
                                    color: theme.palette.text.primary,
                                    lineHeight: 1.5
                                },
                                '& .content': {
                                    color: theme.palette.text.secondary,
                                    lineHeight: 1.5,
                                    textAlign: 'left',
                                    wordWrap: 'break-word'
                                },
                            },
                        }
                    },
                    '& .content-container-non-title': {
                        marginTop: 25
                    }
                },
                // '&::-webkit-scrollbar-track': {
                //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                //     borderRadius: '10px',
                //     background: '#F5F5F5',
                // },
                // '&::-webkit-scrollbar': {
                //     width: '6px',
                //     background: '#F5F5F5',
                // },
                // '&::-webkit-scrollbar-thumb': {
                //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
                //     borderRadius: '10px',
                //     background: '#000000',
                // },
            },
        }
    }
))(({ classes, cardDetail, containerStyle, contentContainerStyle, isHTML }) => {
    const { data, header_title } = cardDetail
    return (
        <div className={classes.root}>
            <div className="container" style={containerStyle}>
                <div className="container-wrapper" style={contentContainerStyle}>
                    {header_title && <Typography variant="h4" className="header_title">
                        {header_title}
                    </Typography>}
                    {data?.map((d, i) => {
                        const { title, contents, spanSize, separator } = d
                        return (
                            <div key={`content_${i}`} className="content-container">
                                {title && <Typography variant="h5" className={'title'}>
                                    {title}
                                </Typography>}
                                <div className='content-container-wrapper'>
                                    {contents?.map((content, i) => {
                                        const { label, value } = content
                                        const firstSpan = spanSize
                                        const colonSpan = 1
                                        const lastSpan = (SPAN_SIZE - spanSize) - colonSpan
                                        return (
                                            value?
                                            <Row key={`content_detail_${i}`} className="content-wrapper">
                                                {
                                                    label &&
                                                    <React.Fragment>
                                                        <Col xs={firstSpan}>
                                                            <Typography className="label-content" >
                                                                {label}
                                                            </Typography>
                                                        </Col>
                                                        <Col xs={colonSpan}>
                                                            <Typography className="label-content">
                                                                {':'}
                                                            </Typography>
                                                        </Col>
                                                    </React.Fragment>
                                                }
                                                {value &&
                                                    <Col xs={label ? lastSpan : SPAN_SIZE}>
                                                        <Typography className="content">
                                                            {isHTML ? renderHTML(value) : value}
                                                        </Typography>
                                                    </Col>
                                                }
                                            </Row>:null
                                        )
                                    })}
                                </div>
                                {separator && <Separator />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
});


const CardDetail = ({ cardDetail, containerStyle, contentContainerStyle, isHTML, ...props }) => {
    const properties = { cardDetail, containerStyle, contentContainerStyle, isHTML, ...props }
    return (
        <Container {...properties} />
    )
}

export default CardDetail