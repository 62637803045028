import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import renderHTML from 'react-render-html';

const skewDegree = 30
const widthCalC = 58
const leftValue = 29

const Container = withStyles((theme) => (
    {
        root: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: '100px',
            width: '100%',
            overflow: 'hidden',
            zIndex: 1,
            '& .remark-container': {
                position: "absolute",
                padding: "5px 45px 5px 25px",
                borderBottomLeftRadius: 10,
                right: -10,
                top: 0,
                background: theme.palette.header.remark,
                border: `2px solid ${theme.palette.header.main}`,
                borderTop: 'none',
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                transform: `skew(${skewDegree}deg)`,
                '& .remark-content': {
                    transform: `skew(-${skewDegree}deg)`
                }
            },
            '& .main-container': {
                position: "absolute",
                width: `calc(100% )`,
                height: "inherit",
                transform: `skew(${skewDegree}deg)`,
                background: theme.palette.header.main,
                borderBottomLeftRadius: 10,
                left: leftValue,
                '& .title-content': {
                    transform: `skew(-${skewDegree}deg)`,
                    position: "absolute",
                    right: '70px',
                    bottom: '20px',
                    textAlign: 'right',
                    '& .title': {
                        fontWeight: theme.typography.fontWeightBold + ' !important',
                    }
                },
            },
            '@media (min-width:600px)': {
                '& .main-container': {
                    width: `calc(100% - ${widthCalC}px)`,
                    '& .title-content': {
                        right: '35px !important',
                    }
                },
                '& .remark-container': {
                    right: 10
                }
            },
            '& .logo-container': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
                width: '50px',
                height: '50px',
                border: `2px solid ${theme.palette.header.main}`,
                background: "#000",
                borderRadius: '50%',
                marginLeft: '5px',
                padding: '10px',
                '& img': {
                    width: 'inherit',
                    height: 'inherit'
                }
            }
        }
    }
))(({ classes, title, remark, logo, element, titleStyle, logoStyle }) => (
    <div className={[classes.root]}>
        <div className="main-container">
            <div className="title-content" style={!remark ? titleStyle ? {...titleStyle, fontSize: 'unset'} : { bottom: '35px' } : {}}>
                <Typography className="title" variant="h3" style={titleStyle?.fontSize && { fontSize: titleStyle.fontSize }}>{renderHTML(title)}</Typography>
            </div>
        </div>
        {remark &&
            <div className="remark-container">
                <div className="remark-content">
                    <Typography>{remark}</Typography>
                </div>
            </div>
        }
        <div className="logo-container">
            {logo ?
                <img src={logo} alt={title} style={logoStyle} /> :
                element
            }
        </div>
    </div>
));


const Header = ({ title, titleStyle, remark, logo, logoStyle, element }) => {
    const props = { title, titleStyle, remark, logo, logoStyle, element }
    return (
        <Container {...props} />
    )
}

export default Header