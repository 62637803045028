import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect } from 'react-router';
import PeopleLogo from "../../assets/logo/people_logo.png";
import CircleProgress from '../../components/CircleProgress';
import ErrorMessage from '../../components/ErrorMessage';
import Header from '../../components/Header';
import withPersonal from '../../components/HoC/withPersonal';
import PersonalForm from '../../components/PersonalForm';
import Spinner from '../../components/Spinner';
import { Body, Container } from '../../components/Wrapped';
import { getProfiles } from '../../store/reducers/personal/actions';
import { config } from '../../config';

const useStyles = makeStyles(theme => ({
    root: {

    },
}))

const Personal = ({ personal, label, classes, titles, isEdit, isExisting, history, steps }) => {
    const personalClasses = useStyles()
    const loading = useSelector(state => state.personal.loading)
    const dispatcher = useDispatch()

    useEffect(() => {
        if (isEdit) {
            dispatcher(getProfiles())
        }
    }, [dispatcher, isEdit])

    if (!isEdit && isExisting) {
        return <Redirect to={config.route.registerCar} />
    }

    if (loading) {
        return (
            <React.Fragment>
                <ErrorMessage root={isEdit ? config.route.member : config.route.register} />
                <Spinner />
            </React.Fragment>
        )
    }
    return (
        <Container className={[classes.root, personalClasses.root]}>
            <ErrorMessage root={isEdit ? config.route.member : config.route.register} />
            <Body>
                {isEdit ?
                    <Header title={label.edit_personal} logo={PeopleLogo} logoStyle={{ width: 30, height: 30 }} /> :
                    <Header title={label.header} remark={I18n.t("for_customer")}
                        element={<CircleProgress step={1} total={steps} />} />
                }
                <PersonalForm label={label} titles={titles} formData={personal} isEdit={isEdit} history={history} />
            </Body>
        </Container>
    )
}

export default withPersonal(Personal)