import { config } from "../../../../../config";
import http from "../../../../../http";
import { GET_PROVINCES_BEGIN, GET_PROVINCES_SUCCESS, CLEAR_PROVINCE } from "./type";
import { hasErrors } from "../../../error/actions";

export const getProvinces = () => {
    return async dispatch => {
        try {
            dispatch(getBegin())
            const result = await http.get(config.api.COMMON.ADDRESS.GET_PROVINCES)
            dispatch(getSuccess(result))
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const clearProvince = () => {
    return async dispatch => {
        dispatch({ type: CLEAR_PROVINCE })
    }
}

const getBegin = () => {
    return dispatch => {
        dispatch({ type: GET_PROVINCES_BEGIN })
    }
}
const getSuccess = (items) => {
    return dispatch => {
        dispatch({ type: GET_PROVINCES_SUCCESS, payload: { items, loading: false } })
    }
}