import { makeStyles, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { config, isDevelopment } from '../../config';
import { clearErrors } from '../../store/reducers/error/actions';

const useStyles = makeStyles(theme => ({
    root: {
    }
}))

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorMessage = ({ root }) => {
    const history = useHistory();
    const classes = useStyles()
    const error = useSelector(state => state.error)
    const dispatcher = useDispatch()
    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatcher(clearErrors())
    };

    if (error?.status && error?.message === config.constant.ERRORS.UNAUTHORIZED) {
        dispatcher(clearErrors(() => {
            if (root === config.route.register) {
                window.location.href = config.api.THIRD_PARTY[isDevelopment ? "DEV" : "PRODUCTION"].REGISTER
            }
            if (root === config.route.member) {
                window.location.href = config.api.THIRD_PARTY[isDevelopment ? "DEV" : "PRODUCTION"].MEMBER
            }
        }))
    }

    history.listen(() => {
        if (error.status) {
            dispatcher(clearErrors())
        }
    })

    return (
        <Snackbar
            className={classes.root}
            open={error.status}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity="error">
                <Typography>
                    {`${error?.message}`}
                </Typography>
            </Alert>
        </Snackbar>
    )
}

export default ErrorMessage