import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ path, component: Component, ...props }) => {
    const isAuth = useSelector(state => state.authorization.isAuth)
    return (
        <Route path={path} {...props} render={(childProps) => {
            if (isAuth) {
                return (<Component {...childProps} />)
            } else {
                return (<Redirect to="error" />)
            }
        }} />
    )
}

export default ProtectedRoute