import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import renderHTML from 'react-render-html'

const DialogMessage = ({ open, content, title, onConfirm, onClose, cancelLabel, confirmLabel }) => {
    const buttonLabel = I18n.t('button')

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {renderHTML(content)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {cancelLabel ? cancelLabel : buttonLabel.cancel}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {confirmLabel ? confirmLabel : buttonLabel.submit}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogMessage