import { CLEAR_ERROR, HAS_ERROR } from "./type";
import ErrorModel from "../../../components/ErrorMessage/ErrorModel";

const initState = new ErrorModel()
export const ErrorReducer = (state = initState, action) => {
    switch (action.type) {
        case HAS_ERROR:
            return action.payload
        case CLEAR_ERROR:
            return {
                ...state,
                status: action.payload
            }
        default:
            return state
    }
};
