import { CAR_SUBMIT_FORM, CLEAR_CAR_FORM } from "./type";
import CarFormModel from "../../../components/CarForm/CarFormModel";

const initialState = {
    formData: new CarFormModel(),
    loading: false,
    hasFormData: false
}
export const CarReducer = (state = initialState, action) => {
    switch (action.type) {
        case CAR_SUBMIT_FORM:
            return {
                ...state,
                formData: action.payload,
                hasFormData: true
            }
        case CLEAR_CAR_FORM:
            return {
                ...state,
                formData: new CarFormModel(),
                hasFormData: false
            }
        default:
            return state
    }
};
