import React from 'react'
import SubBackground from "../../assets/background/sub_background.png"
import SubBackgroundDesktop from "../../assets/background/sub_background_desktop.png"
import { Container, Body, baseStyles } from '../../components/Wrapped'
import styled from 'styled-components'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        '& .body': {
            display: 'flex',
            '& .text': {
                color: theme.palette.text.primary,
                fontWeight: theme.typography.fontWeightBold
            }
        },
    },
}))

const ContainerWrapped = styled(Container)`
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media(min-width: 768px){
        background: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat; 
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`
const BodyWrapped = styled(Body)`

`

const InvalidPlatform = () => {
    const baseClasses = baseStyles()
    const classes = useStyles()
    return (
        <ContainerWrapped className={[baseClasses.root, baseClasses.container, classes.root]}>
            <BodyWrapped>
                <Typography className="text" variant="h4">
                    กรุณาเปิดด้วย Application LINE เท่านั้น
                </Typography>
            </BodyWrapped>
        </ContainerWrapped>
    )
}

export default InvalidPlatform