import { makeStyles } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { config } from "../../../config";
import Select from "../../Select";
import { Col, Row, TextFieldWrapped } from "../../Wrapped";
import { POSITION_OF_TIRE_ENUM } from "../TirePositionModel";
import ValidateError from "../../ValidateError";
import { I18n } from "react-redux-i18n";

const useStyles = makeStyles(theme => (
    {
        root: {
            width: '100%',
            '& .MuiFormControl-root': {
                margin: "0 !important",
                width: '100%',
            },
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
            },
        },
    }
))

const self = (data, enumValue) => {
    return data?.find(d => d.positionOfTire === enumValue)
}

const daysPassedToWeek = () => {
    const current = new Date()
    const previous = new Date(current.getFullYear(), 0, 1)
    const DAY_TO_MS = 86400000
    const DAY_PER_WEEK = 7
    return Math.ceil(Math.ceil((current - previous + 1) / DAY_TO_MS) / DAY_PER_WEEK)
}
const yearIsCorrect = (y) => y?.length === 4
const yearRangeIsCorrect = (y, currentYear) => {
    const minOfYear = Number(currentYear) - config.constant.YEAR_LENGHT
    return Number(y) >= minOfYear && Number(y) <= currentYear
}

const DetailsElement = ({ position, data, enumValue, label, onChange, onBlur, errors, tire_size, onTouched }) => {
    const selfValue = self(data, enumValue)
    if (!selfValue) return null;
    const error = errors?.[enumValue]
    const touched = errors?.touched?.[enumValue]
    const validateIsError = error?.tireSizeId?.required || error?.week?.required || error?.year?.required
    return (
        <React.Fragment>
            <Row>
                <Col xs={6}>
                    <Select
                        id={`${position}_size`}
                        label={label[`${position}_size`]}
                        defaultValue={null}
                        value={selfValue?.tireSizeId}
                        onChange={e => onChange(e, "tireSizeId", enumValue)}
                        onBlur={() => onTouched(enumValue)}
                        data={tire_size}
                        dataText="tireSize"
                        error={error?.tireSizeId?.required && touched}
                    />
                </Col>
                <Col xs={3}>
                    <TextFieldWrapped
                        name={`${position}_week`}
                        variant="outlined"
                        label={label.week}
                        value={selfValue?.week}
                        onChange={(e) => onChange(e, "week", enumValue)}
                        onBlur={(e) => {
                            onTouched(enumValue)
                            onBlur(e, "week", enumValue)
                        }}
                        error={error?.week?.required && touched} />
                </Col>
                <Col xs={3}>
                    <TextFieldWrapped
                        name={`${position}_year`}
                        variant="outlined"
                        label={label.year}
                        value={selfValue?.year}
                        onChange={(e) => onChange(e, "year", enumValue)}
                        onBlur={(e) => {
                            onTouched(enumValue)
                            onBlur(e, "year", enumValue)
                        }}
                        error={error?.year?.required && touched} />
                </Col>
            </Row>
            <ValidateError
                error={validateIsError && touched}
                message={I18n.t("validate_message.please_select", { label: label.validate })} />
        </React.Fragment >
    )
}

const TireDetailGroup = ({ data, tire_size, tire_label, onChange, onTouched, errors }) => {
    const classes = useStyles();
    const onSelected = ({ target: { value } }, target, enumValue) => {
        if (target !== "tireSizeId") {
            if (isNaN(Number(value)) || value.length > 2) {
                return;
            }
            const currentData = self(data, enumValue)
            const currentYear = moment().year().toString();
            const year = config.constant.CENTURY + (target === "week" ? currentData?.year : value)
            const week = target === "week" ? value : currentData?.week

            if (yearIsCorrect(year) && !yearRangeIsCorrect(year, currentYear)) {
                if (year > currentYear) {
                    value = (currentYear).toString().substr(2, 2)
                } else {
                    value = (currentYear - 5).toString().substr(2, 2)
                }
            }

            if (year >= currentYear) {
                const currentYearCountWeek = daysPassedToWeek();
                if (week > currentYearCountWeek) {
                    if (target === "year") {
                        currentData.week = ""
                    }
                    if (target === "week") {
                        value = currentYearCountWeek
                    }
                }
            }

            if (week.length > 1) {
                if (week < 1) {
                    return
                }
                if (week > config.constant.WEEK_PER_YEAR) {
                    if (year && currentYear === year) {
                        value = daysPassedToWeek();
                    } else {
                        value = config.constant.WEEK_PER_YEAR
                    }
                }
            }
            return onChange(value, target, enumValue)
        }
        return onChange(value, target, enumValue)
    }

    const onBlurInputNumber = ({ target: { value } }, target, enumValue) => {
        if (target === "week") {
            const currentData = self(data, enumValue)
            const currentYear = moment().year().toString();
            const currentYearCountWeek = daysPassedToWeek();
            if (currentData?.year) {
                const year = config.constant.CENTURY + currentData.year
                const isCurrentYear = year === currentYear
                if (isCurrentYear) {
                    if (Number(value) > currentYearCountWeek) {
                        value = currentYearCountWeek.toString()
                    }
                }
            }
        }

        if (value.length === 1) {
            value = "0" + value
            if (!Number(value)) {
                value = ""
            }
        }

        if (target === "year") {
            const currentYear = moment().year().toString();
            const year = config.constant.CENTURY + value
            const invalidYear = yearIsCorrect(year) && !yearRangeIsCorrect(year, currentYear)
            if (invalidYear) {
                value = ""
            }
        }
        return onChange(value, target, enumValue)
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <DetailsElement
                    position="fl"
                    data={data}
                    enumValue={POSITION_OF_TIRE_ENUM.FRONT_LEFT.value}
                    label={tire_label}
                    onChange={onSelected}
                    onBlur={onBlurInputNumber}
                    tire_size={tire_size}
                    errors={errors}
                    onTouched={onTouched}
                />
                <DetailsElement
                    position="fr"
                    data={data}
                    enumValue={POSITION_OF_TIRE_ENUM.FRONT_RIGHT.value}
                    label={tire_label}
                    onChange={onSelected}
                    onBlur={onBlurInputNumber}
                    tire_size={tire_size}
                    errors={errors}
                    onTouched={onTouched}
                />
                <DetailsElement
                    position="rl"
                    data={data}
                    enumValue={POSITION_OF_TIRE_ENUM.BACK_LEFT.value}
                    label={tire_label}
                    onChange={onSelected}
                    onBlur={onBlurInputNumber}
                    tire_size={tire_size}
                    errors={errors}
                    onTouched={onTouched}
                />
                <DetailsElement
                    position="rr"
                    data={data}
                    enumValue={POSITION_OF_TIRE_ENUM.BACK_RIGHT.value}
                    label={tire_label}
                    onChange={onSelected}
                    onBlur={onBlurInputNumber}
                    tire_size={tire_size}
                    errors={errors}
                    onTouched={onTouched}
                />
            </div>
        </React.Fragment>
    )
}

TireDetailGroup.propTypes = {
    data: PropTypes.array.isRequired,
    tire_label: PropTypes.object.isRequired
}

export default TireDetailGroup