import { HAS_ERROR, CLEAR_ERROR } from "./type"

export const hasErrors = (error) => {
    return async dispatch => {
        console.log(error)
        await dispatch({
            type: HAS_ERROR,
            payload: {
                status: true,
                message: error?.message,
                code: error?.code
            }
        })
    }
}

export const clearErrors = (callback) => {
    return async dispatch => {
        await dispatch({
            type: CLEAR_ERROR,
            payload: false
        })
        callback && callback()
    }
}