import { combineReducers } from "redux";
import { CarBrandReducer } from "./carBrands";
import { CarModelReducer } from "./carModel";

const CarReducer = combineReducers({
    carBrand: CarBrandReducer,
    carModel: CarModelReducer
})

export { CarReducer };
