import { CircularProgress, Fab, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        alignItems: 'center',
        width: 56,
        height: 56,
        '& .step': {
            background: 'transparent',
            '& .label': {
                letterSpacing: '-2px',
                fontSize: '1.125em',
                color: theme.palette.text.primary,
                '&:first-letter': {
                    fontSize: '1.4em',
                    color: theme.palette.primary.main,
                }
            },
            '& .smaller': {
                fontSize: '1.5em',
                color: theme.palette.text.primary,
            },
        },
        '& .progress': {
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },

        '& .MuiCircularProgress-circleStatic': {
            color: "white",
        }

    },
}))


const CircleProgress = ({ step, total }) => {
    const classes = useStyles()
    const progress = (step / total) * 100
    return (
        <div className={classes.root}>
            <Fab
                aria-label="save"
                color="primary"
                className='step'
            >
                <Typography className='label'>
                    {step} / {total}
                </Typography>
            </Fab>
            <CircularProgress size={68} variant="static" value={progress} className='progress' thickness={1.5} />
        </div>
    )
}

export default CircleProgress