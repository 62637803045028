import { makeStyles } from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import TireLogo from "../../assets/logo/tire_logo.png";
import ErrorMessage from '../../components/ErrorMessage';
import Header from '../../components/Header';
import withPersonal from '../../components/HoC/withPersonal';
import Spinner from '../../components/Spinner';
import WarrantyCard from '../../components/WarrantyCard';
import { Body, ButtonWrapped, Container } from '../../components/Wrapped';
import { config } from '../../config';
import { getWarranty } from '../../store/reducers/warranty/actions';
import commonFunction from '../../config/common.function';

const useStyles = makeStyles(theme => ({
    root: {
        '& .content-container': {
            maxWidth: '360px',
            width: '90%',
            margin: 10,
        }
    }
}))

const Warranty = ({ label, classes }) => {
    const history = useHistory()
    const dispatcher = useDispatch()
    const warranty = useSelector(state => state.warranty)
    const [dataList, setDataList] = useState([])
    const warrantyClasses = useStyles()
    const register_mode = history.location.state?.register

    const onSettings = () => {
        history.push(config.route.memberSettings)
    }

    useEffect(() => {
        dispatcher(getWarranty(result => {
            result.sort(
                (a, b) =>
                    config.common.compare(
                        moment(b.warrantyExpiredDate.toString(), "DD-MM-YYYY").unix(),
                        moment(a.warrantyExpiredDate.toString(), "DD-MM-YYYY").unix()))
            setDataList(result)
        }))
    }, [dispatcher])


    if (warranty.loading) {
        return (
            <React.Fragment>
                <ErrorMessage root={config.route.member} />
                <Spinner />
            </React.Fragment>
        )
    }

    return (
        <Container className={[classes.root, warrantyClasses.root]}>
            <ErrorMessage root={config.route.member} />
            <Body>
                <Header title={label.warranty.header} logo={TireLogo} titleStyle={{ bottom: 15, right: 50, fontSize: commonFunction.fontSizeHeader() }} />
                {
                    !register_mode &&
                    <div className="setting-container">
                        <ButtonWrapped
                            onClick={onSettings}
                            startIcon={<SettingsOutlinedIcon />}
                            className="setting-button"
                            variant="contained"
                            size="medium">
                            {label.warranty.setting}
                        </ButtonWrapped>
                    </div>
                }
                <div className="content-container" style={{ marginTop: register_mode ? 35 : 10 }}>
                    {dataList?.map((data, _i) => {
                        return (
                            <WarrantyCard key={`warranty_card_${_i}`} data={data} label={label.warranty} />
                        )
                    })}
                </div>
            </Body>
        </Container>
    )
}

export default withPersonal(Warranty)