import { ExpansionPanel, Link, makeStyles } from '@material-ui/core';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import renderHTML from 'react-render-html';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import SubBackground from "../../assets/background/sub_background.png";
import SubBackgroundDesktop from "../../assets/background/sub_background_desktop.png";
import QALogo from "../../assets/logo/qa_logo.png";
import { baseStyles, Body, Container } from '../../components/Wrapped';

const questions = [
    {
        header: 'หากเปลี่ยน line หรือ โทรศัพท์ จะทำอย่างไร?',
        content: `
            การเปลี่ยนโทรศัพท์มี 2 กรณีดังนี้ :
            <br/><br/>
            <span style="font-weight: 400;">กรณีที่ 1 เปลี่ยนโทรศัพท์ แต่ไม่เปลี่ยน Line account</span>
            <br/>
            ท่านสามารถดำเนินการใช้งาน Line account เดิมได้โดยอัตโนมัติ โดยหากมีความประสงค์จะตรวจสอบสถานะ ให้ทำการเพิ่มเพื่อนใน Application Line : @dunloptirethailand ได้ทันที
            <br/><br/>
            <span style="font-weight: 400">กรณีที่ 2 เปลี่ยน Line account</span>
            <br/>
            *หมายเหตุ หากท่านเปลี่ยน Line account ระบบจะไม่สามารถเชื่อมต่อข้อมูลเก่าของท่าน
            ท่านสามารถทำการเชื่อมต่อข้อมูลเก่าของท่านอีกครั้งได้โดยการลงทะเบียนใหม่เพื่อทำการเชื่อมต่อข้อมูลอัตโนมัติ
            <br/><br/>
            1. ไปที่ศูนย์บริการ Dunlop shop เดิมที่ท่านเคยใช้บริการซื้อยาง โปรแกรมรับประกันดันลอปอุ่นใจประกันยาง 100 วัน เพื่อรับเลขประจำตัวศูนย์บริการในการลงทะเบียนใหม่
            <br/><br/>
            2. ทำการลงทะเบียนใหม่
            <br/>
            *หมายเหตุ ท่านจำเป็นต้องซื้อยางเพิ่ม เพื่อทำการลงทะเบียนใหม่เท่านั้น ระบบจะทำการตรวจสอบและเชื่อมต่อข้อมูลอัตโนมัติ
            <br/><br/>
            3. ระบบทำการเชื่อมต่อข้อมูลใหม่และข้อมูลเก่า
            <br/>
            *หมายเหตุ หากลงทะเบียนข้อมูลใหม่ไม่ตรงกับข้อมูลเก่า ระบบจะไม่สามารถทำการเชื่อมต่อข้อมูลได้
            <br/><br/>
            4. ระบบทำการเชื่อมต่อเสร็จสิ้น
            <br/><br/>
            5. ตรวจเช็คข้อมูลได้โดยผ่าน ระบบ สมาชิก(Member)
        `
    },
    {
        header: 'เบอร์ติดต่อศูนย์บริการ',
        content: `เบอร์ติดต่อศูนย์บริการ <a style="color: #0079bf;" href="tel:+6627440199">02-744-0199</a>`,
    },
    {
        header: 'หากต้องการเปลี่ยน Password',
        content: `
            1. เข้าสู่ระบบ "สมาชิกดันลอป"
            <br/><br/>
            2. เข้าสู่ระบบด้วยรหัสผ่าน 6 หลัก
            <br/><br/>
            3. ไปที่  "ตั้งค่า"
            <br/><br/>
            4. ไปที่ "เปลี่ยนรหัสผ่าน"
            <br/><br/>
            5. ระบบจะเข้าสู่หน้า "เปลี่ยนรหัสผ่าน" โดยอัตโนมัติ
            <br/><br/>
            6. ทำตามขั้นตอนบนหน้าจอเพื่ออัพเดทรหัสผ่านของคุณ
            <br/><br/>
            7. เปลี่ยนรหัสผ่านเสร็จสิ้น
        `,
    },
    {
        header: 'ลืมรหัส Password',
        content: `
            1. เข้าสู่ระบบ "สมาชิกดันลอป"
            <br/><br/>
            2. ไปที่ "ลืมรหัสผ่าน"
            <br/><br/>
            3. ระบบจะเข้าสู่หน้า "รีเซ็ตรหัสผ่าน" โดยอัตโนมัติ
            <br/><br/>
            4. ทำตามขั้นตอนโดยการยืนยันตัวตนด้วย เลขบัตรประชาชน และ วันเดือนปีเกิด
            <br/><br/>
            5. ทำตามขั้นตอนบนหน้าจอเพื่ออัพเดทรหัสผ่านของคุณ
            <br/><br/>
            6. เปลี่ยนรหัสผ่านเสร็จสิ้น
        `,
    },
]

const ContainerWrapper = styled(Container)`
    position: relative;
    background-color: #020202;
    background-image: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media(min-width: 768px){
        background-image: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat; 
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'auto',
        '& .header-content': {
            margin: '30px 0px 30px 0px',
            '& img': {
                width: 55,
                height: 55
            },
            '& .header': {
                marginTop: 10,
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.primary,
            },
        },
        '& .body': {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '90%',
            height: '100%',
            '& .qa-container': {
                marginBottom: 15,
                '& .expand-container': {
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: 'unset',
                    borderBottomLeftRadius: 'unset',
                    '& .expand-header': {
                        color: '#d1d1d1',
                        padding: '0 12px 0 12px',
                    },
                    '& .expand-content': {
                        padding: '24px 12px 24px 12px',
                        background: '#dadada',
                        color: '#191919',
                        borderBottomRightRadius: theme.shape.borderRadius,
                        borderBottomLeftRadius: theme.shape.borderRadius,
                    }
                },
            },
            '& .hint': {
                marginTop: 10,
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.text.hint,
                lineHeight: 1.2,
            },
            '& .goback-wrapper': {
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                '& .goback': {
                    marginTop: 'unset',
                    borderBottom: `1px solid ${theme.palette.text.hint}`,
                },
                '& span': {
                    fontSize: '0.8em',
                    color: theme.palette.text.hint,
                    marginBottom: 2,
                    marginRight: 5,
                },
            },
        },
    }
}))

const QA = () => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const label = I18n.t('qa')

    return (
        <ContainerWrapper className={[baseClasses.root, baseClasses.container, classes.root]}>
            <Body>
                <div className="header-content">
                    <img src={QALogo} alt="qa" />
                    <Typography variant="h3" className='header'>{label.header}</Typography>
                </div>
                {
                    questions.map((question, index) => {
                        return (
                            <div key={`qa_${index}`} className="qa-container">
                                <ExpansionPanel className="expand-container">
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        className="expand-header"
                                    >
                                        <Typography>{question.header}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="expand-content">
                                        <Typography>
                                            {renderHTML(question.content)}
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        )
                    })
                }
                <Link className="goback-wrapper" onClick={() => {
                    history.goBack()
                }}>
                    <span>{"<<"}</span>
                    <Typography className="hint goback" style={{fontSize: 14}}>
                        {label.go_back}
                    </Typography>
                </Link>
            </Body>
        </ContainerWrapper>
    )
}
export default QA