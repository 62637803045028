import { makeStyles, Typography } from '@material-ui/core'
import { FastField, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import SubBackground from "../../../assets/background/sub_background.png"
import SubBackgroundDesktop from "../../../assets/background/sub_background_desktop.png"
import ErrorMessage from '../../../components/ErrorMessage'
import ValidateError from '../../../components/ValidateError'
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row, TextFieldWrapped } from '../../../components/Wrapped'
import { config } from '../../../config'
import { verifyDealer } from '../../../store/reducers/dealer/actions'
import { verifyRoute } from '../../../store/reducers/route/actions'

const headerStyle = {
    fontWeight: 300,
    lineHeight: 1.25
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .body': {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '90%',
            height: '100%',
            marginTop: '20%',
            '& .form': {
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
            },
            '@media(min-width: 500px)': {
                marginTop: '5%'
            },
            '@media(min-width: 600px)': {
                marginTop: '5%'
            },
            '@media(min-width: 768px)': {
                marginTop: '7%'
            },
        },
        '& .footer': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            marginTop: 0,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
            '& .MuiGrid-item:first-of-type': {
                paddingRight: 'unset',
            },
            '& .MuiGrid-item:last-of-type': {
                paddingLeft: 'unset',
            },
            '@media(min-width: 768px)': {
                bottom: '25%'
            },
        },
    },
    header: {
        ...headerStyle,
        color: theme.palette.text.primary,
    },
    sub_header: {
        ...headerStyle,
        color: theme.palette.text.primary,
    },
    mark: {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.secondary
    }
}))

const ContainerWrapped = styled(Container)`
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media(min-width: 768px){
        background: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat; 
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`
const BodyWrapped = styled(Body)`

`

const VerifyDealer = ({ history }) => {
    const baseClasses = baseStyles()
    const dispatcher = useDispatch()
    const classes = useStyles()
    const label = { ...I18n.t("proof_valid_dealer"), ...I18n.t("button") }
    return (
        <ContainerWrapped className={[baseClasses.root, baseClasses.container, classes.root]}>
            <ErrorMessage root={config.route.register} />
            <BodyWrapped>
                <Typography variant="h3" className={classes.header}>{label.header}</Typography>
                <Typography variant="h3" className={classes.sub_header}>{label.sub_header}</Typography>
                <Typography className={classes.mark}>{`*${I18n.t("for_dealer")}`}</Typography>
                <Formik
                    initialValues={{
                        "code": ""
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (values.code) {
                            const pattern = /^[A-Z][0-9]{4}$/
                            if (!pattern.test(values.code)) {
                                errors['code'] = {
                                    ...errors['code'],
                                    pattern: true
                                }
                            }
                        } else {
                            errors['code'] = {
                                ...errors['code'],
                                required: true
                            }
                        }
                        return errors;
                    }}

                    onSubmit={({ code }, { setSubmitting }) => {
                        setTimeout(() => {
                            dispatcher(verifyRoute('verifyDealer'))
                            dispatcher(verifyDealer(code, () => {
                                history.replace(config.route.registerWarranty)
                            }))
                            setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ handleSubmit }) => (
                        <Form className="form" onSubmit={handleSubmit}>
                            <FastField name="code">
                                {({ field, form, meta }) => (
                                    <React.Fragment>
                                        <TextFieldWrapped {...field}
                                            label={label.dealer_code}
                                            variant="outlined"
                                            error={meta.error && meta.touched}
                                            onChange={({ target: { value } }) => {
                                                let result = value.toUpperCase()
                                                if (result.length > 5) return
                                                form.setFieldValue('code', result)
                                            }}
                                        />
                                        <ValidateError
                                            error={meta.error?.required && meta.touched}
                                            message={I18n.t("validate_message.please_input", { label: label.dealer_code })} />
                                        <ValidateError
                                            error={meta.error?.pattern && meta.touched}
                                            message={I18n.t("validate_message.pattern", { label: label.eg })} />
                                    </React.Fragment>
                                )}
                            </FastField>
                            <Footer>
                                <Row style={{ display: 'flex', alignItems: 'center' }}>
                                    <Col xs={12}>
                                        <ButtonWrapped variant="contained" size="large" type="submit" color="primary">
                                            {label.next}
                                        </ButtonWrapped>
                                    </Col>
                                </Row>
                            </Footer>
                        </Form>
                    )}
                </Formik>
            </BodyWrapped>
        </ContainerWrapped>
    )
}

export default VerifyDealer