import { makeStyles, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import SubBackground from "../../../assets/background/sub_background.png"
import SubBackgroundDesktop from "../../../assets/background/sub_background_desktop.png"
import PasswordSuccessLogo from "../../../assets/logo/password_success_logo.png"
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../../components/Wrapped'
import { config } from '../../../config'

const headerStyle = {
    fontWeight: 100,
    lineHeight: 1.25
}

const useStyles = makeStyles(theme => ({
    root: {
        '& .body': {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '90%',
            height: '100%',
            marginTop: '20%',
            '@media(min-width: 500px)': {
                marginTop: '5%'
            },
            '@media(min-width: 600px)': {
                marginTop: '5%'
            },
            '@media(min-width: 768px)': {
                marginTop: '7%'
            },
            '& .content-wrapper': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                    width: 80,
                    height: 80,
                    margin: 15,
                },
                '& .header': {
                    ...headerStyle,
                    color: theme.palette.text.primary,
                    transform: 'scale(1.2)',
                    margin: 15,
                },
            }
        },
        '& .footer': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            marginTop: 0,
            marginBottom: 10,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
            '& .MuiGrid-item:first-of-type': {
                paddingRight: 'unset',
            },
            '& .MuiGrid-item:last-of-type': {
                paddingLeft: 'unset',
            },
            '@media(min-width: 768px)': {
                bottom: '25%'
            },
        },
    },
}))

const ContainerWrapped = styled(Container)`
    background: url(${SubBackground}) !important;
    background-repeat: no-repeat; 
    background-position: center center;
    background-size: cover !important;
    align-items: center !important;

    @media(min-width: 768px){
        background: url(${SubBackgroundDesktop}) !important;
        background-repeat: no-repeat; 
        background-position: center center;
        background-size: cover !important;
        align-items: center !important;
    }
`
const BodyWrapped = styled(Body)`

`

const ResetPasswordSuccess = () => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const label = { ...I18n.t("change_password"), ...I18n.t("button") }
    const isSettings = history.location.state?.settings

    useEffect(() => {
        sessionStorage.removeItem('resetMode')
    }, [])

    const handleBack = () => {
        if (isSettings) {
            history.goBack()
        } else {
            history.replace(config.route.memberWarranty)
        }
    }

    return (
        <ContainerWrapped className={[baseClasses.root, baseClasses.container, classes.root]}>
            <BodyWrapped>
                <div className='content-wrapper'>
                    <img src={PasswordSuccessLogo} alt="success" />
                    <Typography variant="h3" className='header'>{label.success}</Typography>
                </div>
                <Footer>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={12}>
                            <ButtonWrapped variant="contained" size="large" type="submit" color="primary"
                                onClick={handleBack}>
                                {label.back_warranty_detail}
                            </ButtonWrapped>
                        </Col>
                    </Row>
                </Footer>
            </BodyWrapped>
        </ContainerWrapped >
    )
}

export default ResetPasswordSuccess