import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import Tire from "../../assets/logo/tire.png";

const Container = withStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center !important',
        background: 'linear-gradient(#191919 40%, #000000 100%)',
        '& .container-wrapper': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            '& img': {
                width: 100,
                height: 100,
                background: 'transparent',
                animation: 'rotation 2s infinite linear'
            },
            '& .loading-wrapper': {
                display: 'flex',
                flexDirection: 'row',
                marginTop: 15,
                '& .loading-text': {
                    color: theme.palette.primary.main,
                    marginRight: 10,
                    animation: 'scale 0.5s steps(25, end) 0s infinite',
                    '&:nth-child(1)': {
                        animationDuration: '0.75s'
                    },
                    '&:nth-child(2)': {
                        animationDuration: '1s'
                    },
                    '&:nth-child(3)': {
                        animationDuration: '0.75s'
                    },
                    '&:nth-child(4)': {
                        animationDuration: '1s'
                    },
                    '&:nth-child(5)': {
                        animationDuration: '0.75s'
                    },
                    '&:nth-child(6)': {
                        animationDuration: '1s'
                    },
                    '&:nth-child(7)': {
                        animationDuration: '0.75s'
                    },
                },
                '& .loading-text:first-of-type': {
                    marginLeft: 0
                },
                '& .loading-text:last-of-type': {
                    marginRight: 0
                },
            },
        }
    }
}))(({ classes, children }) => {
    return (
        <div className={classes.root}>
            <div className="container-wrapper">
                <React.Fragment>
                    {children}
                    <div className="loading-wrapper">
                        <Typography className="loading-text" variant="h3">L</Typography>
                        <Typography className="loading-text" variant="h3">o</Typography>
                        <Typography className="loading-text" variant="h3">a</Typography>
                        <Typography className="loading-text" variant="h3">d</Typography>
                        <Typography className="loading-text" variant="h3">i</Typography>
                        <Typography className="loading-text" variant="h3">n</Typography>
                        <Typography className="loading-text" variant="h3">g</Typography>
                    </div>
                </React.Fragment>
            </div>
        </div>
    )
})

const Spinner = () => {
    return (
        <Container>
            <img src={Tire} alt="tire" />
        </Container>
    )
}

export default Spinner