class ErrorModel {
    code = "";
    message = "";
    status = false;
    constructor(error) {
        this.code = error?.code;
        this.message = error?.message;
    }
}

export default ErrorModel