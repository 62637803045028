import { PERSONAL_SUBMIT_FORM, VERIFY_PERSONAL_BEGIN, VERIFY_PERSONAL_SUCCESS, SET_PDPA_FLAG, GET_PERSONAL_PROFILE_SUCCESS, VERIFY_PERSONAL_ERROR, CLEAR_PERSONAL_FORM } from "./type"
import http from "../../../http"
import { config } from "../../../config"
import { hasErrors } from "../error/actions"

export const personalSubmitForm = (data, callback) => {
    return async dispatch => {
        await dispatch({ type: PERSONAL_SUBMIT_FORM, payload: data })
        callback && callback()
    }
}

export const setPDPAFlag = (flag) => {
    return async dispatch => {
        await dispatch({ type: SET_PDPA_FLAG, payload: flag })
    }
}

export const verifyCitizenId = (identificationNumber, callback) => {
    return async dispatch => {
        try {
            const result = await http.post(config.api.CUSTOMER.REGISTER_WARRANTY.VERIFY_ID_CARD, { identificationNumber })
            await dispatch(success(result, callback))
        } catch (error) {
            await dispatch(failure(error))
        }
    }
}

export const changePassword = ({ password, confirmPassword }, callback, errorCallback) => {
    return async dispatch => {
        try {
            await dispatch(begin())
            const result = await http.post(config.api.CUSTOMER.MEMBER.CHANGE_PASSWORD, { password, confirmPassword })
            if (result?.token) {
                localStorage.setItem('token', result.token)
            }
            callback && callback()
            await dispatch(success())
        } catch (error) {
            errorCallback && errorCallback()
            await dispatch(failure(error))
        }
    }
}

export const getProfiles = () => {
    return async dispatch => {
        try {
            await dispatch(begin())
            const result = await http.get(config.api.CUSTOMER.MEMBER.GET_PROFILE)
            await dispatch(getProfileSuccess(result))
        } catch (error) {
            await dispatch(failure(error))
        }
    }
}

export const verifyForgotPassword = ({ idCard, birthDate }, callback) => {
    return async dispatch => {
        try {
            await dispatch(begin())
            const result = await http.post(config.api.CUSTOMER.MEMBER.VERIFY_FORGOT_PASSWORD, { idCard, birthDate })
            if (result?.token) {
                localStorage.setItem('token', result.token)
            }
            callback && callback()
            await dispatch(success())
        } catch (error) {
            await dispatch(failure(error))
        }
    }
}

export const updateProfiles = (data, callback) => {
    return async dispatch => {
        try {
            await dispatch(begin())
            await http.put(config.api.CUSTOMER.MEMBER.UPDATE_PROFILE, data)
            await callback && callback()
            await dispatch(success())
        } catch (error) {
            await dispatch(failure(error))
        }
    }
}

export const clearPersonalForm = () => {
    return async dispatch => {
        await dispatch({ type: CLEAR_PERSONAL_FORM })
    }
}

const begin = () => {
    return async dispacher => {
        await dispacher({ type: VERIFY_PERSONAL_BEGIN })
    }
}
const success = (result, callback) => {
    return async dispatch => {
        if (result) {
            await dispatch({ type: VERIFY_PERSONAL_SUCCESS, payload: result })
        } else {
            await dispatch({ type: VERIFY_PERSONAL_SUCCESS })
        }
        if (callback) {
            callback(result?.status)
        }
    }
}

const failure = (error) => {
    return async dispatch => {
        await dispatch({ type: VERIFY_PERSONAL_ERROR })
        await dispatch(hasErrors(error))
    }
}

const getProfileSuccess = (result) => {
    return async dispacher => {
        await dispacher({ type: GET_PERSONAL_PROFILE_SUCCESS, payload: result })
    }
}