import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router'
import { LAYOUT_SPACING } from '../../App.config'
import { MAIN_COLOR_INPUT } from '../../App.theme'
import { config } from '../../config'
import Spinner from '../Spinner'
const useStyle = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        height: 'auto',
        minHeight: '100vh',
        '& .body': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '768px',
            minHeight: 'calc(100vh)',
            width: '100%',
        },
        '& .body-content': {
            margin: theme.spacing(LAYOUT_SPACING),
            padding: theme.spacing(LAYOUT_SPACING),
        },
        '& .header-content': {
            background: theme.palette.banner
        },
        '& .remark-container': {
            background: theme.palette.background.default,
            '& .remark-content': {
                color: theme.palette.text.primary,
            }
        },
        '& .setting-container': {
            marginTop: '10px',
            marginRight: '10px',
            alignSelf: 'flex-end',
            '& .setting-button': {
                background: MAIN_COLOR_INPUT,
                color: theme.palette.text.primary,
                paddingLeft: 20,
                paddingRight: 20,
                '& svg': {
                    color: theme.palette.primary.main,
                    // fontSize: theme.typography.h5.fontSize,
                    fontSize: theme.typography.h4.fontSize,
                },
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                },
            },
        },
        '& .footer': {
            marginTop: 'auto',
            marginBottom: 15,
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
    }
}))

const withPersonal = Component => props => {
    const personal = useSelector(state => state.personal.formData)
    const car = useSelector(state => state.car.formData)
    const label = {
        ...I18n.t("personal"),
        button: I18n.t("button"),
        warranty: I18n.t('warranty'),
        settings: I18n.t('settings')
    }
    const { edit: isEdit } = props?.history?.location?.state || { edit: false }
    const classes = useStyle()
    const isExisting = !useSelector(state => state.authorization.newUser)
    const steps = isExisting ? 2 : 3
    const attributes = { personal, car, label, classes, isEdit, isExisting, steps }
    const history = useHistory()
    // const hasCreated = useSelector(state => state.warranty.created)
    const hasCreated = sessionStorage.getItem('created')


    if (hasCreated && history.location.pathname !== config.route.memberWarranty) {
        history.goBack()
        return (
            <Spinner />
        )
    }

    if (personal.loading) {
        return <Spinner />
    }

    return (
        <Component {...props} {...attributes} />
    )
}

export default withPersonal