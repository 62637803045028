import React from 'react';
import { RadioWrapped } from '../Wrapped';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx'

const RadioButton = withStyles(theme => (
    {
        root: {
            padding: 0,
            marginRight: 10,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            width: 18,
            height: 18,
            backgroundColor: 'transparent',
            border: '1px solid #666 !important',
        },
        checkedIcon: {
            backgroundColor: 'transparent',
            '&:before': {
                display: 'block',
                width: 18,
                height: 18,
                backgroundImage: `radial-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main} 56%,transparent 64%)`,
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: "transparent",
            },
        },
    }
))(({ classes, ...props }) => {
    return (
        <RadioWrapped
            {...props}
            className={classes.root}
            icon={<span className={classes.icon} />}
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        />
    )
})

const Radio = ({ ...props }) => {
    return (
        <RadioButton {...props} />
    )
}
export default Radio