export const POSITION_OF_TIRE_ENUM = {
    FRONT_LEFT: {
        value: "LF",
        seq: 1
    },
    FRONT_RIGHT: {
        value: "RF",
        seq: 2
    },
    BACK_LEFT: {
        value: "LB",
        seq: 3
    },
    BACK_RIGHT: {
        value: "RB",
        seq: 4
    }
}

export const TIRE_SIZE_ENUM_LABEL = {
    FL: "fl_size",
    FR: "fr_size",
    BL: "rl_size",
    BR: "rr_size",
}

class TirePositionModel {
    positionOfTire = "";
    tireSizeId = "";
    tireSizeText = "";
    seq
    week = ""
    year = ""
    manufacturingWeek = "";
    manufacturingYear = "";
    constructor(positionOfTire, seq) {
        this.positionOfTire = positionOfTire
        this.seq = seq
    }
}

export default TirePositionModel