import { CircularProgress, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useHistory, Redirect } from 'react-router';
import { LAYOUT_SPACING } from '../../App.config';
import { MAIN_COLOR } from '../../App.theme';
import CardDetail from '../../components/CardDetail';
import { BlockModel, CardDetailModel, ContentModel } from '../../components/CardDetail/CardDetailModel';
import ErrorMessage from '../../components/ErrorMessage';
import Radio from '../../components/Radio';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, LabelWrapped, RadioGroupWrapped, Row } from '../../components/Wrapped';
import { config } from '../../config';
import { clearCarForm } from '../../store/reducers/car/actions';
import { clearPersonalForm, setPDPAFlag } from '../../store/reducers/personal/actions';
import { clearWarrantyForm, createWarranty } from '../../store/reducers/warranty/actions';

const data = {
    term: `
    1. วัตถุประสงค์ในการประมวลผล
    <br />
    ข้อมูลส่วนบุคคลของท่านอาจถูกประมวลผลเพื่อการให้บริการประกันภัยยาง 100 วัน การรับประกันสินค้าและวิเคราะห์การตลาดซึ่งให้บริการหรือดำเนินการโดย บริษัท ดันลอป ไทร์ (ไทยแลนด์) จำกัด (“บริษัทฯ”) ทั้งนี้ บริษัทฯ อาจใช้ข้อมูลส่วนบุคคลของท่านในการติดต่อประชาสัมพันธ์โปรโมชั่น โฆษณา และข้อมูลอื่นๆ ที่ท่านอาจมีความสนใจทั้งทางตรงหรือทางอ้อม 
    <br /> <br/>
    2. การปฏิบัติตามสัญญาและผลของการไม่ให้ข้อมูล
    <br/>
    บริษัทฯ ประมวลผลข้อมูลส่วนบุคคลของท่านบนพื้นฐานของการปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญาหรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญา ทั้งนี้ ในกรณีที่ท่านปฏิเสธหรือไม่สามารถที่จะให้ข้อมูลดังกล่าวกับทางบริษัทฯ เมื่อร้องขอ บริษัทฯ อาจไม่สามารถให้การสนับสนุนเกี่ยวกับการรับประกันสินค้าแก่ท่านได้ นอกเหนือจากบริษัทฯ จะประมวลผลข้อมูลของท่านบนพื้นฐานของการปฏิบัติตามสัญญาแล้ว บริษัทฯ ยังอาจทำการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือประมวลผลบนพื้นฐานของความยินยอมของท่าน
    <br /> <br/>
    3. ข้อมูลส่วนบุคคลที่ถูกเก็บรวบรวมและระยะเวลาการเก็บรักษา
    <br/>
    ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวมอาจรวมถึง ชื่อนามสกุล เพศ อายุ ที่อยู่ หมายเลขโทรศัพท์ อีเมล ข้อมูลรถยนต์ (ชนิด/ยี่ห้อ เลขทะเบียน) และข้อมูลยาง เป็นต้น ข้อมูลส่วนบุคคลของท่านจะถูกเก็บรักษาไว้กับบริษัทฯ ในระยะเวลาเท่าที่จำเป็นเพื่อปฏิบัติตามวัตถุประสงค์ที่ได้ทำการเก็บรวบรวมหรือประมวลผลตามกฎหมายที่เกี่ยวข้อง หรือไม่ว่าในกรณีใดๆ เพื่อให้ บริษัทฯ สามารถปกป้องสิทธิและประโยชน์โดยชอบด้วยกฎหมายของตนหรือของบุคคลที่สามได้
    <br /> <br/>
    4. การเปิดเผยข้อมูลส่วนบุคคล
    <br/>
    บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลดังต่อไปนี้ตามวัตถุประสงค์ที่ได้กล่าวไว้ในข้อ 3 ข้างต้น
    <br/>
    (ก) เปิดเผยข้อมูลต่อบริษัท หรือภายในกลุ่มบริษัท
    <br/>
    (ข) เปิดเผยข้อมูลต่อบุคคลที่สามซึ่งเป็นตัวแทน/ตัวแทนจำหน่ายหรือผู้รับเหมาซึ่งมีหน้าที่ในการรักษาความลับอันเกี่ยวเนื่องกับการประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ตามที่ได้ระบุไว้ในนโยบายฉบับนี้ (ทั้งนี้ อาจรวมถึง คู่ค้าทางธุรกิจ ที่ปรึกษาทางการตลาด ผู้ให้บริการโซเชียลมีเดีย ผู้ให้บริการด้านไอที)
    <br/>
    (ค) เปิดเผยข้อมูลตามที่กฎหมาย ระเบียบ หรือคำสั่งศาลกำหนด และ/หรือ
    <br/>
    (ง) เปิดเผยแก่บุคคลที่สามในกรณีของการซื้อกิจการ การควบรวมกิจการ กระบวนการล้มละลายหรือการโอนทรัพย์สิน บริษัทฯ อาจส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศอื่นที่อยู่นอกราชอาณาจักร เช่น ประเทศญี่ปุ่น อย่างไรก็ตามประเทศปลายทางดังกล่าวอาจไม่มีกฎหมายการคุ้มครองข้อมูลส่วนบุคคลที่เท่าเทียมกับกฎหมายในประเทศไทย และท่านอาจไม่ได้รับความคุ้มครองข้อมูลส่วนบุคคลดังเช่นในประเทศไทย
    <br/>
    <br/>
    5. สิทธิของเจ้าของข้อมูลส่วนบุคคล
    <br/>
    ท่านมีสิทธิในการเข้าถึง แก้ไขทำให้สมบูรณ์หรือลบ ระงับการประมวลผล คัดค้านการประมวลผล ถอนความยินยอม ขอให้ส่งหรือโอนซึ่งข้อมูลส่วนบุคคลของท่านที่เราเก็บรวบรวมไว้ และสิทธิร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ในกรณีที่ท่านต้องการถอนความยินยอม ท่านสามารถถอนความยินยอมนี้เมื่อใดก็ได้ อย่างไรก็ตาม การถอนความยินยอมย่อมไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมไปแล้วโดยชอบด้วยกฎหมายแก่บริษัทฯ
    <br/><br/>
    6. ข้อมูลการติดต่อ
    <br/>
    ในกรณีที่ท่านต้องการใช้สิทธิตามข้อ 5 ข้างต้น หรือในกรณีที่ท่านมีข้อสงสัยหรือคำถามประการใดๆ เกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้ ท่านสามารถติดต่อบุคคลหรือแผนกที่เกี่ยวข้องได้ตลอดเวลา
    <br /> <br/>
        หากท่านประสงค์ที่จะเปลี่ยนแปลงความยินยอมในการเปิดเผยข้อมูล สามารถติดต่อได้ที่สำนักงานของบริษัทฯ โทร <a style="color: #bcd9ea;" href="tel:+6627440199">02-744-0199</a>
        <br /> <br/>
        อย่างไรก็ตาม การให้หรือไม่ให้ความยินยอมของท่านไม่ส่งผลต่อการพิจารณาการใช้ผลิตภัณฑ์
    `,
}

const getCardDetail = (label, data) => {
    const cardDetailModel = new CardDetailModel()
    cardDetailModel.header_title = label.privacy_and_term
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(null, data.term)))
    return cardDetailModel
}

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        height: 'auto',
        minHeight: '100vh',
        '& .body': {
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxWidth: '768px',
            width: '100%',
            '& .pdpa-flag': {
                marginTop: 'auto',
                // marginBottom: 20,
                width: '90%',
                '& .MuiFormGroup-root': {
                    minHeight: '80px',
                    marginLeft: '10px',
                    display: 'flex',
                    flexDirection: 'column !important',
                }
            }
        },
        '& .body-content': {
            margin: theme.spacing(LAYOUT_SPACING),
            padding: theme.spacing(LAYOUT_SPACING),
        },
        '& .header-content': {
            background: theme.palette.banner
        },
        '& .footer': {
            marginTop: 'auto',
            '& .MuiGrid-container': {
                margin: "0 !important",
                width: '100%',
                '& span': {
                    fontSize: theme.typography.h4.fontSize,
                    fontWeight: theme.typography.fontWeightBold
                },
            },
        },
    },
    buttonWrapper: {
        margin: theme.spacing(1),
        position: 'relative'
    },
    buttonProgress : {
        color: MAIN_COLOR,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}))

const PDPA = () => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const classes = useStyles()
    const label = { ...I18n.t('pdpa'), button: I18n.t('button') }
    const dispatcher = useDispatch()
    const [pdpaFlag, setPdpaFlag] = useState(null)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const auth = useSelector(state => state.authorization)
    const warranty = useSelector(state => state.warranty.formData)
    const customer = useSelector(state => state.personal.formData)
    const car = useSelector(state => state.car.formData)

    const clearData = useCallback((callback) => {
        dispatcher(clearWarrantyForm())
        dispatcher(clearPersonalForm())
        dispatcher(clearCarForm())
        callback && callback()
    }, [dispatcher])

    const onCancel = useCallback((e) => {
        clearData(() => {
            history.replace(config.route.register)
        })
    }, [history, clearData])

    const onConfirm = () => {
        setConfirmLoading(true);
        const params = { warranty, customer, car, newUser: auth.newUser }
        params.warranty.currentMileage = params.warranty.currentMileage.replace(/,/g, '')
        params.customer.identificationNumber = params.customer.identificationNumber? params.customer.identificationNumber.replace(/-/g, ''):config.common.convertTelAndBirthDateToNID(config.common.replaceForSave(params.customer.phoneNumber),params.customer.dateOfBirth)
        params.customer.phoneNumber = params.customer.phoneNumber.replace(/-/g, '')
        params.customer.address = params.customer.address ? params.customer.address.trim() : ''
        dispatcher(createWarranty(params, () => {
            setConfirmLoading(false);
            // deploy with -1
            // const direction = history.length - 1
            // history.go(-(direction))
            clearData()
        }))
    }

    useEffect(() => {
        if (pdpaFlag) {
            dispatcher(setPDPAFlag(pdpaFlag))
        }
    }, [pdpaFlag, dispatcher])

    const hasCreated = useSelector(state => state.warranty.created)

    if (hasCreated && history.location.pathname !== config.route.memberWarranty) {
        return (
            <Redirect to={config.route.register} />
        )
    }

    return (
        <Container className={[baseClasses.root, classes.root]}>
            <ErrorMessage root={config.route.register} />
            <Body>
                <CardDetail
                    isHTML
                    cardDetail={getCardDetail(label, data)}
                    containerStyle={{
                        marginTop: 30,
                        height: '60vh',
                    }}
                    contentContainerStyle={{
                        overflowY: 'scroll',
                    }}
                />
                <div className="pdpa-flag">
                    <RadioGroupWrapped
                        aria-label="pdpa-flag"
                        name="pdpa-flag"
                        value={pdpaFlag}
                        onChange={({ target: { value } }) => {
                            setPdpaFlag(value)
                        }}>
                        <FormControlLabel
                            value={config.constant.AGREE.toString()} control={<Radio />}
                            label={
                                <LabelWrapped active={pdpaFlag === config.constant.AGREE.toString()}>
                                    {label.agree}
                                </LabelWrapped>} />
                        <FormControlLabel
                            value={config.constant.DISAGREE.toString()} control={<Radio />}
                            label={
                                < LabelWrapped active={pdpaFlag === config.constant.DISAGREE.toString()}>
                                    {label.not_agree}
                                </LabelWrapped>
                            }
                        />
                    </RadioGroupWrapped>
                </div>
                <Footer style={{ width: '90%' }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={5} >
                            <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={onCancel}>
                                {label.button.cancel}
                            </ButtonWrapped>
                        </Col>
                        <Col xs={7}>
                            <div className={classes.buttonWrapper}>
                                <ButtonWrapped disabled={pdpaFlag === null || confirmLoading} variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                    {label.button.accept}
                                </ButtonWrapped>
                                {confirmLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Col>
                    </Row>
                </Footer>
            </Body>
        </Container>
    )
}

export default PDPA