import { SIGN_IN, SIGN_OUT, VERIFY_LINE_ACCOUNT } from "./type";

const initialState = {
    isAuth: false,
    newUser: false,
    firstTime: false,
    verifyFirstTime: false
};
export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, ...action.payload }
        case SIGN_OUT:
            return { ...state, isAuth: action.payload }
        case VERIFY_LINE_ACCOUNT:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
};
