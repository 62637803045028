export default {
    th: {
        member_access: {
            header: "เข้าสู่ระบบ",
            hint: "*กรุณาใส่รหัสผ่าน 6 หลัก",
            password_validate: "รหัสผ่าน 6 หลัก",
            forgot_password: "ลืมรหัสผ่าน",
            first_time: {
                header: "สำหรับการเข้าสู่ระบบครั้งแรก",
                hint: "*กรุณาใส่รหัสผ่านเป็น วัน/เดือน/ปีเกิด (พ.ศ.) ของคุณ",
                password_validate: " วัน/เดือน/ปีเกิด ของคุณ",
            }
        },
        change_password: {
            header: "เปลี่ยนรหัสผ่าน",
            password: "กรุณาใส่รหัสผ่านปัจจุบัน",
            password_hint: "*กรุณาใส่รหัสผ่าน 6 หลัก",
            new_password: "ตั้งรหัสผ่านใหม่",
            new_password_hint: "*กรุณาใส่รหัสผ่านใหม่",
            confirm_password: "ยืนยันการตั้งรหัสผ่านใหม่",
            confirm_hint: "*กรุณาใส่รหัสผ่านใหม่ให้ตรงกับรหัสด้านบน",
            required: "รหัสผ่านใหม่",
            incorrect: "รหัสผ่านใหม่ให้ตรงกัน",
            success: "เปลี่ยนรหัสผ่านเรียบร้อย",
            reset_password: {
                header: "รีเซ็ตรหัสผ่าน",
            }
        },
        reset_password: {
            header: "รีเซ็ตรหัสผ่าน",
            hint: "*เราสามารถช่วยคุณในการตั้งค่ารหัสผ่านได้<br/>เพียงดำเนินการกรอกข้อมูลเพื่อยืนยันตัวตนตามขั้นตอน",
            hint2: "*กรุณากรอกข้อมูลตามที่ท่านได้ลงทะเบียนไว้",
            citizen_id: "เบอร์โทรศัพท์มือถือ",
            citizen_id_pattern: "เบอร์โทรศัพท์มือถือ ให้ถูกต้อง",
            date_of_birth: "วัน / เดือน / ปีเกิด",
        },
        warranty: {
            header: "รายการยางที่อยู่ในระยะเวลา<br/>รับประกัน 100 วัน",
            warranty_id: "Warranty ID",
            expire_date: "วันหมดอายุ",
            car_license: "ทะเบียนรถ",
            car_model: "รุ่นรถ",
            dealer_name: "ร้านค้าที่ซื้อ",
            status: "สถานะ",
            status_active: "อยู่ในการรับประกัน 100 วัน คงเหลือ %{date} วัน",
            status_inactive: "หมดเขตการรับประกันยาง 100 วัน",
            setting: "ตั้งค่า",
            not_found: "ไม่พบข้อมูลการรับประกันยางที่ %{dealer}",
        },
        settings: {
            header: "การตั้งค่า",
            edit_personal: "แก้ไขข้อมูลส่วนบุคคล",
            change_password: "เปลี่ยนรหัสผ่าน",
        },
        member_register: {
            header: "REGISTER",
            sub_header: "DUNLOP TIRE WARRANTY",
            description: "ลงทะเบียนโปรแกรมรับประกันดันลอปอุ่นใจ",
            sub_description: "ประกันภัยยาง 100 วัน",
            first_time: "ลงทะเบียนครั้งแรก",
            QA: "คำถามที่พบบ่อย",
            existing: "ลงทะเบียนเพิ่ม",
        },
        proof_valid_dealer: {
            header: "ใส่เลขประจำตัวศูนย์บริการ",
            sub_header: "Dunlop Shop",
            dealer_code: "เลขประจำศูนย์บริการ (e.g. A2xxx)",
            eg: "e.g. A2xxx",
        },
        tire: {
            header: "ข้อมูลการเปลี่ยนยาง",
            service_center_detail: "รายละเอียดศูนย์บริการ",
            tire_change_model: "รุ่นยางที่เปลี่ยน",
            tire_change_quantity: "จำนวนยางที่เปลี่ยนวันนี้ (เส้น)",
            tire_change_quantity_validate: "จำนวนยางที่เปลี่ยน",
            tire_change_position: {
                main: "ตำแหน่งยางที่เปลี่ยน",
                fl_size: "ขนาดยางหน้าซ้าย",
                fr_size: "ขนาดยางหน้าขวา",
                rl_size: "ขนาดยางหลังซ้าย",
                rr_size: "ขนาดยางหลังขวา",
                week: "สัปดาห์",
                year: "ปีผลิต",
                week_year_manufacture: "สัปดาห์/ปีผลิต",
                validate: "ขนาดยาง, สัปดาห์ และปีที่ผลิต",
            },
            tire_change_reason: "สาเหตุของการเปลี่ยนยาง",
            previous_tire_brand: "ยี่ห้อยางที่ใช้ก่อนหน้า",
            current_miles: "เลขไมล์รถปัจจุบัน",
            upload_proof_of_purchase: "อัพโหลดหลักฐานใบเสร็จ / ใบกำกับภาษี",
            customer_tire_detail: "รายละเอียดยางลูกค้า",
            on_behalf: "ซื้อในนาม%{behalf}",
            personal: "บุคคล",
            company: "บริษัท",
            company_name: "ชื่อบริษัท",
            confirm: {
                header: "ตรวจสอบข้อมูลยาง",
                tire_change_model: "รุ่นยางที่เปลี่ยน",
                tire_detail_header: "รายละเอียดยาง",
                tire_position: "ตำแหน่งยาง",
                quantity: "จำนวน",
                quantity_value: "%{quantity} เส้น",
                front_tire: "ยางหน้า",
                back_tire: "ยางหลัง",
                front_back_tire: "ยางหน้าและยางหลัง",
                front_left: "ยางหน้าซ้าย",
                front_right: "ยางหน้าขวา",
                back_left: "ยางหลังซ้าย",
                back_right: "ยางหลังขวา",
                tire_size: "ขนาดยาง",
                week_year_manu: "สัปดาห์/ปีผลิต",
                reason: "สาเหตุที่เปลี่ยน",
                previous: "ยี่ห้อยางที่ใช้ก่อนหน้า",
                current_miles: "เลขไมล์รถปัจจุบัน",
                current_miles_value: "%{miles} กม.",
            }
        },
        personal: {
            header: "ข้อมูลส่วนบุคคล",
            title: "คำนำหน้าชื่อ",
            name: "ชื่อ-นามสกุล",
            first_name: "ชื่อ",
            last_name: "นามสกุล",
            citizen_id: "เลขบัตรประชาชน %{notRequired}",
            citizen_id_pattern: "เลขบัตรประชาชนให้ถูกต้อง",
            date_of_birth: "วัน / เดือน / ปีเกิด",
            warning_date: "ใช้เป็นรหัสในการเข้าสู่ระบบครั้งถัดไป ",
            phone_number: "โทรศัพท์มือถือ",
            phone_pattern: "โทรศัพท์มือถือให้ถูกต้อง",
            email: "อีเมล %{notRequired}",
            email_pattern: "อีเมลให้ถูกต้อง",
            address: "ที่อยู่ปัจจุบัน (บ้านเลขที่, หมู่บ้าน, ซอย) %{notRequired}",
            province: "จังหวัด %{notRequired}",
            district: "เขต / อำเภอ %{notRequired}",
            sub_district: "แขวง / ตำบล %{notRequired}",
            notRequired: "(ไม่บังคับ)",
            edit_personal: "แก้ไขข้อมูลส่วนบุคคล",
            edit_hint_disabled: "*ข้อมูลที่ไม่สามารถแก้ไขได้",
            car: {
                header_1: "เพิ่มข้อมูลรถยนต์ที่ใช้งาน",
                header_2: "ข้อมูลรถยนต์ที่ใช้งาน",
                car_brand: "ยี่ห้อ%{car}",
                car_model: "รุ่น%{car}",
                range_of_miles: "ระยะทางที่ใช้รถต่อปี",
                car_license: "เลขทะเบียนรถ",
                car_license_pattern: "เลขทะเบียนรถให้ถูกต้อง (e.g. 9กก 9999)",
                title: "รถ",
            },
            confirm: {
                header: "ตรวจสอบความถูกต้อง",
                buyer_detail: "รายละเอียดผู้ซื้อ",
                fullname: "ชื่อ-นามสกุล",
                citizen_id: "เลขบัตรประชาชน",
                date_of_birth: "วันเดือนปีเกิด",
                phone_number: "โทรศัพท์มือถือ",
                email: "อีเมล",
                province: "จังหวัด",
                district: "เขต/อำเภอ",
                sub_district: "แขวง/ตำบล",
                address: "ที่อยู่ปัจจุบัน",
                car: {
                    car_detail: "รายละเอียดรถยนต์",
                    car_brand: "ยี่ห้อ",
                    car_model: "รุ่น",
                    car_license: "ทะเบียน",
                }
            },
            verify_citizen_title: 'แจ้งเตือน',
            verify_citizen: 'มีข้อมูลบัญชีเดิมอยู่ในระบบ กรณีที่เคยลงทะเบียนด้วยบัญชีไลน์ จะไม่สามารถเข้าสู่ระบบด้วบบัญชีไลน์เดิมได้ ต้องการดำเนินการต่อหรือไม่'
        },
        pdpa: {
            privacy_and_term: "นโยบายความเป็นส่วนตัว-การรับประกันสินค้า",
            condition: "ข้อมูลที่เราประมวลผลเมื่อคุณใช้ Google",
            agree: "ประสงค์ให้เปิดเผยข้อมูล",
            not_agree: "ไม่ประสงค์ให้เปิดเผยข้อมูล",
        },
        thanks: {
            header: "ขอบคุณสำหรับการลงทะเบียน",
        },
        dealer: {
            login: {
                header: "เข้าสู่ระบบ Dunlop Shop",
                username: "ชื่อบัญชีผู้ใช้ (e.g. A2xxx)",
                password: "รหัสผ่าน",
            },
            register: {
                header: "ลงทะเบียน",
                first_time_register: "ลงทะเบียนครั้งแรก",
                existing_register: "เคยลงทะเบียนแล้ว",
                tire_register: "ลงทะเบียนยางเพิ่ม",
                warranty: "ข้อมูลยางเก่าลูกค้า",
                qa: "คำถามที่พบบ่อย",
            },
            verify_customer_citizen: {
                header: "ลูกค้าเก่า",
                hint: "*กรณีลูกค้าเก่า ให้ทางศูนย์บริการกรอกเลขบัตรประชาชนของลูกค้า เพื่อใช้ในการดึงข้อมูลจากระบบ",
                citizen_id: "เลขบัตรประชาชน",
            },
        },
        for_dealer: "สำหรับร้านค้า",
        for_customer: "สำหรับลูกค้า",
        next_page: "ต่อไป : %{nextPage}",
        button: {
            edit: "แก้ไข",
            previous: "ย้อนกลับ",
            next: "ถัดไป",
            cancel: "ยกเลิก",
            accept: "ยอมรับ",
            to_proceed: 'ดำเนินการต่อ',
            confirm: "ยืนยัน",
            submit: "ตกลง",
            view_warranty_detail: "ดูรายละเอียดการรับประกัน",
            back_warranty_detail: "กลับสู่หน้ารายการยางที่รับประกัน",
            reset_password: "รีเซ็ตรหัสผ่าน",
            signin: "เข้าสู่ระบบ",
        },
        please_select: "กรุณาเลือก",
        validate_message: {
            please: "กรุณา%{label}",
            please_select: "กรุณาเลือก%{label}",
            please_input: "กรุณากรอก%{label}",
            pattern: "กรุณากรอกให้ถูกต้อง %{label}",
            file_type: "สามารถอัพโหลดได้เฉพาะรูปภาพเท่านั้น",
            file_size: "ไฟล์ต้องมีขนาดไม่เกิน 25MB",
        },
        qa: {
            header: "คำถามที่พบบ่อย",
            go_back: "กลับหน้าหลัก",
        },
        errors: {
            network_error: "ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง"
        }
    }
};
