import { Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, useHistory } from 'react-router';
import styled from 'styled-components';
import Tire from "../../assets/background/tire.png";
import withResolver from '../../components/HoC/withResolver';
import { Body, ButtonWrapped, Container } from '../../components/Wrapped';
import { config } from '../../config';
const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.background.default,
        display: "flex",
        flexDirection: "column",
        height: '100%',
        justifyContent: "center",
        '& .body': {
            width: '90%',
            maxWidth: '600px',
            display: "flex",
            flexDirection: "column",

            '& .tire-logo': {
                alignSelf: 'center',
                width: '250px'
            },

            '@media (min-width:600px)': {
                '& .tire-logo': {
                    alignSelf: 'center',
                    width: '350px'
                },
            },

            '@media (max-height:360px)': {
                flexDirection: "row",
                '& .tire-logo': {
                    alignSelf: 'center',
                    width: '150px'
                },
            },
        },
        '& .qa-container': {
            width: 100,
            '& .qa': {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                '& .content': {
                    color: theme.palette.text.secondary,
                    fontWeight: 300,
                    marginTop: 'unset',
                    borderBottom: `1px solid ${theme.palette.text.hint}`,
                }
            }
        }
    },
    header: {
        fontWeight: 300,
        color: theme.palette.text.secondary,
        lineHeight: 1,
        marginLeft: -1,
    },
    sub_header: {
        fontWeight: 300,
        color: theme.palette.text.primary,
        lineHeight: 1,
    },
    description: {
        marginTop: 10,
        fontWeight: 300,
        color: theme.palette.text.secondary,
        lineHeight: 1.2,
    },
    firstTimeButton: {
        marginTop: 25,
        marginBottom: 40,
        fontWeight: theme.typography.fontWeightBold,
        width: '60% !important'
    },

}))

const ContainerWrapped = styled(Container)`
    align-items: center !important;
    background: linear-gradient(#191919 40%, #000000 100%);
`
const BodyWrapped = styled(Body)`
`


const MemberRegister = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const label = I18n.t("member_register")
    const auth = useSelector(state => state.authorization)
    const hasCreated = useSelector(state => state.warranty.created)

    if (hasCreated) {
        return (
            <Redirect to={config.route.thanks} />
        )
    }

    return (
        <ContainerWrapped className={classes.root}>
            <BodyWrapped>
                <img className="tire-logo" src={Tire} alt="tire" />
                <div>
                    <Typography variant="h1" className={classes.header}>{label.header}</Typography>
                    <Typography variant="h4" className={classes.sub_header}>{label.sub_header}</Typography>
                    <Typography variant="subtitle1" className={classes.description}>{label.description}<br />{label.sub_description}</Typography>
                    <ButtonWrapped
                        onClick={() => history.push(config.route.registerVerifyDealer)}
                        className={classes.firstTimeButton}
                        variant="contained"
                        color="primary"
                        size="large"
                    >
                        {auth.newUser ? label.first_time : label.existing}
                    </ButtonWrapped>
                    <div className="qa-container">
                        <Link className={'qa'} onClick={() => history.push({ pathname: config.route.qa, state: { redirect: config.route.register } })}>
                            <Typography className='content' style={{ fontSize: 14 }}>
                                {label.QA}
                            </Typography>
                        </Link>
                    </div>
                </div>
            </BodyWrapped>
        </ContainerWrapped>
    )
}

export default withResolver(MemberRegister)