import { WARRANTY_FORM_SUBMIT, WARRANTY_BEGIN, WARRANTY_SUCCESS, CLEAR_WARRANTY_FORM } from "./type";
import WarrantyFormModel from "../../../components/WarrantyForm/WarrantyFormModel";

const initialState = {
    formData: new WarrantyFormModel(),
    items: [],
    loading: false,
    created: false,
    hasFormData: false
}

export const WarrantyReducer = (state = initialState, action) => {
    switch (action.type) {
        case WARRANTY_FORM_SUBMIT:
            return {
                ...state,
                formData: action.payload,
                hasFormData: true
            }
        case WARRANTY_BEGIN:
            return {
                ...state,
                loading: true
            }
        case WARRANTY_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload?.result ? action.payload.result : state.items,
                created: action.payload.created,
            }
        case CLEAR_WARRANTY_FORM:
            return {
                ...state,
                formData: new WarrantyFormModel(),
                hasFormData: false,
            }
        default:
            return state
    }
};
