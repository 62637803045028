export class CardDetailModel {
    header_title = ""
    data = []
}

export class BlockModel {
    title
    contents = []
    separator
    spanSize

    constructor(content = null, spanSize = 4, title = '', separator = false) {
        if (content) {
            this.contents.push(content)
        }
        this.spanSize = spanSize
        this.title = title
        this.separator = separator
    }
}

export class ContentModel {
    label = ''
    value = ''
    constructor(label, value) {
        this.label = label
        this.value = value
    }
}