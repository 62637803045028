import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useCallback } from 'react';
import { FiDelete } from "react-icons/fi";
import styled from 'styled-components';
import NumButton from './NumButton';


const NumpadList = () => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, <FiDelete />]
    return numbers.map((data, index) => ({ key: `key_${index}`, value: data }))
}

const NumberContainer = styled.div.attrs([{ className: 'number-container flex-container flex-column flex-justify-space-between flex-align-items-center' }])`
    position: ${props => props.isAbsolute ? 'absolute' : 'relative'};
    ${props => props.active && `
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    ` };
    display: ${props => props.active ? 'unset' : 'none'};
`

const PadContainer = styled.div.attrs([{ className: 'pad-container flex-container flex-column flex-justify-space-between flex-align-items-center' }])`
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: auto;
`

const fadeOut = createStyles({
    opacity: 0,
    height: 0,
    width: 0,
    bottom: null,
    left: null,
    right: null,
    transition: 'height 250ms 250ms, opacity 0ms'
})
const fadeIn = createStyles({
    opacity: 1,
    height: '50%',
    transition: 'height 250ms, opacity 250ms 250ms',
})

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10,
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.keyboard.main,
        '& .MuiGrid-container': {
            width: '100% !important',
            display: 'flex !important',
            flexWrap: 'wrap !important',
            boxSizing: 'border-box !important',
            margin: 'unset !important',
            '& .MuiGrid-item': {
                margin: 'unset !important',
                padding: 'unset !important',
            }
        },
        '& .number-row-container': {
            borderBottom: '1px solid #000',
            '&:last-of-type': {
                borderBottom: 'none',
            },
            '& .number-col-container': {
                borderRight: '1px solid #000',
                '&:last-of-type': {
                    borderRight: 'none',
                },
                '& .number': {
                    color: theme.palette.keyboard.contrastText,
                    fontSize: theme.typography.h5.fontSize,
                },
                '& .contrast-number': {
                    background: theme.palette.keyboard.sub,
                    color: theme.palette.keyboard.contrastText,
                    fontSize: theme.typography.h5.fontSize,
                }
            }
        }
    }
}))

const Numpad = ({ setActive, active = true, pinCode, setPinCode, length, isAbsolute }) => {
    const classes = useStyles()
    const fadeStyle = active ? fadeIn : fadeOut;
    const numberRef = React.useRef();

    const handleClickOutside = useCallback((e) => {
        if (active) {
            if (numberRef.current && !numberRef.current.contains(e.target)) {
                setActive(false)
            }
        }
    }, [active, setActive])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [handleClickOutside])

    return (
        <NumberContainer
            ref={numberRef}
            isAbsolute={isAbsolute}
            active={active}
            style={{
                ...fadeStyle,
            }}
            className={classes.root}>
            <PadContainer>
                {NumpadList()
                    .map(data => {
                        return (
                            <Grid className="number-col-container" item key={`col_numpad_${data.key}`} xs={4}>
                                {(data.value || data.value === 0)
                                    &&
                                    <NumButton className={isNaN(Number(data.value)) ? 'number' : 'contrast-number'} onClick={() => {
                                        if (Number(data.value) || data.value === 0) {
                                            setPinCode((pinCode.length < length) ? [...pinCode, data.value] : pinCode)
                                        } else {
                                            setPinCode(pinCode.filter((pin, _i) => _i < pinCode.length - 1))
                                        }
                                    }}>
                                        {data.value}
                                    </NumButton>}
                            </Grid>
                        )
                    })
                    .reduce((r, element, index) => {
                        index % 3 === 0 && r.push([]);
                        r[r.length - 1].push(element)
                        return r
                    }, [])
                    .map((rowContent, index) => {
                        return (
                            <Grid
                                className="number-row-container"
                                container
                                key={`row_numpad_${index}`}
                                direction="row"
                                justify="center"
                                alignItems="stretch"
                                style={{ height: '100%' }}
                            >
                                {rowContent}
                            </Grid>
                        )
                    })}
            </PadContainer>
        </NumberContainer>
    )
}

export default Numpad;