const api_dev = {
    API_URL: "https://dev.dialogoneapi.dunloptire.co.th/api",
}

const api_cloud_dev = {
    API_URL: "https://dev.dialogoneapi.dunloptire.co.th/api"
}

const api_prod = {
    API_URL: "https://register.dunloptire.co.th/api"
}

const api = {
    CUSTOMER: {
        REGISTER_WARRANTY: {
            VERIFY_DEALER_CODE: "/customer/verifyDealerCode",
            REGISTER: "/customer/register",
            VERIFY_ID_CARD: "/customer/verifyIdCard",
        },
        MEMBER: {
            LOGIN: "/customer/login",
            VERIFY_FORGOT_PASSWORD: "/customer/verifyForgetPassword",
            VERIFY_PASSWORD: "/customer/verifyPassword",
            CHANGE_PASSWORD: "/customer/changePassword",
            GET_WARRANTIES: "/customer/getWarranties",
            UPDATE_PROFILE: "/customer/updateProfile",
            GET_PROFILE: "/customer/getProfile",
        },
        THIRD_PARTY: {
            GET_LINE_ID: "/customer/getLineId",
        }
    },
    DEALER: {
        REGISTER_WARRANTY: {
            LOGIN: "/dealer/login",
            VERIFY_ID_CARD_CUSTOMER: "/dealer/verifyIdCardCustomer",
            GET_WARRANTIES_CUSTOMER: "/dealer/getWarrantiesCustomer",
            REGISTER: "/dealer/createWarranty",
        }
    },
    COMMON: {
        ADDRESS: {
            GET_PROVINCES: "/common/getProvinces",
            GET_DISTRICTS: "/common/getDistricts", // qs : { provinceId }
            GET_SUB_DISTRICTS: "/common/getSubDistricts", // qs : { districtId }
        },
        CAR: {
            GET_CAR_BRANDS: "/common/getCarBrands", // qs : { keyword }
            GET_CAR_MODELS: "/common/getCarModels", // qs : { keyword }
        },
        TITLE_REASON: {
            GET_REASONS: "/common/getReasons",
            GET_TITLES: "/common/getTitles",
            GET_MILEAGES: "/common/getMileages",
        },
        TIRE: {
            GET_TIRE_MODELS: "/common/getTireModels",
            GET_TIRE_BRANDS: "/common/getTireBrands",
            GET_TIRE_SIZES: "/common/getTireSizes", // qs : { tireModelId }
        },
        QUESTION: {
            GET_QUESTION: "/common/getQuestion"
        },
        UPLOAD: {
            UPLOAD_IMAGES: "/common/uploadImage",
            GET_TIRE_IMG: "/common/getTireImg" // qs : { tireModelId }
        }
    },
    THIRD_PARTY: {
        DEV: {
            REGISTER: "https://dev.dialogoneapi.dunloptire.co.th/api/linelogin?index=cooperationregister",
            MEMBER: "https://dev.dialogoneapi.dunloptire.co.th/api/linelogin?index=cooperationmember"
        },
        PRODUCTION:{
            REGISTER: "https://register.dunloptire.co.th/api/linelogin?index=cooperationregister",
            MEMBER: "https://register.dunloptire.co.th/api/linelogin?index=cooperationmember"
        }
    }
}

export {
    api,
    api_dev,
    api_cloud_dev,
    api_prod
}