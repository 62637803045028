import { config } from "../../../../../config";
import http from "../../../../../http";
import { GET_TIRE_MODEL_BEGIN, GET_TIRE_MODEL_SUCCESS } from "./type";
import { hasErrors } from "../../../error/actions";

export const getTireModel = () => {
    return async dispatch => {
        try {
            dispatch(getBegin())
            const result = await http.get(config.api.COMMON.TIRE.GET_TIRE_MODELS)
            dispatch(getSuccess(result))
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

const getBegin = () => {
    return dispatch => {
        dispatch({ type: GET_TIRE_MODEL_BEGIN })
    }
}
const getSuccess = (items) => {
    return dispatch => {
        dispatch({ type: GET_TIRE_MODEL_SUCCESS, payload: { items, loading: false } })
    }
}