import { GET_TIRE_SIZE_BEGIN, GET_TIRE_SIZE_SUCCESS } from "./type";

const initialState = {
    items: [],
    loading: false
}
export const TireSizeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIRE_SIZE_BEGIN:
            return {
                ...state,
                loading: true
            }
        case GET_TIRE_SIZE_SUCCESS:
            return action.payload
        default:
            return state
    }
};
