import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router';
import CardDetail from '../../../components/CardDetail';
import { BlockModel, CardDetailModel, ContentModel } from '../../../components/CardDetail/CardDetailModel';
import CircleProgress from '../../../components/CircleProgress';
import Header from '../../../components/Header';
import withPersonal from '../../../components/HoC/withPersonal';
import { baseStyles, Body, ButtonWrapped, Col, Container, Footer, Row } from '../../../components/Wrapped';
import { config } from '../../../config';
import commonFunction from '../../../config/common.function';

const getPersonalDetail = (label, personal) => {
    const { firstname, lastname, identificationNumber
        , dateOfBirth, phoneNumber, email
        , address, provinceText, districtText, subDistrictText } = personal
    const cardDetailModel = new CardDetailModel()
    cardDetailModel.header_title = label.buyer_detail
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.fullname, `${firstname} ${lastname}`)))
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.citizen_id, identificationNumber)))
    if (dateOfBirth) {
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.date_of_birth, config.common.toBuddhistYear(dateOfBirth, "DD/MM/YYYY"))))
    }
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.phone_number, phoneNumber)))
    if (email) {
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.email, email)))
    }
    if (address) {
        cardDetailModel.data.push(new BlockModel(
            new ContentModel(label.address, address.trim())))
    }
    if (provinceText) {
      cardDetailModel.data.push(new BlockModel(
          new ContentModel(label.province, provinceText)))
    }
    if (districtText) {
      cardDetailModel.data.push(new BlockModel(
          new ContentModel(label.district, districtText)))
    }
    if (subDistrictText) {
      cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.sub_district, subDistrictText)))
    }
    return cardDetailModel
}

const getCardDetail = (label, car) => {
    const { carBrand, carModel, carLicense } = car
    const cardDetailModel = new CardDetailModel()
    cardDetailModel.header_title = label.car.car_detail
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.car.car_brand, carBrand.carBrandName)))
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.car.car_model, carModel.carModelName)))
    cardDetailModel.data.push(new BlockModel(
        new ContentModel(label.car.car_license, carLicense)))
    return cardDetailModel
}

const Confirm = ({ label, tire, personal, car, classes, isExisting, steps }) => {
    const history = useHistory()
    const baseClasses = baseStyles()
    const onCancel = () => {
        // history.push(config.route.registerCar)
        history.goBack()
    }

    const onConfirm = () => {
        history.push(config.route.pdpa)
    }

    return (
        <Container className={classes.root}>
            <Body>
                <Header title={label.confirm.header} remark={I18n.t("for_customer")}
                    element={<CircleProgress step={isExisting ? 2 : 3} total={steps} />} titleStyle={{ fontSize: commonFunction.fontSizeHeader() }} />
                {!isExisting &&
                    <CardDetail
                        cardDetail={getPersonalDetail(label.confirm, personal)}
                        containerStyle={{ marginTop: 30 }}
                    />
                }
                <CardDetail
                    cardDetail={getCardDetail(label.confirm, car)}
                    containerStyle={{ marginTop: 30 }}
                />
                <Footer className={baseClasses.root} style={{ width: '90%' }}>
                    <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col xs={5} >
                            <ButtonWrapped variant="contained" size="medium" color="secondary" onClick={onCancel}>
                                {label.button.edit}
                            </ButtonWrapped>
                        </Col>
                        <Col xs={7}>
                            <ButtonWrapped variant="contained" size="large" type="submit" color="primary" onClick={onConfirm}>
                                {label.button.next}
                            </ButtonWrapped>
                        </Col>
                    </Row>
                </Footer>
            </Body>
        </Container>
    )
}

export default withPersonal(Confirm)