import { VERIFY_DEALER_BEGIN, VERIFY_DEALER_SUCCESS, SIGN_IN_DEALER_SUCCESS } from "./type";
import http from "../../../http";
import { config } from "../../../config";
import { hasErrors } from "../error/actions";

export const verifyDealer = (code, callback) => {
    return async dispatch => {
        try {
            dispatch(dealerBegin())
            const result = await http.post(config.api.CUSTOMER.REGISTER_WARRANTY.VERIFY_DEALER_CODE, { code })
            localStorage.setItem("token", result?.token)
            dispatch(getDealerSuccess(result))
            callback && callback()
        } catch (error) {
            dispatch(hasErrors(error))
        }
    }
}

export const signin = (data, callback) => {
    return async dispatch => {
        try {
            await dispatch(dealerBegin())
            const result = await http.post(config.api.DEALER.REGISTER_WARRANTY.LOGIN, data)
            if (result?.token) {
                localStorage.setItem('token', result.token)
            }
            await dispatch(signinSuccess())
            await callback && callback()
        } catch (error) {
            await dispatch(hasErrors(error))
        }
    }
}

const dealerBegin = () => {
    return dispatch => {
        dispatch({ type: VERIFY_DEALER_BEGIN })
    }
}
const getDealerSuccess = ({ dealer }) => {
    return dispatch => {
        dispatch({ type: VERIFY_DEALER_SUCCESS, payload: dealer })
    }
}

const signinSuccess = () => {
    return dispatch => {
        dispatch({ type: SIGN_IN_DEALER_SUCCESS, isAuth: true })
    }
}