import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import TireLogo from "../../assets/logo/tire_logo.png";
import CardDetail from '../../components/CardDetail';
import { BlockModel, CardDetailModel, ContentModel } from '../../components/CardDetail/CardDetailModel';
import ErrorMessage from '../../components/ErrorMessage';
import Header from '../../components/Header';
import withDealer from '../../components/HoC/withDealer';
import withWarranty from '../../components/HoC/withWarranty';
import WarrantyForm from '../../components/WarrantyForm';
import { Body, Container } from '../../components/Wrapped';
import { config } from '../../config';
import { getTireSize } from '../../store/reducers/common/tire/tireSize/actions';
// import Spinner from '../../components/Spinner';

const getCardDetail = (label, dealer) => {
    const cardDetailModel = new CardDetailModel()
    const contentModel = new ContentModel(dealer.code, dealer.dealerName)
    cardDetailModel.data.push(new BlockModel(contentModel, 2))
    cardDetailModel.header_title = label.service_center_detail
    return cardDetailModel
}

const RegisterWarranty = ({ dealer, warranty, label, classes }) => {
    const { tireChangeModelId } = warranty
    const dispatcher = useDispatch()

    useEffect(() => {
        if (tireChangeModelId) {
            dispatcher(getTireSize(tireChangeModelId))
        }
    }, [dispatcher, tireChangeModelId])


    useEffect(() => {
        sessionStorage.setItem('prevLocation', config.route.registerWarranty)
    }, [])

    return (
        <Container className={classes.root}>
            <ErrorMessage root={config.route.register} />
            <Body>
                <Header title={label.header} remark={I18n.t("for_dealer")} logo={TireLogo} />
                <CardDetail
                    cardDetail={getCardDetail(label, dealer.data)}
                    containerStyle={{ marginTop: 30 }}
                />
                <WarrantyForm label={label} formData={warranty} />
            </Body>
        </Container>
    )
}

export default withWarranty(withDealer(RegisterWarranty))