import PersonalFormModel from "../../../components/PersonalForm/PersonalFormModel";
import { PERSONAL_SUBMIT_FORM, VERIFY_PERSONAL_BEGIN, VERIFY_PERSONAL_SUCCESS, SET_PDPA_FLAG, GET_PERSONAL_PROFILE_SUCCESS, VERIFY_PERSONAL_ERROR, CLEAR_PERSONAL_FORM } from "./type";

const initialState = {
    formData: new PersonalFormModel(),
    loading: false,
    hasFormData: false,
}
export const PersonalReducer = (state = initialState, action) => {
    switch (action.type) {
        case PERSONAL_SUBMIT_FORM:
            return {
                ...state,
                formData: action.payload,
                hasFormData: true
            }
        case VERIFY_PERSONAL_BEGIN:
            return {
                ...state,
                loading: true
            }
        case VERIFY_PERSONAL_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case VERIFY_PERSONAL_ERROR:
            return {
                ...state,
                loading: false
            }
        case SET_PDPA_FLAG:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    pdpaFlag: action.payload
                }
            }
        case GET_PERSONAL_PROFILE_SUCCESS:
            return {
                ...state,
                formData: action.payload,
                loading: false
            }
        case CLEAR_PERSONAL_FORM:
            return {
                ...state,
                formData: new PersonalFormModel(),
                hasFormData: false
            }
        default:
            return state
    }
};
